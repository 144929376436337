import React from 'react'
import HeadingAtom from 'atoms/headingAtom'
import heroMedia from 'assets/home/banner.mp4'
import poster from 'assets/home/bannerPoster.jpg'
import HeadingAtomBanner from 'atoms/headingAtomBanner'

export default function Banner() {
  return (
    <section className="bg-black md:mt-0 blade-top-padding relative md:min-h-screen min-h-[500px]">
      <video
        className="absolute -z-1 top-0 h-full w-full object-cover object-bottom"
        poster={poster}
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={heroMedia} type="video/mp4" />
        <track kind="captions" />
      </video>

      <div className="w-full absolute top-0 md:min-h-screen min-h-[450px] ">
        <div className="z-50 w-container-sm md:min-h-screen min-h-[450px] flex flex-col place-content-center md:pb-32">
          <HeadingAtomBanner
            title="Conquering Business Challenges"
            subtitle="To Drive Transformation & Product Breakthroughs"
            className="2xl:max-w-2xl xl:max-w-xl max-w-md animate-fade-in-slow"
          />
          {/* <div className="banner-animation 2xl:max-w-2xl xl:max-w-xl max-w-md banner-heading-wrapper">
            <div className="relative">
              <div className="banner-text-hedin font-medium leading-tight text-gradient-dark relative flex flex-wrap gap-x-2">
                <span>
                  <h1 className="inline ">C</h1>
                  <h1 className="inline ">o</h1>
                  <h1 className="inline ">n</h1>
                  <h1 className="inline ">q</h1>
                  <h1 className="inline ">u</h1>
                  <h1 className="inline ">e</h1>
                  <h1 className="inline ">r</h1>
                  <h1 className="inline ">i</h1>
                  <h1 className="inline ">n</h1>
                  <h1 className="inline ">g</h1>
                </span>

                <span>
                  <h1 className="inline ">B</h1>
                  <h1 className="inline ">u</h1>
                  <h1 className="inline ">s</h1>
                  <h1 className="inline ">i</h1>
                  <h1 className="inline ">n</h1>
                  <h1 className="inline ">e</h1>
                  <h1 className="inline ">s</h1>
                  <h1 className="inline ">s</h1>
                </span>

                <span>
                  <h1 className="inline ">C</h1>
                  <h1 className="inline ">h</h1>
                  <h1 className="inline ">a</h1>
                  <h1 className="inline ">l</h1>
                  <h1 className="inline ">l</h1>
                  <h1 className="inline ">e</h1>
                  <h1 className="inline ">n</h1>
                  <h1 className="inline ">g</h1>
                  <h1 className="inline ">e</h1>
                  <h1 className="inline ">s</h1>
                </span>
              </div>
              <h1 className="absolute inset-0 font-semibold leading-tight text-white opacity-0 animate-appear">
                Conquering Business Challenges
              </h1>
              <div className="absolute bg-gradient-to-br from-dark  to-white mix-blend-darken inset-0 font-semibold leading-tight text-white" />
            </div>
            <h5 className="banner-text font-light text-gray md:mt-4 mt-2 leading-normal">
              To Drive Transformation & Product Breakthroughs
            </h5>
          </div> */}
        </div>
      </div>
    </section>
  )
}
