import i__Accountability from 'assets/about/values-elements/i__Accountability.png'
import i__Authenticity from 'assets/about/values-elements/i__Authenticity.png'
import i__BeyondRoles from 'assets/about/values-elements/i__BeyondRoles.png'
import i__Integrity from 'assets/about/values-elements/i__Integrity.png'
import i__influencingImpact from 'assets/about/values-elements/i__influencingImpact.png'
import i__perpetuatingExistence from 'assets/about/values-elements/i__perpetuatingExistence.png'

import React, { useEffect, useRef, useState } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { gsap } from 'gsap'
import './about.css'
import HeadingAtom from 'atoms/headingAtom'

gsap.registerPlugin(ScrollTrigger)

const elements = [
  i__Integrity,
  i__Authenticity,
  i__Accountability,
  i__perpetuatingExistence,
  i__influencingImpact,
  i__BeyondRoles,
]

export default function CausesAndActions() {
  const [viewIndex, setViewIndex] = useState(0)
  const wrapper = useRef<any>()
  const imgWrapper = useRef<any>()
  const cardWrapper = useRef<any>()

  const nodeRef = useRef<any>()

  useEffect(() => {
    const windowWidth = window.innerWidth
    // // console.log(windowWidth > 768, 'sdlfkjdflkj')
    if (windowWidth > 768) {
      const selector = gsap.utils.selector(cardWrapper)
      const ctx = gsap.context(() => {
        const articles = selector('article')
        articles.forEach((elem, index) => {
          gsap.fromTo(
            elem,
            { opacity: 0.2 },
            {
              opacity: 1,
              onStart: () => {
                setViewIndex(index)
              },
              scrollTrigger: {
                trigger: elem,
                start: 'top 50%',
                end: 'bottom 50%',
                markers: false,
                toggleActions: 'play reverse play reverse',
              },
            },
          )
        })
      }, wrapper)
      return () => ctx.revert()
    }
  }, [])

  return (
    <div
      className="bg-black blade-top-padding blade-bottom-padding"
      ref={wrapper}
    >
      <div className="md:blade-top-padding-lg">
        <HeadingAtom
          title="The Causes of Our Actions"
          subtitle="Values in Practice"
          className=" w-container mx-auto"
          titleClass="2xl:max-w-2xl max-w-sm"
        />
        <div className="md:grid hidden grid-cols-2" ref={imgWrapper}>
          <div className="blade-top-margin blade-bottom-padding-lg ">
            <div className="2xl:h-[50rem] h-[45rem] grid place-content-center sticky top-0">
              <SwitchTransition>
                <CSSTransition
                  key={viewIndex}
                  nodeRef={nodeRef}
                  timeout={500}
                  onEnter={(contentEl: any) =>
                    gsap.fromTo(contentEl, { opacity: 0 }, { opacity: 1 })
                  }
                  classNames="imgslide"
                  unmountOnExit
                >
                  <div
                    className="list-none dropDown inline w-container"
                    ref={nodeRef}
                  >
                    <img src={elements[viewIndex]} alt="" />
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </div>
          </div>

          <div
            className="cards-wrapper blade-top-padding-xl pb-[25vh]"
            ref={cardWrapper}
          >
            <FeatureCard
              title="Integrity"
              desc="Right from the first handshake, we believe that integrity transcends into relationships that last a lifetime and is instrumental in making the impossible possible."
            />
            <FeatureCard
              title="Authenticity"
              desc="For us, it’s a dare to stay true to our unique identities, tap some untapped quirks with a genuine voice, and let one’s true self shine."
            />
            <FeatureCard
              title="Accountability"
              desc="With no room for excuses, we use it as a recipe to break free from mediocrity and rewrite the rules of engagement across frontiers."
            />
            <FeatureCard
              title="Perpetuating Existence"
              desc="We believe in embracing the power of continuity in a world of constant change, seizing every opportunity, and leaving no room for oversight."
            />
            <FeatureCard
              title="Influencing Impact"
              desc="For us, it’s all about rewriting stories while giving our protagonists a nudge to drive change and create seismic shifts across walks of life. "
            />
            <FeatureCard
              title="Beyond Roles"
              desc="For us, dreaming big is a journey from me to we, beyond boundaries, beyond purposes, and a commitment to leaving a legacy that matters."
            />
          </div>
        </div>

        <div className="grid md:hidden">
          <div className="cards-wrapper grid gap-16 w-container">
            <FeatureCard
              title="Integrity"
              imgSrc={elements[0]}
              desc="Right from the first handshake, we believe that integrity transcends into relationships that last a lifetime and is instrumental in making the impossible possible."
            />
            <FeatureCard
              title="Authenticity"
              imgSrc={elements[1]}
              desc="For us, it’s a dare to stay true to our unique identities, tap some untapped quirks with a genuine voice, and let one’s true self shine."
            />
            <FeatureCard
              title="Accountability"
              imgSrc={elements[2]}
              desc="With no room for excuses, we use it as a recipe to break free from mediocrity and rewrite the rules of engagement across frontiers."
            />
            <FeatureCard
              title="Perpetuating Existence"
              imgSrc={elements[3]}
              desc="We believe in embracing the power of continuity in a world of constant change, seizing every opportunity, and leaving no room for oversight."
            />
            <FeatureCard
              title="Influencing Impact"
              imgSrc={elements[4]}
              desc="For us, it’s all about rewriting stories while giving our protagonists a nudge to drive change and create seismic shifts across walks of life. "
            />
            <FeatureCard
              title="Beyond Roles"
              imgSrc={elements[5]}
              desc="For us, dreaming big is a journey from me to we, beyond boundaries, beyond purposes, and a commitment to leaving a legacy that matters."
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function FeatureCard({
  title,
  desc,
  imgSrc,
}: {
  title: string
  desc: string
  imgSrc?: string
}) {
  return (
    <article>
      <div className="flex flex-col md:gap-4 md:h-80 md:pr-28 md:mt-0 2xl:max-w-3xl max-w-2xl">
        <div className="list-none h-[14rem] max-w-[16rem] dropDown md:hidden block">
          <img className="h-full w-full object-cover" src={imgSrc} alt="" />
        </div>
        <h2 className=" font-semibold  tracking-wide text-gradient-dark text-transparent bg-clip-text">
          {title}
        </h2>
        <h6 className="text-lightGray md:mt-0 mt-2 leading-relaxed max-w-2xl font-normal">
          {desc}
        </h6>
      </div>
    </article>
  )
}
