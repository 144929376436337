import React from 'react'
import something from 'assets/workCaseStudies/icon.svg'

export default function ProblemStatement() {
  return (
    <div>
      <div className=" blade-top-padding md:blade-top-padding-sm ">
        <div className="flex flex-col lg:flex-row justify-between gap-6 w-container">
          <h4 className="text-gradient-dark font-semibold w-fit">
            Problem Statements
          </h4>

          <div className="grid gap-6 pr-4 md:pr-0 md:gap-8 lg:gap-10 xl:gap-12 2xl:gap-16 2xl:max-w-3xl lg:max-w-xl">
            <StatementCard
              icon={something}
              title="Organizations, both large and small, grapple with the complexities of tracking and managing their IT spending."
            />
            <StatementCard
              icon={something}
              title="The proliferation of applications leading to redundancy, underutilization, and hidden expenses."
            />
            <StatementCard
              icon={something}
              title="Lack of visibility into application usage, hindered ability to make informed decisions about resource utilization and cost optimization."
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function StatementCard({ title, icon }: { title: string; icon: string }) {
  return (
    <article className="flex items-start gap-5">
      <div className="grid mt-[2px] md:mt-1.5 place-content-center place-items-center basis-14 flex-0">
        <img
          src={icon}
          alt={title}
          className="h-full w-full object-contain object-center"
        />
      </div>
      <div className="flex-1">
        <h3
          className="text-white font-light md:block hidden"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <h5
          className="text-white font-light md:hidden block leading-snug"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
    </article>
  )
}
