import React from 'react'
import HeadingTile from 'molecules/headingTile'

export default function ApproachCardsView({ data }: { data: any }) {
  return (
    <div className="w-container">
      <div>
        <HeadingTile
          title="Approach"
          subtitle="Thinking Beyond Mere Conventions"
          center
        />
        <div className="flex justify-center flex-wrap 2xl:gap-32 xl:gap-16 gap-10 blade-top-padding-sm xl:max-w-screen-xl mx-auto">
          {data.map((item: any) => {
            return (
              <div
                key={item.id}
                className="sm:w-5/12 w-10/12 2xl:even:mt-20 sm:even:mt-10 lg:min-w-[20rem] min-w-[18rem]"
              >
                <Cards
                  list={item.list}
                  title={item.title}
                  paragraph={item.paragraph}
                  id={item.id}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

function Cards({
  title,
  paragraph,
  image,
  id,
  list,
}: {
  title: string
  paragraph: string
  image?: string
  id: number
  list: any
}) {
  return (
    <div className="cards flex md:flex-row flex-col-reverse border relative border-white border-opacity-10 gap-y-6 max-w-screen-md mx-auto xl:p-10 lg:p-7 p-4 rounded-2xl lg:gap-10 gap-5 bg-gradient-to-br from-[#222121] via-black to-black">
      <div className="flex justify-between relative ">
        <div>
          <h5 className="text-white">
            {id}. {title}
          </h5>
          <span
            className=" text-sm md:text-base lg:text-lg text-white opacity-60 lg:mt-4 mt-2 pr-3 font-light leading-snug block"
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
          {list ? (
            <ul className="text-white  list-disc list-outside pl-5 grid gap-2">
              {list.map((item: any, idx: number) => {
                const key = `${idx}`
                return <li key={key}>{item}</li>
              })}
            </ul>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}
