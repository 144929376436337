import React from 'react'
import { useNavigate } from 'react-router-dom'
import { JoinForm } from 'organisms/index'

type CTA = {
  type: 'primary' | 'secondary'
  text: string
  onClick: () => void
  color: 'transparent' | 'black' | 'blue' | undefined
}
type Subtitle = { type: 'primary' | 'secondary'; value: string }
type Props = {
  title: string
  subtitle?: Array<Subtitle>
  cta?: Array<CTA>
}

export default function SubFooter() {
  // const navigate = useNavigate()
  // const handleSchedule = () => {
  //   navigate('/coming-soon')
  // }
  // const handleProposal = () => navigate('/coming-soon')
  return (
    <div className="blade-bottom-padding-xl blade-top-padding-xl bg-black">
      <div className=" md:w-10/12 w-full sm:ml-auto  bg-cover bg-center  bg-[url('assets/home/subFooter.jpg')]">
        <div className="blade-top-padding-sm blade-bottom-padding-sm sm:w-full md:w-11/12 px-5">
          <div className="blade-top-margin-sm blade-bottom-margin md:w-11/12 lg:pl-10 pl-0 block sm:ml-auto ">
            <h1 className="bg-darkGradient bg-clip-text text-transparent font-semibold">
              Are you in? <br className="md:block hidden" /> Let your Brilliance
              Unfold!
            </h1>
            <h5 className="mt-3">
              Together, we&apos;ll break down barriers, solve complex{' '}
              <br className="md:block hidden" />
              problems, and have a blast along the way.
            </h5>
            <h5 className="font-bold mt-5">
              Make your Mark, and Join our Ranks!
            </h5>

            <div className="blade-top-padding-sm">
              <JoinForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
