import React, { useEffect } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './headingAtom.css'

gsap.registerPlugin(ScrollTrigger)

export default function HeadingAtom({
  className,
  title,
  pageName,
  subtitle,
  themeDark = true,
  titleClass,
  subTitleClass,
  triggerAnim,
}: {
  title: string
  pageName?: string
  subtitle: string
  className?: string
  themeDark?: boolean
  titleClass?: string
  subTitleClass?: string
  triggerAnim?: string
}) {
  useEffect(() => {
    if (triggerAnim) {
      gsap.timeline({
        scrollTrigger: {
          trigger: `.${triggerAnim}`,
          start: 'top 70%',
          end: 'bottom top',
          markers: false,
          toggleClass: 'heading-wrapper-animation',
        },
      })
    }
  }, [])

  return (
    <div className={`${className} ${triggerAnim}`}>
      {!themeDark ? (
        <>
          <h5
            className={`${subTitleClass} pageName font-medium text-darkGray md:mt-4 mt-2 leading-normal`}
          >
            {pageName}
          </h5>
          <h1
            className={`${titleClass} title font-semibold leading-tight text-gradient-light`}
          >
            {title}
          </h1>
          <h5
            className={`${subTitleClass} subtitle font-medium text-darkGray md:mt-4 mt-2 leading-normal`}
          >
            {subtitle}
          </h5>
        </>
      ) : (
        <>
          <h1
            className={`${titleClass} title font-semibold  leading-tight text-gradient-dark transition-all duration-500`}
          >
            {title}
          </h1>
          <h5
            className={`${subTitleClass} subtitle font-light text-gray md:mt-4 mt-2 leading-normal transition-all duration-500`}
          >
            {subtitle}
          </h5>
        </>
      )}
    </div>
  )
}
