import React, { useEffect } from 'react'
import Banner from './01_banner'
import Transforming from './02_transforming'
import SlideAnimation from './03_slideAnimation/indexDone'
import SuccessStory from './04_successStory'
import GetInTouch from './05_getInTouch'

export default function PageServices() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main className="bg-black">
      <Banner />
      <Transforming />
      <SlideAnimation />
      <SuccessStory />
      <GetInTouch />
    </main>
  )
}
