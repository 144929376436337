import HeadingTile from 'molecules/headingTile'
import React from 'react'
import case_study_cloudeagle_0 from 'assets/workCaseStudies/etl/approach/case_study_cloudeagle-0.jpg'
import case_study_cloudeagle_1 from 'assets/workCaseStudies/etl/approach/case_study_cloudeagle-1.jpg'
import case_study_cloudeagle_2 from 'assets/workCaseStudies/etl/approach/case_study_cloudeagle-2.jpg'
import case_study_cloudeagle_3 from 'assets/workCaseStudies/etl/approach/case_study_cloudeagle-3.jpg'
import case_study_cloudeagle_5 from 'assets/workCaseStudies/etl/approach/case_study_cloudeagle-5.jpg'
import case_study_cloudeagle_6 from 'assets/workCaseStudies/etl/approach/case_study_cloudeagle-6.jpg'
import case_study_cloudeagle_7 from 'assets/workCaseStudies/etl/approach/case_study_cloudeagle-7.jpg'
import case_study_cloudeagle_8 from 'assets/workCaseStudies/etl/approach/case_study_cloudeagle-8.jpg'
import case_study_cloudeagle_9 from 'assets/workCaseStudies/etl/approach/case_study_cloudeagle-9.jpg'
import case_study_cloudeagle_10 from 'assets/workCaseStudies/etl/approach/case_study_cloudeagle-10.jpg'

import user_data_source_1 from 'assets/workCaseStudies/etl/approach/user_data_sources-1.jpg'
import user_data_source_2 from 'assets/workCaseStudies/etl/approach/user_data_sources-2.jpg'
import user_data_source_3 from 'assets/workCaseStudies/etl/approach/user_data_sources-3.png'

export default function Approach() {
  const data = [
    {
      src: case_study_cloudeagle_0,
      id: 0,
    },
    {
      src: case_study_cloudeagle_1,
      id: 1,
    },
    {
      src: case_study_cloudeagle_2,
      id: 2,
    },
    {
      src: case_study_cloudeagle_3,
      id: 3,
    },

    {
      src: case_study_cloudeagle_5,
      id: 5,
    },
    {
      src: case_study_cloudeagle_6,
      id: 6,
    },
    {
      src: case_study_cloudeagle_7,
      id: 7,
    },
    {
      src: case_study_cloudeagle_8,
      id: 8,
    },
    {
      src: case_study_cloudeagle_9,
      id: 9,
    },
    {
      src: case_study_cloudeagle_10,
      id: 10,
    },
  ]

  const userData = [
    {
      src: user_data_source_3,
      id: 3,
    },
    {
      src: user_data_source_2,
      id: 2,
    },
    {
      src: user_data_source_1,
      id: 1,
    },
  ]
  return (
    <div className="w-container">
      <HeadingTile
        title="Approach"
        subtitle="Thinking Beyond Mere Conventions"
        paragraphs={[
          'We designed a comprehensive ETL integration solution, the approach of which involved the following key steps -',
        ]}
        center
        className=""
      />

      <section className="mt-10">
        <div className="grid gap-3 xl:p-14 md:p-10 p-5 rounded-2xl border border-borderGray bg-dark-gradient">
          <h6 className="text-white font-semibold">
            1. Data Source and Format Identification
          </h6>
          <h6 className="text-white text-opacity-70 2xl:text-lg text-sm">
            Disparate data sources were identified and categorized into two main
            groups:
          </h6>

          <h6 className="text-white mt-6">Financial Data Sources</h6>

          <div className="grid gap-4 md:grid-cols-5 grid-cols-3 flex-wrap md:w-10/12 mt-3">
            {data.map((el) => {
              return (
                <div key={el.id} className="rounded-md overflow-hidden">
                  <img src={el.src} alt="description" />
                </div>
              )
            })}
          </div>

          <h6 className="text-white mt-6">User Data Sources</h6>
          <div className="grid gap-4 md:grid-cols-5 grid-cols-3 flex-wrap md:w-10/12 mt-3">
            {userData.map((el) => {
              return (
                <div key={el.id} className="rounded-md overflow-hidden">
                  <img src={el.src} alt="description" />
                </div>
              )
            })}
          </div>

          {/* 
          <div className="flex gap-5 lg:w-10/12 mt-8">
            <div className="flex-1">
              <h6 className="text-white">User Data Sources </h6>
              <ul className="flex md:flex-row flex-col text-white list-inside md:gap-6 gap-3 mt-4 opacity-70 list-disc">
                <li className="text-sm">Motion</li>
                <li className="text-sm">DialPad</li>
                <li className="text-sm">New Relic</li>
              </ul>
              
            </div>
              <div className="flex-1">
                  <h6 className="text-white">Contract Management</h6>
                  <ul className="flex md:flex-row flex-col text-white list-inside md:gap-6 gap-3 mt-4 opacity-70 list-disc">
                    <li className="text-sm">IronClad</li>
                    <li className="text-sm">DocSign</li>
                    <li className="text-sm">Workday</li>
                  </ul>
              </div>
            </div> */}
        </div>

        <div className="grid md:grid-cols-3 2xl:gap-10 gap-7 2xl:mt-10 mt-7">
          <div className="md:p-8 p-5 text-white border border-borderGray rounded-2xl bg-dark-gradient">
            <h6 className="font-semibold"> 2. Data Extraction</h6>
            <h6 className="opacity-70 mt-3 2xl:text-lg text-sm">
              Custom ETL pipelines were implemented for each data source to
              extract relevant data which involved using APIs, file-based
              transfers, and database queries.
            </h6>
          </div>
          <div className="md:p-8 p-5 text-white border border-borderGray rounded-2xl bg-dark-gradient">
            <h6 className="font-semibold"> 3. Data Transformation</h6>
            <h6 className="opacity-70 mt-3 2xl:text-lg text-sm">
              For the data with different structures and formats, data
              transformation processes were brought in to standardize and
              cleanse data, ensuring all round consistency.
            </h6>
          </div>
          <div className="md:p-8 p-5 text-white border border-borderGray rounded-2xl bg-dark-gradient">
            <h6 className="font-semibold">4. Data Loading</h6>
            <h6 className="opacity-70 mt-3 2xl:text-lg text-sm">
              The transformed data was loaded into our data warehouse for
              contract visualization, user data provisioning, and finance data
              management. This allowed us to maintain a single source of truth
              for all data-related operations.
            </h6>
          </div>
        </div>
      </section>
    </div>
  )
}
