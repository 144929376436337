import React, { useRef } from 'react'
import StoriesOfTransformation from 'screens/home/storiesofTransformation'
import Banner from '../shared/banner'
import Carousel from '../shared/carosuel'
import PossibilityCarousel from '../shared/possibilityCarousel'
import CardGrid from '../shared/cardGrid'
import TechStack from '../shared/techStack'
import SubFooter from '../shared/subFooter'
import '../shared/shared.css'

export default function StaffingAgency() {
  const formRef = useRef<any>()

  const handleClick = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' })
    const node = formRef.current.querySelector('input')
    node.focus()
  }

  return (
    <section>
      <Banner
        isHeader
        header="Staffing Agencies!"
        isSubtitle
        subtitle="Test our teams on a Free Trial."
        formRef={formRef}
        handleClick={handleClick}
        formType="Staffing Agencies"
        title="Let the world’s <strong class='font-semibold'>top developer cohort</strong> navigate your clients’ products <br class='md:hidden block' /> to success."
      />
      <Carousel />
      <CardGrid />
      <PossibilityCarousel />
      <TechStack />
      <StoriesOfTransformation />
      <SubFooter handleClick={handleClick} />
    </section>
  )
}
