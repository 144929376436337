import productdevelopment from 'assets/home/solutions/ProductDevelopment.svg'
import businessProcess from 'assets/home/solutions/BusinessProcessTransformation.svg'
import engneeringSustainability from 'assets/home/solutions/EngineeringSustainabiity.svg'
import businessInnovation from 'assets/home/solutions/BusinessInnovation.svg'
import digtalCore from 'assets/home/solutions/DataManagement.svg'

export const solutions = [
  {
    id: 1,
    title: 'Product Development / Scaling',
    subtitle:
      'From inception to scale, experience a seamless product journey as we bring your vision to life with strategic guidance and agile methodologies.',
    img: productdevelopment,
    ul: [
      {
        li: 'MVP',
      },
      {
        li: 'Product Fundamentals',
      },
      {
        li: 'Product Scale & Performance',
      },
      // {
      //   li: 'No code/Low code',
      // },
    ],
  },
  {
    id: 2,
    title: 'Business Process Transformation',
    subtitle:
      'Get product scalability, optimized processes, and seamless adaptability as we identify hidden inefficiencies and drive sustainable business transformations. ',
    img: businessProcess,
    ul: [
      {
        li: 'Product Transformation',
      },
      {
        li: 'Process Automation',
      },
      {
        li: 'Business Intelligence',
      },
    ],
  },
  {
    id: 3,
    title: 'Engineering Sustainability',
    subtitle:
      'We enable you to build a resilient business foundation that paves the way for innovative re-engineering, enhanced operational efficiencies, and long-term sustainability.',
    img: engneeringSustainability,
    ul: [
      {
        li: 'Legacy Modernization',
      },
      {
        li: 'Infrastructure Optimization and Audit',
      },
      {
        li: 'Business Performance',
      },
      {
        li: 'Re-engineering and support',
      },
    ],
  },
  {
    id: 4,
    title: 'Business Innovation',
    subtitle:
      'Transformational technology consulting that drives results. From leveraging intelligent automation to improving data accuracy, unlock new opportunities to achieve your strategic business goals.',
    img: businessInnovation,
    ul: [
      {
        li: 'Product Innovation and Extension',
      },
      {
        li: 'Tech Consulting',
      },
      {
        li: 'Artificial Intelligence ',
      },
      {
        li: 'Cloud Computing ',
      },
      {
        li: 'IoT',
      },
      // {
      //   li: 'ETL & Integration',
      // },
    ],
  },
  {
    id: 5,
    title: 'Data Management',
    // subtitle:
    //   'Empower your business with strategic digital expertise and actionable insights that will guide you to drive data-led accelerated growth.',
    subtitle: `Expect a dynamic fusion of technology, strategic acumen, and meticulous execution as we redefine what's possible in the realm of data management.`,
    img: digtalCore,
    ul: [
      {
        li: 'Data Integration & ETL',
      },
      {
        li: 'Data Governance',
      },
      {
        li: 'Data Platforms',
      },
      {
        li: 'Data Architecture & Modeling',
      },
    ],
    // ul: [
    //   {
    //     li: 'Digital Strategy',
    //   },
    //   {
    //     li: 'Integrated Platforms ',
    //   },
    //   {
    //     li: 'Data Governance',
    //   },
    //   {
    //     li: 'Analytics and Control',
    //   },
    // ],
  },
]

export const productDevelopment = {
  title: 'This is where we step in!',
  subtitle:
    'Perceiving product challenges as springboards for your business triumphs!',
  paragraph:
    'Businesses left stranded in the ocean of mediocrity where static products translate to stagnation and irrelevance, alienate themselves from their own product vision, stifling innovation along the way.',
  list: [
    {
      id: 1,
      problem: 'A race against time to launch an MVP that resonates?',
      solution: {
        title: 'MVP; For more than just a Test Run',
        subtitle:
          'Imagine an MVP that not only validates your idea but captivates your audience, an embodiment of your vision in its purest digital form.',
        paragraphI: `We start at the foundation, solving the problem of "mere viability" by building an MVP that rolls out swiftly, captures attention, garners traction, and sets the stage for your product's triumphant journey from the very point.`,
        paragraphII: `Our engineering virtuosos work in symphony with your ideas, market insights, behavioral patterns, psychological triggers, and technological frameworks, moulding the interface and functionality attributes into a prototype that isn’t just viable but rather formidable too. `,
      },
    },
    {
      id: 2,
      problem: 'Struggling with Shaky Product Fundamentals?',
      solution: {
        title: 'Nurturing Robust Product Fundamentals',
        subtitle:
          'Envision a product built on a foundation of technical excellence that shields against disruptions and fuels innovation.',
        paragraphI:
          'Building on the MVP’s success, our experts fortify your product with a bedrock of sound architecture, impeccable design, and seamless user experience, intricately weaving functionalities and scalability into its very fabric.',
        paragraphII:
          'The result? A robust, user-centric product that can easily adapt to your evolving needs.',
      },
    },
    {
      id: 3,
      problem: 'Grappling with Performance amidst Rapid Scaling?',
      solution: {
        title: 'Product Scaling with Performance',
        subtitle:
          'For us, scaling is about delivering excellence consistently!',
        paragraphI:
          'Witness your product transcend boundaries when our experts orchestrate a symphony of optimization, harnessing cloud-native architecture, and agile methodologies, tackling the issues of slow performance, scalability limitations, and resource constraints.',
        paragraphII: `Whether it's managing user demand or taming intricate technical complexities, we engineer solutions that empower your product to thrive under any circumstance.`,
      },
    },
  ],
}
export const businessProcessData = {
  title: 'Change isn’t just inevitable - it’s essential.',
  subtitle:
    'We don’t promise change, we engineer it with strategic persistence, bringing a transformation that transcends beyond the surface!',
  paragraph: `Amidst the ever-changing tapestry of modern business commerce, where the echo of growth reverberates with unprecedented intensity, mere adaptations aren’t enough. Imagine a journey that traverses not only the contours of your organization's present but also the contours of its untapped potential. `,
  list: [
    {
      id: 1,
      // problem: 'Obsolete legacy systems & disjointed operations a conundrum?',
      problem:
        'Product falling behind, causing your business growth to plateau?',
      solution: {
        title: 'Product Transformation',
        subtitle:
          'A Strategic Gateway that Redefines Possibilities for Businesses!',
        paragraphI: `Product Transformation isn't just a solution – it's a manifestation. `,
        paragraphII: `In the realm of strategic decision-making, the pursuit of probabilities transcends mere relevance - it rests upon crafting a symphony of interactions that resonate deeply with users. As architects, we comprehend the stakes that are embedded within product transformation and align our transformation strategy to empower your product with contextual depth. Your vision becomes our compass as we weave a tech stack that aligns with your product nuances, market pulse, and user psyche, to forge a path that bridges product ambition with strategic execution. `,
        paragraphIII: `A transformed product reflects a revitalized brand, one that redefines expectations, solves real-world problems, and forges novel industry standards. Your product's full potential awaits.`,
      },
    },
    {
      id: 2,
      problem:
        'Manual processes & operational inefficiencies weighing your business down?',
      solution: {
        title: 'Process Automation',
        subtitle:
          'Drive Operational Excellence by Heralding into an Era of Unprecedented Productivity',
        paragraphI: `Process Automation isn't about what we implement; it's about what your business can achieve.`,
        paragraphII: `A one-size-fits-all or off-the-shelf approach barely scratches the surface of what your business truly needs. We understand that your organization thrives on intricacy – the very intricacy that can be orchestrated via a symphony of automation and precision that resonates with the complexities unique to your niche. Whether it's compliance-heavy workflows, operational frictions, or strategic decision-making, we unravel the challenges that have stymied your progress towards elevating your bragworthy propositions. `,
        paragraphIII: `Witness your operations evolve from rule-based to autonomous as processes get fluid, and your organizational narrative gets etched with bold strokes of automation and vivid hues of progress.`,
      },
    },
    {
      id: 3,
      problem:
        'Siloed data & inadequate analytics leading to ill-informed decision-making?',
      solution: {
        title: 'Business Intelligence',
        subtitle:
          'Make Informed Choices by Unlocking Actionable Insights Camouflaged in your Data',
        paragraphI: `Business Intelligence isn't just about collecting figures; it's about turning those figures into a narrative that guides your every move.`,
        paragraphII: `We unlock these narratives by – transforming raw data into insights-informing strategies, using predictive analysis to accelerate decision-making, uncovering hidden patterns through big data exploration, and devising frameworks for targeted marketing. `,
        paragraphIII: `Amidst the high stakes, it isn’t about whether to embrace business intelligence, but rather freeing companies from the dark whilst they leverage analytics for strategic agility, risk mitigation, problem-solving, and long-term sustainability in dynamic markets. `,
      },
    },
    // {
    //   id: 4,
    //   problem:
    //     'Siloed data & inadequate analytics leading to ill-informed decision-making?',
    //   solution: {
    //     title: 'Product Scaling with Performance',
    //     subtitle:
    //       'For us, scaling is about delivering excellence consistently!',
    //     paragraphI:
    //       'Witness your product transcend boundaries when our experts orchestrate a symphony of optimization, harnessing cloud-native architecture, and agile methodologies, tackling the issues of slow performance, scalability limitations, and resource constraints.',
    //     paragraphII: `Whether it's managing user demand or taming intricate technical complexities, we engineer solutions that empower your product to thrive under any circumstance.`,
    //   },
    // },
  ],
}
export const businessInnovationData = {
  // title: 'Change isn’t just inevitable - it’s essential.',
  subtitle:
    'Innovation isn’t about reinventing the wheel but a strategic imperative that empowers you to visualize customer journeys, reimagine propositions, and engineer a future brimming with potential.  ',
  paragraph: `For businesses facing the peril of products growing stale, legacy systems binding potential, sluggish processes hindering growth, and untapped data lying dormant, the need for engraining innovation as an antidote in their core fabric remains paramount. `,
  list: [
    {
      id: 1,
      problem:
        'Product failing to resonate with changing customer preferences?',
      solution: {
        title: 'Product Innovation & Extension',
        subtitle:
          'Captivating, Engaging, & Enduring Experiences that Inspire Customer Devotion',
        paragraphI: `In a landscape where differentiation is the lifeblood of businesses and products face the challenge of fading into the crowd, the mandate is clear: either evolve or evaporate. `,
        paragraphII: `We address this very dilemma - the need to transcend convention and infuse life into your offerings. We delve deep into your product’s DNA making sure it remains at the forefront of users' minds with a transformed identity, elevated relevance, strategic growth, and extension strategies.`,
        paragraphIII: `It isn't growth for growth's sake; it's a meticulously planned journey that unearths your product's latent potential, with a blueprint to captivate, engage, and endure whilst amplifying your market share, topline, visibility, and influence.`,
      },
    },
    {
      id: 2,
      problem:
        'Functional realities pushing tech aspirations against the wall?',
      solution: {
        title: 'Technology Consulting',
        subtitle:
          'Bringing Harmony in Your Overarching Business Goals Amidst the Next Leap in Tech',
        paragraphI: `The relentless march of progress entangles businesses in the intricate web of technology. The truth is that technology isn't just a tool; it's a strategic driver that necessitates expert guidance for solving dilemmas like its seamless integration, improving customer experience, process optimizations, heightening security, deciphering the digital dialect, and leveraging it for innovation.`,
        paragraphII: `Our message isn't cloaked in technical jargon; it speaks the language of business outcomes. We elevate your understanding, enabling you to make tech choices that bolster your business's growth trajectory, make technological integration a step toward pioneering disruption, and reap tangible benefits.`,
        paragraphIII: `It's your opportunity to not only navigate technology but to steer it towards your vision. The future beckons and it's digitally charged.`,
      },
    },

    {
      id: 3,
      problem: 'Leveraging AI to drive forward your business goals?',
      solution: {
        title: 'Artificial Intelligence',
        subtitle:
          'For Reshaping the Way Your Business Thinks, Operates, and Leads',
        paragraphI: `Expect optimized processes, hyper-personalized customer experiences, and data-driven decision-making. Navigating the complexities, we decode the AI landscape, translating possibilities into a language that speaks to your business objectives. 
        `,
        paragraphII: `From transforming AI aspirations into concrete strategies to ensuring AI aligns seamlessly with business objectives, we embed the technology into your initiatives and products, making it a tool of strategic precision rather than just a technological trend. 
        `,
        paragraphIII: `Your journey isn’t about practicing innovation, it’s about embracing intelligence.
        `,
      },
    },
    {
      id: 4,
      problem: 'Cloud integration & acceptance a challenge?',
      solution: {
        title: 'Cloud Computing',
        subtitle:
          'For Making Your Systems Evolve, Scale, & Adapt to Market Shifts',
        paragraphI: `Innovation without a foundation leads to missed opportunities and stagnation. We understand that harnessing innovation requires the right coherence of tools and with businesses grappling under the allure of agility, scalability, and cost efficiency, a comprehensive cloud computing strategy finds its need. `,
        paragraphII: `A reality where your business isn't limited by hardware constraints, where your ideas can transform into reality at speeds, where systems evolve & scale on demand, where critical data remains protected, and where the reality of cloud integration isn’t a hurdle. `,
        paragraphIII: `We decode your cloud proficiency, preserve your existing investments, and translate possibilities into actionable strategies that align with your unique business goals. `,
      },
    },
    {
      id: 5,
      problem:
        'Is the gap between the promise of IoT and its integration wide enough?',
      solution: {
        title: 'IoT',
        subtitle: 'For Streamlining Turbulence, One Data Point at a Time',
        paragraphI: `The longing for real-time insights, automated processes, data-driven decisions, and being at the forefront of the digital revolution is irresistible, but the path to IoT implementation can be a labyrinth of complexities. Bridging the gap between the promise of IoT and the reality of integration also presents a critical hurdle.`,
        paragraphII: `With a team of seasoned experts, we ensure that your IoT strategy seamlessly integrates with your existing infrastructure, safeguarding against disruption and aligning with your business objectives. It's not just about adopting connected devices; it's about leveraging IoT to tangibly enhance efficiencies and positioning IoT as a strategic imperative for you to innovate in the face of change.`,
      },
    },
  ],
}
export const dataManagementData = {
  // title: 'Change isn’t just inevitable - it’s essential.',
  subtitle:
    'Reshaping your data landscape with technical finesse and contextual relevance, we make way for streamlined operations, emerging trends, informed strategic choices, and an ability to seize emerging opportunities.',
  paragraph: `In a universe of data quantum, precision is paramount. Expect a dynamic fusion of technology, strategic acumen, and meticulous execution as we redefine what's possible in the realm of data management. We do it whilst solving a myriad of challenges – interoperability issues, data integrity & integration, compliance adherence, scalable data storage, and harnessing the true power of data assets. We don't just solve these dilemmas; we turn them into catalysts for growth. `,
  list: [
    {
      id: 1,
      problem: 'Data residing in silos seem impossible to unite?',
      solution: {
        title: 'Data Integration & ETL',
        subtitle:
          'Co-Piloting Business Excellence with Data Liberation, Enrichment, & Optimization',
        paragraphI: `Every data piece holds the potential to unlock new insights and growth opportunities. `,
        paragraphII: `Harnessing this potential can be a daunting task, as data resides in silos, formats, and sources that seem impossible to unite. By bridging this gap, eliminating data silos that hinder cross-functional analysis, eliminating delays in crucial insights due to slow ETL, and automating integration, we empower you to access unified, and accurate insights in real time. `,
        paragraphIII: `We are not just solving data integration and ETL problems by streamlining data processes and eliminating obvious efficiencies, rather we are shaping a future of data-driven success for your business. `,
      },
    },
    {
      id: 2,
      problem:
        'Navigating how to strategically govern data flow within your organization?',
      solution: {
        title: 'Data Governance',
        subtitle:
          'For Governing Data Ownership, Quality Standards, & Security Protocols',
        paragraphI: `Businesses in the realm of data management and governance face concerns about the availability of sensitive data, data breaches, non-compliance, data inconsistencies, formatting, and visualization. `,
        paragraphII: `We navigate this complex landscape of data regulations and security pitfalls by harmonizing your data sources into a centralized catalog, employing state-of-the-art encryption, access controls, automated compliance checks, and leveraging AI & ML to automate routine governance. Basically, a robust framework that ensures your data remains not just a secure and compliant asset but a catalyst for growth.`,
      },
    },
    {
      id: 3,
      problem: 'Seeking a data platform in the evolving digital age?',
      solution: {
        title: 'Data Platforms ',
        subtitle: 'Transforming Data Quantum into Strategic Assets',
        paragraphI: `For organizations drowning in data and yet craving for insights, the promise of data remains untapped, buried beneath a mountain of complexity, fragmentation, and inefficiency. Grappling with scattered data sources, inconsistent formats, extracting meaningful value from assets, and looming cyber threats, we not only enable organizations to reshape the landscape with cutting-edge platforms but also position them for success in the digital age. `,
        paragraphII: `Whether you need a structured repository or a flexible data lake for unified data integration,  analytics to unearth hidden patterns, a scalable architecture to grow with your data needs, or safeguarding disaster recovery for business continuity, we architect scalable environments that accelerate data retrieval and empower data-driven decision-making.`,
      },
    },
    {
      id: 4,
      problem:
        'Vouching for a structured, purpose-driven approach to data management?',
      solution: {
        title: 'Data Architecture & Modeling',
        subtitle:
          'Where Every Byte has a Purpose, Making Every Decision Backed by Insights',
        paragraphI: `Harnessing the potential that data holds demands a strategic approach that aligns data with your business objectives. Companies continue to struggle with the absence of a structured, purpose-driven approach to data management in order to extract actionable insights for informed decisions. `,
        paragraphII: `We build robust data architectural and advanced modeling frameworks that lay the foundation for data-driven decision-making and align with your business goals. Optimized data storage, access, and retrieval, behavioral insights, market trends, reduced operational bottlenecks, and enhanced data quality, we ensure that every piece of data collected serves a purpose. `,
      },
    },
  ],
}
export const engineeringSustainableData = {
  // title: 'Change isn’t just inevitable - it’s essential.',
  paragraph:
    'Benchmarking survival is an outdated aspiration. The changing business landscape demands sustainability steeped in excellence. A pursuit that isn’t just a strategic choice; but rather a non-negotiable imperative. ',
  subtitle: `Amidst the evolving terrain and tangible hurdles, legacy systems shackle growth, inefficient infrastructure obscures potential, and conventional methodologies, once reliable, now hinder agility. This is where we architect a pathway fortified by resilience, tenacity, and strategic distinction, surmounting organizational challenges into quantifiable, real-world outcomes.`,
  list: [
    {
      id: 1,
      problem: 'Legacy systems resisting change and echoing inefficiency? ',
      solution: {
        title: 'Legacy Modernization',
        subtitle:
          'Transforming your Legacy Systems from Anchors to Accelerators',
        paragraphI: `Digital transformation renders traditional frameworks obsolete. Legacy paradigms become hindrances, impeding the progress of your operations. Our seasoned professionals undertake meticulous revitalization, infusing vitality into your aging systems through a seamless alignment with contemporary technologies, ensuring flawless integration & transition, and fortifying your platforms to seamlessly accommodate business-critical applications.`,
        paragraphII: `We take into accordance the distinctive strengths of your existing systems, preserving their respective unique features. The integration, while being sensitive to the legacy, leads to a landscape where your mission-critical processes interconnect with novel functionalities without impediments, reinforcing your business continuity. `,
      },
    },
    {
      id: 2,
      problem: 'Infrastructure groaning under the weight of inefficiencies?',
      solution: {
        title: 'Infrastructure Optimization & Audit',
        subtitle:
          'Fine-tuning Ecosystems/Frameworks to Empower Agility, Eliminate Bottlenecks, & Accommodate Expansion',
        paragraphI: `Every brick of your digital fortress matters as it craves optimization in an increasingly changing digital world. We dive deep into every crevice and unearth the vulnerabilities plaguing your infrastructure, unravel hidden inefficiencies, optimize for performance, drive potency, and transform it into a resilient bastion.`,
        paragraphII: `Making your growth not chaotic but rather seamless, our solutions optimize resource utilization by reallocating computing power, storage capacity, network bandwidth, analyze network traffic patterns and optimize network architecture to minimize latency, ensure backup and recovery strategies to safeguard data integrity, ensure interoperability within the infrastructure, integrate on-premises systems with cloud platforms, eliminate data silos, implement robust security protocols, including firewalls, encryption, and access controls.  `,
      },
    },
    {
      id: 3,
      problem:
        'Outdated processes being clung on amidst a lack of support system?',
      solution: {
        title: 'Re-Engineering & Support',
        subtitle:
          'Refactoring, Re-architecting, & Rejuvenating your Systems to Effortlessly Empower Your Future',
        paragraphI: `Our experts adeptly curate bespoke strategies that methodically integrate tech and ensure new stack infusion, lean processes/development, process restructuring, strategic alignment, and user/employee experience, laying the groundwork for operations to transcend beyond mere functionalities and band-aid fixes.`,
        paragraphII: `Our Re-Engineering and Support solutions serve as meticulous architectural blueprints for your journey of reinvention. The support framework transcends reactivity; embodying proactivity and functioning as an intricate safety net that guarantees uninterrupted operations while handling disruptions. The re-engineering strategies factor in scalability, equip your enterprise with agility and empower you to adapt swiftly to market shifts and remain robust in the face of expansion.`,
      },
    },
  ],
}
