import React from 'react'

export default function HeadingAtomBanner({
  className = '',
  title,
  pageName,
  subtitle,
  themeDark = true,
  titleClass = '',
  subTitleClass = '',
}: {
  title: string
  pageName?: string
  subtitle: string
  className?: string
  themeDark?: boolean
  titleClass?: string
  subTitleClass?: string
}) {
  return (
    <div className={`${className} banner-heading-wrapper`}>
      {!themeDark ? (
        <>
          {pageName ? (
            <h5
              className={`banner-text ${subTitleClass} font-medium text-darkGray md:mt-4 mt-2 leading-normal`}
              dangerouslySetInnerHTML={{ __html: pageName }}
            />
          ) : (
            ''
          )}
          <h1
            className={`banner-text ${titleClass} font-semibold leading-tight text-gradient-light`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <h5
            className={`banner-text ${subTitleClass} font-medium text-darkGray md:mt-4 mt-2 leading-normal`}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </>
      ) : (
        <>
          <h1
            className={`banner-text ${titleClass} font-semibold leading-tight text-gradient-dark transition-all duration-1000`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <h5
            className={`banner-text ${subTitleClass} font-light text-gray md:mt-4 mt-2 leading-normal`}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </>
      )}
    </div>
  )
}
