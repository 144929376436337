import React, { useEffect } from 'react'
import { productDevelopment } from 'static/solutions'
import bgImage from 'assets/solutions/productDev.jpg'
import Banner from '../01_banner'
import Message from '../03_message'
import CuriousMinds from '../04_curiousMinds'
import ProblemStatementMobileView from '../02_problemStatementMobileView'
import GetInTouch from '../06_getInTouch'
import TempProblemStatement from '../02_temp_problemStatement'

export default function PageProductDevelopment() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main className="bg-black">
      <Banner
        title='Propelling your Product to <br className="md:block hidden" /> Handle the Demands of Success;'
        subtitle="Precision in Pixels, Excellence in Every Line"
      />
      <div className="md:block hidden">
        <TempProblemStatement data={productDevelopment} bgImage={bgImage} />
      </div>
      <div className="md:hidden block">
        <ProblemStatementMobileView data={productDevelopment} />
      </div>
      <Message theMessage="A Product Potential is always limitless so is the impact created when synergies are forged with a purpose!" />
      <CuriousMinds
        title="Get ready to decode the art and science behind a successful product evolution."
        subtitle="Check out our repository of technical wisdom, with insights that redefine the way you approach product development and scaling."
      />
      {/* <div className="blade-top-padding-xl" /> */}
      <GetInTouch
        title="The future of your product awaits!"
        subtitle="Let’s engineer excellence together!"
      />
    </main>
  )
}
