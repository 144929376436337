import React from 'react'
import overview from 'assets/workCaseStudies/bflow/dashboard.png'
import HeadingTile from 'molecules/headingTile'

export default function Overview() {
  return (
    <section className="w-container">
      <div id="overview" className=" blade-top-padding blade-bottom-padding">
        <HeadingTile
          title="Case Study Overview"
          paragraphs={[
            `Bflows emerged as the product of a deliberate, forward-thinking initiative aimed at revolutionizing traditional invoice management practices. Accounting for the ever-evolving landscape of financial operations, our team meticulously analyzed existing infrastructures to pinpoint areas for transformative change, culminating in the birth of Bflows, a cutting-edge platform driven by the proprietary Balanced Flows Technology.`,
            "The framework harnesses the power of extensive data analysis, fostering interconnected commercial relationships and ushering in a paradigm shift in how businesses handle their finances. Bflows is redefining financial interactions and empowering businesses to achieve greater efficiency and growth in today's interconnected business landscape.",
          ]}
        />

        <HeadingTile
          title="Company Overview"
          paragraphs={[
            'Bflows is redefining how businesses manage their invoicing and financial interactions. The platform bases itself on Balanced Flows Technology, a proprietary algorithmic framework that analyzes Big Data derived from mapping interconnected commercial relationships.',
            'It enables reciprocal transactions, offers sophisticated financial tools, and empowers businesses of all sizes to achieve greater financial efficiency.',
          ]}
          className="2xl:mt-32 md:mt-16 mt-10"
        />

        {/* <div className="lg:mt-10 mt-3 md:mt-6">
          <img
            className="w-full"
            src={overview}
            alt="mockup for the dashboard in the bflow product"
          />
        </div> */}
      </div>
    </section>
  )
}
