import { HeroBtn } from 'atoms/buttons'
import React from 'react'

export default function SubFooter({ handleClick }: { handleClick: any }) {
  return (
    <div className="md:blade-bottom-padding">
      <div className="sm:w-10/12 w-full sm:ml-auto bg-cover bg-center bg-[url('assets/home/subFooter.jpg')]">
        <div className=" sm:w-full px-5">
          <div className=" blade-top-padding-xl blade-bottom-padding-xl blade-top-margin-sm blade-bottom-margin-sm md:w-11/12 lg:pl-10 pl-0 block  sm:ml-auto ">
            <h5 className="md:block hidden">
              Get ready to decode the art and science behind{' '}
              <br className="md:block hidden" /> a successful product
              development and evolution.
            </h5>
            <small className="md:hidden block text-center">
              Get ready to decode the art and science behind{' '}
              <br className="md:block hidden" /> a successful product
              development and evolution.
            </small>
            <h2 className="bg-black  md:block hidden bg-clip-text text-transparent font-semibold pt-5">
              Let&rsquo;s redefine <br className="md:block hidden" />
              possibilities together!
            </h2>
            <h3 className=" md:hidden block bg-black bg-clip-text text-transparent font-semibold pt-5 text-center">
              Let&rsquo;s redefine <br />
              possibilities together!
            </h3>
            <div className=" md:grid  pt-5 md:pt-8 pb-2 hidden  place-content-center md:place-content-start">
              <HeroBtn
                text="Fill out the form"
                size="base"
                color="black"
                onClick={handleClick}
                type="button"
              />
            </div>

            <div className=" pt-5 md:pt-8 pb-2 md:hidden grid place-content-center md:place-content-start">
              <HeroBtn
                text="Fill out the form"
                size="small"
                color="black"
                onClick={handleClick}
                type="button"
              />
            </div>

            <h6 className="md:block hidden">
              Our team will get in touch with you within 24 hours.
            </h6>
            <small className="md:hidden block text-center pt-2">
              Our team will get in touch with <br /> you within 24 hours.
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}
