import HeadingTile from 'molecules/headingTile'
import React from 'react'
import { Pagination } from 'swiper'
import { SwiperSlide, Swiper } from 'swiper/react'

export default function ImpactMetrics() {
  const data = [
    {
      id: '1',
      value: '30%',
      desc: 'Reduction in Manual Invoice <br /> Handling Time',
    },
    { id: '2', value: '25%', desc: 'Increase in Cash Flow <br /> Efficiency' },
    { id: '3', value: '15%', desc: 'Reduction in Overdue <br /> Payments' },
    { id: '4', value: '1,200', desc: 'Dynamic Discounts <br /> Availed' },
    {
      id: '5',
      value: '1,600',
      desc: 'Successfully Reciprocated <br /> Transactions',
    },
  ]
  return (
    <div className="metric-swiper blade-bottom-padding-lg">
      <div className="pb-6 md:pb-8 xl:pb-10 blade-top-padding-lg">
        <HeadingTile title="Impact Metrics" center />
      </div>

      <div>
        <Swiper
          slidesPerView={1.2}
          spaceBetween={16}
          breakpoints={{
            768: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2.5,
              spaceBetween: 24,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
            1440: {
              slidesPerView: 3.5,
              spaceBetween: 32,
            },
            1536: {
              slidesPerView: 4.5,
              spaceBetween: 40,
            },
          }}
          initialSlide={0}
          autoHeight
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
          className="cursor-grab px-10 pb-10 md:pb-12 xl:pb-16"
          speed={1000}
        >
          {data.map((item) => {
            return (
              <SwiperSlide
                key={item.id}
                className="metric-card blade-top-padding blade-bottom-padding overflow-hidden"
              >
                <article className="relative grid place-content-center place-items-center gap-5 z-10">
                  <h2 className=" font-bold text-white value-gradient bg-clip-text text-transparent">
                    {item.value}
                  </h2>
                  <h5
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                    className="text-white text-opacity-60  font-semibold text-center leading-snug"
                  />
                </article>
              </SwiperSlide>
            )
          })}
          <span className="swiper-pagination" />
        </Swiper>
      </div>
    </div>
  )
}
