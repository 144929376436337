import HeadingTile from 'molecules/headingTile'
import React, { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { A11y, Pagination, Scrollbar } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/navigation'
import AIDrivenPerformancePrediction from 'assets/workCaseStudies/evolphin/productFunctions/AIDrivenPerformancePrediction.jpg'
import SeamlessAnalysis from 'assets/workCaseStudies/evolphin/productFunctions/SeamlessAnalysis&Recommendations.jpg'
import PreemptiveQualityControl from 'assets/workCaseStudies/evolphin/productFunctions/PreemptiveQualityControl.jpg'
import AIPoweredEditing from 'assets/workCaseStudies/evolphin/productFunctions/AIPoweredEditing.jpg'
import ManualEnhancement from 'assets/workCaseStudies/evolphin/productFunctions/ManualEnhancement.jpg'
import SimplifiedResizing from 'assets/workCaseStudies/evolphin/productFunctions/SimplifiedResizing.jpg'

export default function ProductFunctions() {
  const [activeSlide, setActiveSlide] = useState(0)

  const swiperInstance = React.useRef<SwiperCore | null>(null)

  const handleSlideChange = (swiper: SwiperCore): void => {
    setActiveSlide(swiper.activeIndex)
  }

  const goToSlide = (slideIndex: number): void => {
    if (swiperInstance.current) {
      const swiperRef = swiperInstance.current
      swiperRef.slideTo(slideIndex)
    }
  }
  const slides = [
    {
      id: 1,
      cover: AIDrivenPerformancePrediction,
      category: 'analyzer-module',
      title: 'AI-Driven Performance Prediction',
      description:
        'Assessing images against 40+ marketplace rules, guiding users to optimize images for better conversions.',
    },
    {
      id: 2,
      cover: SeamlessAnalysis,
      category: 'analyzer-module',
      title: 'Seamless Analysis & Recommendations',
      description:
        'Users can paste Amazon or marketplace links for instant detailed reports, recommendations, and issue prioritization.',
    },
    // add image for this one
    {
      id: 3,
      cover: PreemptiveQualityControl,
      category: 'analyzer-module',
      title: 'Preemptive Quality Control',
      description:
        'Avoids penalties by identifying and rectifying image issues before listings go public.',
    },
    {
      id: 4,
      cover: AIPoweredEditing,
      category: 'analyzer-module',
      title: 'AI-Powered Editing',
      description:
        'Automates diverse tasks like on-model fashion cropping, bulk background editing, and resizing.',
    },
    {
      id: 5,
      cover: ManualEnhancement,
      category: 'analyzer-module',
      title: 'Manual Enhancement',
      description: `Users can manually refine images using Crop.Photo's cleanup tool, eliminating artifacts and enhancing quality.`,
    },
    // add image for this one
    {
      id: 6,
      cover: SimplifiedResizing,
      category: 'analyzer-module',
      title: 'Simplified Resizing',
      description: `Resizes multiple images simultaneously, meeting marketplace standards.`,
    },
  ]

  useEffect(() => {
    console.log(activeSlide)
  }, [activeSlide])
  return (
    <section>
      <div className="w-container">
        <HeadingTile
          title="Product Functions"
          paragraphs={['Crop.Photo hosts the following AI-driven modules -']}
        />
      </div>
      <div className="flex md:gap-4 gap-1 md:mt-8 mt-4 w-container">
        <button
          onClick={() => goToSlide(0)}
          className={`${
            activeSlide <= 2 ? 'metric-card !border !border-white' : ''
          } md:text-base text-[12px] text-white !rounded-3xl md:px-6 px-3 py-2 border border-transparent transition-all duration-500 hover:bg-white hover:text-black `}
          type="button"
        >
          Listing Analyzer Module
        </button>
        <button
          onClick={() => goToSlide(3)}
          className={`${
            activeSlide <= 2 ? '' : 'metric-card !border !border-white'
          } md:text-base text-[12px] text-white !rounded-3xl md:px-6 px-3 py-2 border border-transparent transition-all duration-500 hover:bg-white hover:text-black `}
          type="button"
        >
          Photo Processor Module
        </button>
      </div>
      {/*  eslint-disable */}
      <Swiper
        onSwiper={(swiper) => (swiperInstance.current = swiper)}
        className="2xl:pb-10 lg:pb-5 pb-3 mt-6 lg:mt-10 w-containe"
        spaceBetween={50}
        modules={[Pagination, Scrollbar, A11y]}
        initialSlide={0}
        slidesPerView={1.1}
        slideToClickedSlide
        onSlideChange={(ele) => {
          // setActiveSlide(ele.activeIndex)
          handleSlideChange(ele)
        }}
        slideActiveClass="slideActiveClass"
        centeredSlides
        speed={1500}
        grabCursor
        scrollbar={{
          el: '.swiper-scrollbar',
          draggable: true,
          horizontalClass: 'bg-[#181818]',
        }}
        breakpoints={{
          480: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 1.4,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1.6,
            spaceBetween: 30,
          },
          1536: {
            slidesPerView: 1.8,
            spaceBetween: 40,
          },
        }}
      >
        {slides.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <div className="max-w-2xl mx-auto w-full">
                <div className="flex gap-10">
                  <div className="border-l-1 border-white border-opacity-20 md:block hidden" />
                  <div className="w-full ">
                    <img
                      src={item.cover}
                      alt={item.description}
                      className="w-full object-contain rounded-2xl"
                    />
                  </div>
                </div>
                <h6 className="text-white mt-4 pr-10 leading-relaxed font-semibold">
                  {item.title}
                </h6>
                <h6 className="text-white text-opacity-60 mt-1 font-light pr-10 leading-relaxed">
                  {item.description}
                </h6>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="w-container mx-auto">
        <div className="swiper-scrollbar max-w-xs h-1" />
      </div>{' '}
    </section>
  )
}
