import { HeroBtn } from 'atoms/buttons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function GetInTouch({
  title,
  subtitle,
}: {
  title?: string
  subtitle?: string
}) {
  const navigate = useNavigate()
  const goToContact = () => navigate('/contact-us')
  return (
    <div className="w-container 2xl:max-w-screen-lg max-w-screen-md blade-bottom-padding-xl blade-top-padding-xl">
      <h2 className="text-gradient-dark text-center font-semibold ">{title}</h2>

      <h4 className="text-white text-opacity-50 text-center mt-4">
        {subtitle}
      </h4>
      <div className="flex justify-center xlg:mt-10 md:mt-6 mt-4">
        <HeroBtn
          type="button"
          text="Contact Us"
          size="small"
          color="black"
          onClick={goToContact}
        />
      </div>
    </div>
  )
}
