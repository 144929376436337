import React from 'react'

export default function HeadingTile({
  title,
  subtitle,
  paragraphs,
  className,
  center = false,
}: {
  title: string
  subtitle?: string
  paragraphs?: string[]
  className?: string
  center?: boolean
}) {
  return (
    <div
      className={`${className} ${
        center ? 'mx-auto max-w-2xl text-center flex flex-col items-center' : ''
      }`}
    >
      {title ? (
        <h4 className="text-gradient-dark font-semibold w-fit">{title}</h4>
      ) : (
        ''
      )}

      {subtitle ? (
        <h6 className="font-light text-white opacity-80 flex-1 max-w-2xl leading-relaxed mt-4">
          {subtitle}
        </h6>
      ) : (
        ''
      )}

      <div
        className={`flex justify-between lg:flex-row flex-col text-white mt-4 ${
          center ? 'flex-col gap-5' : '2xl:gap-20 lg:gap-16 gap-5'
        }`}
      >
        {paragraphs?.map((ele, index) => {
          const key = `${index}-paragraph`
          return (
            <h6
              key={key}
              className="font-light flex-1 max-w-2xl leading-relaxed"
            >
              {ele}
            </h6>
          )
        })}
      </div>
    </div>
  )
}
