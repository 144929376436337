import React from 'react'

const salientPoints = [
  {
    title: 'MVP',
    desc: "Our teams transcend beyond the 'mere viability' by building an MVP that rolls out swiftly, garners traction, and sets the stage for future product optimizations.",
  },
  {
    title: 'Product Fundamentals & Scaling',
    desc: 'Building upon an MVP’s success, we fortify a product with a bedrock of sound backend architecture, interactive UI, and seamless UX and curate a symphony of optimization, cloud-native architecture, and agile methodologies.',
  },
  {
    title: 'Product Transformation & Innovation',
    desc: "We weave a stack that empowers an existing product with contextual depth and changing tech dynamics, making sure it remains in the users' minds with a transformed identity and elevated future relevance.",
  },
  {
    title: 'Data Integration & ETL',
    desc: 'We bridge the gap of data residing in silos, formats, and sources to enable access to unified, accurate, and actionable insights in real time.',
  },
]

export default function PossibilityCarousel() {
  return (
    <section className=" blade-top-padding blade-bottom-padding">
      <div className="w-container-sm">
        <div className="grid gap-4 md:pb-2 pb-5">
          <h1 className={` font-semibold lg:block hidden  text-gradient-dark`}>
            From possibilities to <br className="md:block hidden" /> impactful
            outcomes,
          </h1>
          <h3 className={`  lg:hidden block font-semibold text-gradient-dark`}>
            From possibilities to <br className="md:block hidden" /> impactful
            outcomes,
          </h3>
          <h5 className=" lg:block hidden leading-normal font-light text-gray">
            Our teams empower you to adopt, <br className="md:block hidden" />{' '}
            evolve, & conquer with ingenuity
          </h5>
          <small className=" lg:hidden block leading-normal font-light text-gray">
            Our teams empower you to adopt, <br className="md:block hidden" />{' '}
            evolve, & conquer with ingenuity
          </small>
        </div>
        <div className="flex justify-end xlg:mt-16 md:mt-6 mt-0 2xl:pr-10 lg:pr-16">
          <div className="2xl:max-w-xl max-w-lg grid gap-5">
            <h5 className="leading-normal font-light text-white lg:block hidden">
              We engineer custom-made SaaS & Enterprise Software Solutions with
              a harmonious union of form and function, converting problem
              statements into elegant algorithms, full-stack automation,
              operational efficiency, and user gratification.
            </h5>
            <small className="leading-normal font-light text-white lg:hidden block">
              We engineer custom-made SaaS & Enterprise Software Solutions with
              a harmonious union of form and function, converting problem
              statements into elegant algorithms, full-stack automation,
              operational efficiency, and user gratification.
            </small>
          </div>
        </div>
      </div>

      <div className=" w-container-lg 2xl:w-container grid gap-y-7 md:gap-y-6  lg:gap-x-3 2xl:gap-x-7  grid-cols-1 md:grid-cols-2 lg:grid-cols-4 blade-top-padding-lg">
        {salientPoints.map((elem, index: number) => {
          const key = `${index}`
          return <Card value={elem} key={key} />
        })}
      </div>
    </section>
  )
}

function Card({ value }: { value: { title: string; desc: string } }) {
  const { title, desc } = value
  return (
    <article className="bg-valuecard grid place-content-between relative px-4 py-7 lg:p-6 2xl:p-8 ">
      <div>
        <span className="text-xl 2xl:text-2xl leading-tight block font-semibold pb-3 md:pb-4">
          {title}
        </span>
        <span className="text-dark text-sm xl:text-base 2xl:text-lg leading-snug pr-5 block pb-4">
          {desc}
        </span>
      </div>
    </article>
  )
}
