import React, { useEffect } from 'react'
import HeadingAtom from 'atoms/headingAtom'
import { TextAnchor } from 'atoms/links'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import './style.css'
import case_study_onplan from 'assets/home/caseStudies/case_study_onplan.png'
import case_study_bflows from 'assets/home/caseStudies/case_study_bflows.png'
import case_study_cloudeagle from 'assets/home/caseStudies/case_study_cloudeagle.png'
import case_study_evolphin from 'assets/home/caseStudies/case_study_evolphin.png'
import CaseStudies from 'organisms/caseStudies'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

function Slide({ item }: { item: any }) {
  return (
    <div className="flex sm:flex-row flex-col-reverse h-full justify-between p-4 border-2 border-gray border-opacity-30 rounded-xl">
      <div className="flex-1 md:p-5 pt-4 min-h-full">
        <div className="flex justify-end h-full gap-3 flex-col 2xl:pr-16 xl:pr-10">
          <h6 className=" text-opacity-60 text-white font-light">{item.tag}</h6>
          <h4 className="text-large text-white leading-snug">{item.title}</h4>
          <h6 className=" text-opacity-60 text-white leading-relaxed ">
            {item.subtitle}
          </h6>
          <TextAnchor href={item.link} text="Know More" />
        </div>
      </div>
      <div className="flex-1 md:min-h-[420px] rounded-xl overflow-hidden">
        <img
          className="sm:h-full h-56 w-full object-cover object-center rounded-xl"
          src={item.imgUrl}
          alt=""
        />
      </div>
    </div>
  )
}

export default function Implementation() {
  const slides = [
    {
      title: 'Financial Planning & Analysis',
      subtitle:
        'Unlocking financial intelligence with powerful planning, budgeting, forecasting, layering visualization, integration, and collaboration capabilities.',
      tag: 'OnPlan',
      imgUrl: case_study_onplan,
      link: '/',
      id: 1,
    },
    {
      title: 'Invoice Management',
      subtitle:
        'Building baking confidence with reducing risks, payment workflows, payment gateway integration, financial insights and reporting, and optimized payment cycle.',
      tag: 'BFlows',
      imgUrl: case_study_bflows,
      link: '/',
      id: 2,
    },
    {
      title: 'Spend Management',
      subtitle:
        'IT spending made simple with cost optimization, assisted buying, contract management, and procurement workflows.',
      tag: 'Cloudeagle',
      imgUrl: case_study_cloudeagle,
      link: '/',
      id: 3,
    },
    {
      title: 'AI-based Image Processing',
      subtitle:
        'Unleashing visual brilliance with image-based specification, AI-enabled image-processing solutions, digital asset management, post-production, and publishing.',
      tag: 'Evolphin',
      imgUrl: case_study_evolphin,
      link: '/',
      id: 4,
    },
  ]

  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: '.paragraph-text',
        start: 'top 80%',
        end: 'bottom top',
        markers: false,
        toggleClass: 'paragraph-fade-in',
      },
    })
  }, [])

  return (
    <>
      <div className="implementation-wrapper w-container-sm blade-top-padding-xl blade-bottom-padding-lg blade-top-margin">
        <HeadingAtom
          title="From Concept to Reality:"
          subtitle="Helping You Scale with Purpose"
          className="2xl:max-w-2xl max-w-md"
          triggerAnim="implementation-wrapper"
        />

        <div className="flex justify-end xlg:mt-10 md:mt-6 mt-0 2xl:pr-10 lg:pr-16">
          <div className="paragraph-text  2xl:max-w-xl max-w-lg grid gap-5">
            <h4 className="opacity-0 translate-y-10 transition-all duration-500 leading-normal font-light text-white">
              We are making businesses dream big and pushing the boundaries of
              what&apos;s possible.
            </h4>
            <h5 className="opacity-0 translate-y-10 transition-all duration-500  leading-normal font-light text-white text-opacity-70">
              It’s through an alchemy of intellect, empathy, and strategy, we
              bring ideas to life.
            </h5>
            <h5 className="opacity-0 translate-y-10 transition-all duration-500  leading-normal font-light text-white text-opacity-70">
              Our thrill to turn problems into scalable opportunities has
              propelled us to
              <span className=" text-white font-semibold">
                {' '}
                architect breakthrough solutions{' '}
              </span>
              and turn hidden potentials into radical performance gains.
            </h5>
          </div>
        </div>
      </div>

      <CaseStudies />

      {/* Carousel */}
      {/* <div className="concept-reality blade-bottom-padding-xl relative">
        <div className="cursor hidden justify-center items-center z-[9999999] w-24 h-12 absolute border border-black bg-black bg-opacity-50 top-0 left-0 rounded-full">
          <div>
            <svg
              stroke="white"
              fill="white"
              strokeWidth="0"
              viewBox="0 0 16 16"
              className="h-10 rotate-180"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </div>
          <div>
            <svg
              stroke="white"
              fill="white"
              strokeWidth="0"
              viewBox="0 0 16 16"
              className="h-10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </div>
        </div>
        <Swiper
          className="md:pb-20 pb-10"
          spaceBetween={50}
          modules={[Pagination]}
          initialSlide={1}
          slidesPerView={1.1}
          centeredSlides
          slideToClickedSlide
          slideActiveClass="slideActiveClass"
          slideNextClass="slideNextClass"
          slidePrevClass="slidePrevClass"
          speed={1500}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            480: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            960: {
              slidesPerView: 1.4,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 1.6,
              spaceBetween: 30,
            },
            1536: {
              slidesPerView: 1.8,
              spaceBetween: 40,
            },
          }}
        >
          {slides.map((item, index) => {
            return (
              <SwiperSlide key={item.id}>
                <Slide item={item} />
              </SwiperSlide>
            )
          })}
          <span className="swiper-pagination" />
        </Swiper>
      </div> */}
    </>
  )
}
