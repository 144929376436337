import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'
import React from 'react'

export default function CuriousMinds({
  title,
  subtitle,
}: {
  title?: string
  subtitle?: string
}) {
  const navigate = useNavigate()
  const goToContact = () => navigate('/coming-soon')

  return (
    <div className="blade-bottom-padding-x">
      <section className="flex flex-wrap md:flex-row flex-col-reverse w-container-sm border border-gray border-opacity-30 px-0 rounded-lg overflow-hidden">
        <div className="bg-darkSlate flex md:w-6/12  2xl:p-20 xlg:p-20 lg:p-14 md:p-8 p-4">
          <div className="max-w-lg flex md:gap-5 gap-3 flex-col">
            <h3 className="text-48 font-semibold">Curious Minds!</h3>
            <h6 className="font-semibold">{title}</h6>
            <h6>{subtitle}</h6>
            <div className="xlg:mt-10 md:mt-6 ">
              <HeroBtn
                type="button"
                text="View Blogs"
                size="small"
                color="transparent"
                onClick={goToContact}
              />
            </div>
          </div>
        </div>
        <div className="flex-1 sm:min-h-[300px] min-h-[230px] md:w-6/12 bg-[url(assets/services/metaverse.jpg)] bg-no-repeat bg-cover bg-center opacity-80" />
      </section>
    </div>
  )
}
