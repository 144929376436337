import React, { useEffect } from 'react'
import { engineeringSustainableData } from 'static/solutions'
import bgImage from 'assets/solutions/innovation.jpg'

import Banner from '../01_banner'
import Message from '../03_message'
import CuriousMinds from '../04_curiousMinds'
import ProblemStatementMobileView from '../02_problemStatementMobileView'
import GetInTouch from '../06_getInTouch'
import TempProblemStatement from '../02_temp_problemStatement'

export default function PageEngineeringSustainability() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main className="bg-black">
      <Banner
        title='Bridging the doors of Legacy, <br class="hidden md:block"/> Infrastructure, & Re-Engineering'
        subtitle="Transforming systems, processes, & workflows into beacons of longevity"
      />
      <div className="md:block hidden">
        <TempProblemStatement
          data={engineeringSustainableData}
          bgImage={bgImage}
        />
      </div>
      <div className="md:hidden block">
        <ProblemStatementMobileView data={engineeringSustainableData} />
      </div>
      <Message theMessage="Stagnate or soar – the choice is always a stark one." />
      <CuriousMinds title="Traverse the intersection of tech and tactics as we engineer sustainability to embrace operational fluidity, scalability, and performance like never before." />
      <GetInTouch
        title="Ready to Redefine the Possibilities in Operational Excellence?"
        subtitle="Watch your systems evolve with streamlined workflows, seamless scalability, and revitalized efficiency."
      />
    </main>
  )
}
