import React from 'react'
import elem1 from 'assets/landingPage/clientele/elem (1).png'
import elem2 from 'assets/landingPage/clientele/elem (2).png'
import elem3 from 'assets/landingPage/clientele/elem (3).png'
import elem4 from 'assets/landingPage/clientele/elem (4).png'
import elem5 from 'assets/landingPage/clientele/elem (5).png'
import elem6 from 'assets/landingPage/clientele/elem (6).png'
import elem7 from 'assets/landingPage/clientele/elem (7).png'
import elem8 from 'assets/landingPage/clientele/elem (8).png'
import elem9 from 'assets/landingPage/clientele/elem (9).png'

const slides = [
  elem1,
  elem2,
  elem3,
  elem4,
  elem5,
  elem6,
  elem7,
  elem8,
  elem9,
  elem1,
  elem2,
  elem3,
  elem4,
  elem5,
]

export default function CardsGrid() {
  return (
    <section className="blade-bottom-padding">
      <section className="pb-6 md:pb-8 lg:pb-10">
        <h5 className="text-gray text-center px-3">
          Leading the charge for Industries!
        </h5>
      </section>
      <section>
        <div className="infinite-carousel">
          <div className="slide-track">
            {slides.map((elem, index: number) => {
              const key = `${index}`
              return (
                <div className="elem px-2 " key={key}>
                  <img
                    src={elem}
                    height="100"
                    width="250"
                    alt="Clientele of Innogent technologies"
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </section>
  )
}
