import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SubFooter from 'organisms/subFooter'
import logo from 'assets/workCaseStudies/onPlan/onPlanLogo.svg'

import './onplan.css'
import Banner from '../similarSection/banner'
import IntroVideo from './introVideo'
import Overview from './overview'
import ProblemStatement from './problemStatements'
import ProductFunctions from './productFunctions'
import Benefits from './benefits'
import Approach from './approach'
import ImpactMetrics from './impactMetrics'
import Conclusion from '../similarSection/conclusion'
import Proficiency from './proficiency'

export default function PageOnPlan() {
  const [isActive, setIsActive] = useState<any>(1)
  const navigate = useNavigate()

  const tabManagementData = [
    {
      id: 1,
      text: 'Overview',
      sectionId: 'overview',
    },
    {
      id: 2,
      text: 'Problem Statements',
      sectionId: 'problem-statements',
    },
    {
      id: 3,
      text: 'Product Functions',
      sectionId: 'product-functions',
    },
    {
      id: 4,
      text: 'Benefits',
      sectionId: 'benefits',
    },
    {
      id: 5,
      text: 'Approach',
      sectionId: 'approach',
    },
    {
      id: 6,
      text: 'Proficiency',
      sectionId: 'proficiency',
    },
    {
      id: 7,
      text: 'Impact Metrics',
      sectionId: 'impact-metrics',
    },
    {
      id: 8,
      text: 'Conclusion',
      sectionId: 'conclusion',
    },
  ]

  const handleSectionNavigation = (sectionId: string) => {
    const ele = document.querySelector(`#${sectionId}`)
    ele?.scrollIntoView({ behavior: 'smooth' })
  }

  const goToContact = () => navigate('/contact-us')

  const handleScroll = () => {
    const sec = document.querySelectorAll('.section-wrapper')

    sec.forEach((item, ind) => {
      const rect = item?.getBoundingClientRect()
      if (rect) {
        const isInView =
          rect.top >= -1 &&
          rect.left >= 0 &&
          rect.top <= window.innerHeight / 5 &&
          rect.right >= window.innerHeight / 5

        if (isInView) {
          ind += 1
          setIsActive(ind)
        }
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isActive])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="onplan-wrapper">
      <Banner
        title="Financial Planning & Analysis with"
        subTitle={`Driving Decision-making with Visibility into <br className="lg:hidden sm:block hidden" /> your  Financial & Operational Dynamics`}
        logo={logo}
        logoShadow
      />
      <IntroVideo />

      <div className="blade-top-margin relative lg:blade-bottom-padding lg:blade-bottom-margin-sm">
        <div className="lg:flex hidden w-fit z-[999] d sticky top-[90vh] justify-center 2xl:gap-10 gap-6 mx-auto 2xl:py-3 py-2 px-6 lg:px-16 border-[1px] border-zinc-800 rounded-3xl bg-gradient-to-tl from-zinc-800 to-black">
          {tabManagementData.map((item, i) => {
            return (
              <button
                className={`text-white 2xl:text-base text-sm font-light whitespace-nowrap ${
                  isActive === item.id ? 'opacity-100' : 'opacity-50'
                }`}
                type="button"
                onClick={() => handleSectionNavigation(item.sectionId)}
                key={item.id}
              >
                {item.text}
              </button>
            )
          })}
        </div>

        <div className="section-wrapper blade-top-padding-xs" id="overview">
          <Overview />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="problem-statements"
        >
          <ProblemStatement />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="product-functions"
        >
          <ProductFunctions />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="benefits">
          <Benefits />
        </div>

        <div className="section-wrapper blade-top-padding-xl" id="approach">
          <Approach />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="proficiency">
          <Proficiency />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="impact-metrics"
        >
          <ImpactMetrics />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="conclusion">
          <Conclusion
            title="Conclusion"
            paragraphs={[
              'OnPlan is the solution for growing organizations that don’t have the time or resources for legacy planning tools. Courtesy of an eccentric approach to product development and scaling, OnPlan has carved a niche in the FP&A space, attracted capital, and positioned itself better in a desirable customer quadrant.',
            ]}
          />
        </div>
      </div>
      <SubFooter
        title='Want to develop your <br class="md:block hidden" />  SaaS application?'
        cta={[
          {
            type: 'primary',
            text: `Let's connect!`,
            onClick: goToContact,
            color: 'black',
          },
        ]}
      />
    </div>
  )
}
