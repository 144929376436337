import HeadingTile from 'molecules/headingTile'
import React from 'react'

export default function Proficiency() {
  return (
    <div className="w-container text-white">
      <HeadingTile title="Proficiency" />

      <h3 className="lg:w-8/12 md:w-11/12 2xl:pr-16 pr-0 leading-normal">
        At Innogent, we specialize in navigating intricate technological
        landscapes, delivering sophisticated solutions that resonate deeply with
        CTOs seeking refined financial planning strategies.
      </h3>

      <div className="lg:w-7/12 md:w-10/12 ml-auto grid md:gap-6 gap-3 lg:mt-14 mt-3 text-white">
        <h6 className="opacity-100">
          Within OnPlan&apos;s architecture, we addressed the intricate
          challenges of data ingestion by seamlessly integrating an array of
          platforms—from QuickBooks to Salesforce—crafting tailored pipelines
          that ensure a seamless flow of information.
        </h6>
        <h6 className="opacity-60">
          Scalability emerged as a pivotal obstacle, which prompted us to
          engineer a custom solution within the Google ecosystem, leveraging
          BigQuery to facilitate fault-tolerant operations. Transcending
          industry norms, we revolutionized the onboarding processes by
          condensing timelines from weeks to mere days.
        </h6>
        <h6 className="opacity-60">
          The application stands as a testament to our commitment to excellence
          in reshaping financial landscapes, exemplified through widened market
          access, amplified profit margins, and scalability to integrate AI
          seamlessly. Championing the fusion of Google Sheets&apos;
          computational prowess, we further redefined the paradigm of financial
          modeling and strategic decision-making.
        </h6>
        <h6 className="opacity-60">
          Our vision extends beyond mere conventions, ensuring that OnPlan
          stands as a beacon of innovation, poised at the forefront of
          revolutionizing financial analysis and foresight.
        </h6>
      </div>
    </div>
  )
}
