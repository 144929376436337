import React, { forwardRef } from 'react'
import { HeroBtn } from 'atoms/buttons'
import BannerForm from './bannerForm'

const stats = [
  {
    value: '50+',
    desc: 'Clients',
  },
  {
    value: '60%',
    desc: "of the clients converted into <br  class='sm:block hidden' /> long-term engagement partners",
  },
  {
    value: '80+',

    desc: 'Projects successfully <br class="md:block hidden" /> completed',
  },
]

export default function Banner({
  title,
  handleClick,
  formRef,
  formType,
  isSubtitle,
  subtitle,
  isHeader,
  header,
}: {
  isSubtitle: boolean
  isHeader: boolean
  subtitle: string
  header: string
  formRef: any
  handleClick: any
  formType: string
  title: string
}) {
  return (
    <section className="pb-5 ">
      <section className="grid grid-cols-1 lg:grid-cols-2 gap-x-3  w-container blade-bottom-padding pt-16 md:blade-top-padding">
        <div className=" flex flex-col justify-center gap-3 lg:gap-12  pr-3 blade-top-padding-lg  ">
          <div>
            <div className="md:text-left text-center">
              {isHeader && (
                <span className=" text-gray pb-1 font-normal text-[12px] md:text-base lg:text-xl pt-4 block">
                  {header}
                </span>
              )}
            </div>

            <span
              dangerouslySetInnerHTML={{ __html: title }}
              className="font-normal leading-tight ml-auto md:ml-0 mr-auto  max-w-md text-gradient-dark text-2xl md:text-3xl xl:text-[2.25rem] 2xl:text-[2.5rem] md:max-w-xl md:w-11/12 block md:text-left text-center"
            />
            <div className=" md:text-left text-center">
              {isSubtitle && (
                <span className=" text-blue font-semibold text-sm md:text-base lg:text-xl  pt-4  block">
                  {subtitle}
                </span>
              )}
            </div>
          </div>

          <div className="grid md:place-content-start place-content-center pt-3 md:pt-4 pb-7 md:pb-6 lg:pb-10 lg:hidden">
            <HeroBtn
              type="button"
              color="blue"
              onClick={handleClick}
              text="Signup"
              size="small"
            />
          </div>
          <div className="justify-center md:justify-start flex flex-wrap md:pb-8 lg:pb-14 sm:pb-5  gap-y-7 gap-x-16">
            {stats.map((elem, index) => {
              const key = `${index}`
              return <StatCard value={elem.value} desc={elem.desc} key={key} />
            })}
          </div>
        </div>
        <div className="lg:pr-3 blade-top-padding-lg " ref={formRef}>
          <BannerForm formType={formType} />
        </div>
      </section>
    </section>
  )
}

function StatCard({ value, desc }: { value: string; desc: string }) {
  return (
    <article className="min-w-[70px]  text-center md:text-left">
      <span className="font-bold  md:block hidden leading-tight text-white text-40">
        {value}
      </span>
      <h2 className=" md:hidden block font-bold  leading-tight text-white">
        {value}
      </h2>

      <span
        dangerouslySetInnerHTML={{ __html: desc }}
        className="text-[12px] md:text-base block font-light text-white  text-opacity-80 mt-1 md:mt-3 leading-normal"
      />
    </article>
  )
}
