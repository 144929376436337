import HeadingAtom from 'atoms/headingAtom'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { BeyondBoardroomsData } from 'static/culture'

function Slide({ item }: { item: any }) {
  return (
    <div className="h-full border-opacity-10 rounded-2xl ">
      <div className="lg:h-[60vh] md:min-h-[420px] rounded-2xl overflow-hidden">
        <img
          className="w-full object-cover object-center"
          src={item.imgUrl}
          alt=""
        />
      </div>
    </div>
  )
}
export default function BeyondBoardrooms() {
  return (
    <section className="building-bridges-wrapper blade-top-padding-xl bg-black">
      <HeadingAtom
        title="Building Bridges Beyond Boardrooms!"
        subtitle="Zip-Lining through some Epic Retreats!"
        className="max-w-2xl mx-auto text-center"
        themeDark
      />

      <div className="blade-top-margin ">
        <Swiper
          className="lg:pb-0 pb-12"
          spaceBetween={50}
          modules={[Navigation]}
          initialSlide={1}
          slidesPerView={1.1}
          centeredSlides
          slideToClickedSlide
          slideActiveClass="slideActiveClass_buildBridges"
          slideNextClass="slideNextClass_buildBridges"
          slidePrevClass="slidePrevClass_buildBridges"
          speed={800}
          loop
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: '.next-btn',
            prevEl: '.prev-btn',
          }}
          breakpoints={{
            480: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            960: {
              slidesPerView: 1.4,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 1.6,
              spaceBetween: 30,
            },
            1536: {
              slidesPerView: 1.5,
              spaceBetween: 40,
            },
          }}
        >
          {BeyondBoardroomsData.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <Slide item={item} />
              </SwiperSlide>
            )
          })}

          <div className="swiper-navigation justify-end gap-2 inline-flex mx-auto absolute 2xl:bottom-8 lg:bottom-6 bottom-0 2xl:right-[20%] md:right-[22%] right-[5%] z-40">
            <span className="prev-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 69 69"
                className="lg:w-14 lg:h-14 h-10 w-10 hover:opacity-80 transition-all border-2 border-transparent  hover:scale-105 rounded-full cursor-pointer"
              >
                <g filter="url(#filter0_b_2023_1041)" opacity="0.7">
                  <rect
                    width="68"
                    height="68"
                    fill="#D9D9D9"
                    rx="34"
                    transform="matrix(-1 0 0 1 68.443 .202)"
                  />
                </g>
                <path
                  fill="#fff"
                  d="M22.35 33.374a1.17 1.17 0 000 1.656l7.449 7.448a1.17 1.17 0 101.655-1.655l-6.621-6.62 6.621-6.622a1.17 1.17 0 10-1.655-1.655l-7.449 7.448zm28.002 1.999a1.17 1.17 0 000-2.341v2.34zm-27.174 0h27.174v-2.341H23.178v2.34z"
                />
                <defs>
                  <filter
                    id="filter0_b_2023_1041"
                    width="118"
                    height="118"
                    x="-24.557"
                    y="-24.798"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                      in="BackgroundImageFix"
                      stdDeviation="12.5"
                    />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_2023_1041"
                    />
                    <feBlend
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_2023_1041"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </span>
            <span className="next-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 69 69"
                className="lg:w-14 lg:h-14 h-10 w-10 hover:opacity-80 transition-all border-2 border-transparent  hover:scale-105 rounded-full cursor-pointer"
              >
                <g filter="url(#filter0_b_2023_1044)" opacity="0.7">
                  <rect
                    width="68"
                    height="68"
                    x="0.443"
                    y="0.202"
                    fill="#D9D9D9"
                    rx="34"
                  />
                </g>
                <path
                  fill="#fff"
                  d="M46.536 33.374a1.17 1.17 0 010 1.656l-7.45 7.448a1.17 1.17 0 11-1.654-1.655l6.62-6.62-6.62-6.622a1.17 1.17 0 011.655-1.655l7.449 7.448zm-28.003 1.999a1.17 1.17 0 110-2.341v2.34zm27.175 0H18.533v-2.341h27.175v2.34z"
                />
                <defs>
                  <filter
                    id="filter0_b_2023_1044"
                    width="118"
                    height="118"
                    x="-24.557"
                    y="-24.798"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                      in="BackgroundImageFix"
                      stdDeviation="12.5"
                    />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_2023_1044"
                    />
                    <feBlend
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_2023_1044"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </span>
          </div>
        </Swiper>
      </div>
    </section>
  )
}
