import React, { useEffect } from 'react'
import SubFooter from 'organisms/subFooter'
import { useNavigate } from 'react-router-dom'
import Banner from './01_banner'
import CausesAndActions from './02_causesAndActions'
import Team from './03_team'
import Investors from './04_investors'
import Timeline from './05_timeline'

export default function PageAbout() {
  const navigate = useNavigate()
  const goToContact = () => navigate('/contact-us')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="bg-black">
      <Banner />
      <CausesAndActions />
      {/* <Team /> */}

      <Investors />
      <Timeline />
      <SubFooter
        subtitle={[
          {
            type: 'primary',
            value:
              "We're seeking individuals who can leap tall business challenges in a single bound.",
          },
          { type: 'secondary', value: 'Step into a World of Possibilities!' },
        ]}
        title='Shape the Future <br class="md:block hidden" />of Businesses with Us!'
        cta={[
          {
            type: 'primary',
            text: 'Join us',
            onClick: goToContact,
            color: 'transparent',
          },
        ]}
      />
    </div>
  )
}
