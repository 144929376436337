import engineering from 'assets/services/services-elements/service_elements_engineering_excellence.svg'
import productDev from 'assets/services/services-elements/service_elements_leanproduct_development.svg'
import seismic from 'assets/services/services-elements/service_elements_seismic.svg'
import softwareDev from 'assets/services/services-elements/service_elements_software_development.svg'
import teamAugmentation from 'assets/services/services-elements/service_elements_team_augmentation.svg'

export const featuresData = [
  {
    id: 0,
    title: 'Consulting for a Seismic Shift',
    subtitle: 'Where Potential Finds Purpose in Transformation!',
    imgSrc: seismic,
    paragraph:
      "We're not here just to consult but to catalyze business transformations. ",
    subParagraph:
      'Expect disruptive strategies bounded by excellence in every facet, forging new paths with unapologetic authenticity. Perhaps a consulting mantra you can expect from us while we work your way from insight to foresight, guiding your business through uncharted waters with a visionary perspective.',
  },

  {
    id: 1,
    title: 'Custom Software Development',
    subtitle:
      'Building SaaS & Enterprise Solutions, Fast-Forward Line by Line!',

    imgSrc: softwareDev,
    paragraph:
      'Curating a harmonious union of form and function to architect your digital products.',
    subParagraph:
      'Expect a coherence of efficiency, scalability, security, and surgical problem-solving, as we empower your business with bespoke software development solutions. Engineering custom development one line of code at a time, we convert complex challenges into elegant algorithms, full stack automation, operational dexterity, and user gratification.',
  },
  {
    id: 2,
    title: 'Team Augmentation',
    subtitle: 'Harness a New Paradigm in Technical Synergy!',
    imgSrc: teamAugmentation,

    paragraph: 'Blurring the lines between your team and ours. ',
    subParagraph:
      'Expect a symphony of technical acumen, velocity, nimble problem-solving, and precision execution converging into a quantum leap in your project dynamics as our teams work in a concerted effort to materialize your product or business foresight. We hub an indomitable force that synergizes to dissolve boundaries, elevate growth curves, and curate organizational case studies for the industry to learn.',
  },
  {
    id: 3,
    title: 'Lean Product Development',
    subtitle:
      'Elevating Ideas into Icons with Velocity, Efficiency, and Precision!',
    imgSrc: productDev,
    paragraph:
      'Anticipate a journey where ideas crystallize into market-ready marvels at warp speed. ',
    subParagraph: `Witness lean development becoming your product's hallmark. Wielding agile methodologies, meticulous roadmaps, iterative sprints, streamlined workflows, precision engineering, and perpetual refinements, we sculpt products that transcend time-to-market expectations, all while conserving resources and accelerating outcomes. `,
  },
  {
    id: 4,
    title: 'Engineering Excellence',
    subtitle: 'Etched into Every Detail!',
    imgSrc: engineering,

    paragraph: 'An engineering symposium where challenges bow to expertise.',
    subParagraph:
      'Brace yourself for an orchestration of precision, where every line of code, every architecture, every UI, and every blueprint transforms into an epitome. A canvas that bears witness to a fusion of art and engineering, where functionality is a masterpiece and innovation is inherent.',
  },
]
