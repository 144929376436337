import HeadingTile from 'molecules/headingTile'
import React, { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { A11y, Pagination, Scrollbar } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/navigation'

import ApplicationDiscovery from 'assets/workCaseStudies/cloudeagle/productFunction/new/applicationDiscovery.png'
import contractManagement from 'assets/workCaseStudies/cloudeagle/productFunction/new/contractManagement.png'
import costOptimization from 'assets/workCaseStudies/cloudeagle/productFunction/new/costOptimization.png'
import vendorResearch from 'assets/workCaseStudies/cloudeagle/productFunction/new/vendorResearch.png'
import procurementWorkflow from 'assets/workCaseStudies/cloudeagle/productFunction/new/procurementWorkflow.png'

export default function ProductFunctions() {
  const [activeSlide, setActiveSlide] = useState(0)

  const swiperInstance = React.useRef<SwiperCore | null>(null)

  const handleSlideChange = (swiper: SwiperCore): void => {
    setActiveSlide(swiper.activeIndex)
  }

  const goToSlide = (slideIndex: number): void => {
    if (swiperInstance.current) {
      const swiperRef = swiperInstance.current
      swiperRef.slideTo(slideIndex)
    }
  }

  const slides = [
    {
      id: 1,
      cover: ApplicationDiscovery,
      category: 'analyzer-module',
      title: 'Application Discovery',
      description: `CloudEagle's extensive application database offers over 300+ integrations, browser extensions, and 1,50,000 applications further simplifying the discovery and making categorizing foolproof.`,
    },
    {
      id: 2,
      cover: contractManagement,
      category: 'analyzer-module',
      title: 'Contract Management and Metadata Extraction',
      description:
        'Ensuring prompt notification of contract renewals, automated workflows, one-stop storage of contracts, and extracting important information from contracts. ',
    },
    {
      id: 3,
      cover: costOptimization,
      category: 'analyzer-module',
      title: 'Cost Optimization',
      description:
        'The application identifies, tracks, and optimizes duplicate, unused, redundant, and hidden applications.',
    },
    {
      id: 4,
      cover: vendorResearch,
      category: 'analyzer-module',
      title: 'Vendor Research',
      description: `With the power of AI and Machine Learning algorithms, the application surfaces the right software for your needs, so you can optimize your software dollars and fast-track your buying process.`,
    },
    {
      id: 4,
      cover: procurementWorkflow,
      category: 'analyzer-module',
      title: 'Procurement Workflow',
      description: `Housing a suite of ready-to-use no-code workflow templates, custom intake request forms, and an on-demand SaaS buying team, the application enables users to streamline their buying and renewal cycle.`,
    },
  ]

  return (
    <section>
      <div className="w-container">
        <HeadingTile title="Product Functions" />
      </div>
      {/*  eslint-disable */}
      <Swiper
        className="2xl:pb-10 lg:pb-5 pb-3 md:mt-6 lg:mt-10 blade-top-padding-sm"
        spaceBetween={50}
        modules={[Pagination, Scrollbar]}
        initialSlide={1}
        slidesPerView={1.1}
        slideToClickedSlide
        onSlideChange={(ele) => {
          setActiveSlide(ele.activeIndex)
        }}
        slideActiveClass="slideActiveClass"
        centeredSlides
        speed={1500}
        grabCursor
        scrollbar={{
          el: '.swiper-scrollbar',
          draggable: true,
          horizontalClass: 'bg-[#181818]',
        }}
        breakpoints={{
          480: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 1.4,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1.2,
            spaceBetween: 40,
          },
          1536: {
            slidesPerView: 1.2,
            spaceBetween: 40,
          },
        }}
      >
        {slides.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <div className="w-10/12 2xl:w-full max-w-6xl mx-auto ">
                <div className="flex md:flex-row flex-col-reverse  md:items-center md:gap-10 gap-3">
                  <div className="max-w-lg md:pb-14">
                    <h6 className="text-white md:mt-4 leading-relaxed font-semibold">
                      {item.title}
                    </h6>
                    <h6 className="text-white text-opacity-60 mt-1 font-light leading-relaxed">
                      {item.description}
                    </h6>
                  </div>
                  <div className="border-l-1 border-white border-opacity-20 md:block hidden" />
                  <div className="w-full 2xl:h-96 md:h-72 h-52 rounded-2xl">
                    <img
                      src={item.cover}
                      alt={item.description}
                      className="w-full h-full object-cover object-left rounded-2xl"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="w-container mx-auto">
        <div className="swiper-scrollbar max-w-xs h-1" />
      </div>{' '}
    </section>
  )
}
