import React, { useEffect } from 'react'
import Banner from './01_banner'
import OurWorkCards from './02_OurWorkCards'

export default function PageWork() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="">
      <Banner />
      <OurWorkCards />
    </section>
  )
}
