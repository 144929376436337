import React, { useRef } from 'react'
import StoriesOfTransformation from 'screens/home/storiesofTransformation'
import Banner from '../shared/banner'
import Carousel from '../shared/carosuel'
import PossibilityCarousel from '../shared/possibilityCarousel'
import CardGrid from '../shared/cardGrid'
import TechStack from '../shared/techStack'
import SubFooter from '../shared/subFooter'
import '../shared/shared.css'

export default function ProductAgencies() {
  const formRef = useRef<any>()

  const handleClick = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' })
    const node = formRef.current.querySelector('input')
    node.focus()
  }

  return (
    <section>
      <Banner
        subtitle=""
        header=""
        isHeader={false}
        isSubtitle={false}
        handleClick={handleClick}
        formRef={formRef}
        formType="Product Agenices"
        title='Looking for
            <strong className="font-bold">Augmented Development Teams </strong>
            to navigate your product <br className="md:block hidden" /> to
            success?'
      />
      <Carousel />
      <CardGrid />
      <PossibilityCarousel />
      <TechStack />
      <StoriesOfTransformation />
      <SubFooter handleClick={handleClick} />
    </section>
  )
}
