import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'
import React from 'react'

export default function GetInTouch() {
  const navigate = useNavigate()
  const goToContact = () => navigate('/contact-us')

  return (
    <div className="blade-bottom-padding-xl w-container-sm">
      <section className="flex flex-wrap md:flex-row flex-col-reverse  border border-gray border-opacity-30 px-0 rounded-lg overflow-hidden ">
        <div className="bg-darkSlate flex md:w-6/12 md:gap-5 gap-2 flex-col 2xl:p-20 xlg:p-20 lg:p-14 md:p-8 p-4">
          <h6 className="text-blue font-semibold">
            Infinite Vision, Engineered Reality:
          </h6>
          <h3 className="font-semibold">Your Odyssey Begins Here!</h3>
          <h6>
            Our services, a harmonious blend of product scaling, custom software
            brilliance, augmented teams, lean innovation, and engineering
            excellence, are designed to catalyze and usher you towards
            transformation.
          </h6>
          <h6>
            Prepare for a paradigm that ripples across your industry and beyond.
          </h6>
          <div className="xlg:mt-10 md:mt-6">
            <HeroBtn
              type="button"
              text="Get In Touch"
              size="small"
              color="transparent"
              onClick={goToContact}
            />
          </div>
        </div>
        <div className="flex-1 md:w-6/12 bg-[url(assets/services/metaverse.jpg)] bg-no-repeat bg-cover bg-center min-h-[250px] opacity-80" />
      </section>
    </div>
  )
}
