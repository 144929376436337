import React from 'react'
import HeadingTile from 'molecules/headingTile'
import approach1 from 'assets/workCaseStudies/onPlan/features/Approach-01.png'
import approach2 from 'assets/workCaseStudies/onPlan/features/Approach-02.png'
import approach3 from 'assets/workCaseStudies/onPlan/features/Approach-03.png'
import approach4 from 'assets/workCaseStudies/onPlan/features/Approach-04.png'

export default function Approach() {
  return (
    <div className="w-container">
      <HeadingTile
        title="Approach"
        subtitle="Thinking Beyond Mere Conventions"
        paragraphs={[
          'For decades, financial modeling relied on Excel and spreadsheet-based calculations. In our stint, we developed an application that seamlessly interfaces with Google Spreadsheets, harnessing its potent calculation capabilities to serve as a robust computational engine.',
        ]}
        center
      />
      <div className="flex flex-col gap-10 blade-top-padding-sm">
        {dat.map((item) => {
          return (
            <div key={item.id} className="asfas">
              <Cards
                cover={item.cover}
                title={item.title}
                paragraph={item.paragraph}
                id={item.id}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

function Cards({
  title,
  paragraph,
  id,
  cover,
}: {
  title: string
  paragraph: string
  id: number
  cover: string
}) {
  return (
    <div className="cards flex md:flex-row flex-col-reverse border relative border-white border-opacity-10 w-container max-w-screen-lg 2xl:p-16 lg:p-14 p-4 rounded-2xl lg:gap-10 gap-5 bg-gradient-to-br from-[#222121] via-black to-black">
      <div className="flex-1 flex flex-col justify-between relative ">
        <div>
          <h5 className="text-white">{title}</h5>
          <h6 className="text-white opacity-60 lg:mt-4 mt-2 pr-3">
            {paragraph}
          </h6>
        </div>
        <h1 className="md:relative absolute md:z-50 right-0 md:top-0 top-full md:-ml-8 -mr-2 md:-mb-10 md:-mt-0 -mt-8 leading-none 2xl:text-7xl xlg:text-4xl text-4xl  w-fit font-bold text-clip text-[#ffffff27]">
          {id}
          <div className="h-full w-full bg-gradient-to-tl from-black to-transparent absolute z-10 top-0" />
        </h1>
      </div>
      <div className="flex-1 relative -ml-1">
        <img
          className="w-full h-full object-cover object-left"
          src={cover}
          alt={title}
        />
      </div>
    </div>
  )
}

const dat = [
  {
    id: 1,
    cover: approach1,
    title: 'Brute-force Solution',
    paragraph:
      'Validating the problem statement with a brute-force solution. Analyzing, improvising, and finalizing the proof-of-concept.',
  },
  {
    id: 2,
    cover: approach2,
    title: 'Addressing Scalable Data Storage',
    paragraph: `A conventional OLAP system wasn't the silver bullet. We embarked on a journey to create a robust solution within the Google ecosystem which further led us to design a custom data product leveraging Google BigQuery. An ingenious solution, infused with scalability and fault tolerance, enabled pivotal OLAP operations.`,
  },
  {
    id: 3,
    cover: approach3,
    title: 'Conquering Data Ingestion Complexities',
    paragraph:
      'Extensive integration with a multitude of data sources, including prominent platforms like QuickBooks, NetSuite, Salesforce, BambooHR, Xero, and various others. Leveraging ETL tools and crafting bespoke pipelines for unique systems ensuring seamless data flow.',
  },
  {
    id: 4,
    cover: approach4,
    title: 'Elevating Onboarding Experience',
    paragraph: 'Radically compressed the onboarding cycle from weeks to days.',
  },
]
