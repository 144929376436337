import HeadingTile from 'molecules/headingTile'
import React from 'react'

export default function ImpactMetrics() {
  const data = [
    {
      id: 1,
      attributes: 'Problem Statement Discovery',
      beforeOnPlan: '1 week',
      afterOnPlan: '1 week',
    },
    {
      id: 2,
      attributes: 'Setting up Data Sources',
      beforeOnPlan: '1 week',
      afterOnPlan: '3 days',
    },
    {
      id: 3,
      attributes: 'Curating Essential Reports (BS, CF, INS)',
      beforeOnPlan: '3 weeks',
      afterOnPlan: '2 days',
    },
    {
      id: 4,
      attributes: 'UAT (First Financial Model)',
      beforeOnPlan: '2 weeks',
      afterOnPlan: '3 days',
    },
    {
      id: 5,
      attributes: 'Readjusting Model Based on Client Feedback',
      beforeOnPlan: '1 week',
      afterOnPlan: '1 day',
    },
    {
      id: 6,
      attributes: 'Go Live',
      beforeOnPlan: '1 weeks',
      afterOnPlan: '4 hours',
    },
  ]
  return (
    <div>
      <HeadingTile title="Impact Metrics" center />

      <div className="lg:pl-16 w-container">
        <table className="impact-table text-white blade-top-margin-sm max-w-5xl mx-auto">
          <thead>
            <tr className="text-left">
              <th className="">
                <h5>Attributes</h5>
              </th>
              <th className=" text-center lg:text-left">
                <h5>Before OnPlan</h5>
              </th>
              <th className=" text-center lg:text-left">
                <h5>After OnPlan</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr key={item.id} className="text-gray">
                  <td>
                    <h6>{item.attributes}</h6>
                  </td>
                  <td className="text-center lg:text-left">
                    <h6>{item.beforeOnPlan}</h6>
                  </td>
                  <td className="text-white text-center lg:text-left">
                    <h6>{item.afterOnPlan}</h6>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
