import React from 'react'
import ourWorkStaticData from 'static/work'
import Workcard from 'molecules/workCard'

export default function OurWorkCards() {
  return (
    <section className="w-container 2xl:max-w-none px-3 xl:px-0 md:px-1 mx-auto grid 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 md:gap-4 xl:gap-5 blade-bottom-padding-xl">
      {ourWorkStaticData.map((elem) => {
        return <Workcard data={elem} key={elem.id} />
      })}
    </section>
  )
}
