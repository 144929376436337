import React, { useEffect, useRef, useState } from 'react'
import SwiperCore, { Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import slideA from 'assets/workCaseStudies/onPlan/slides/slideA.png'
import slideB from 'assets/workCaseStudies/onPlan/slides/slideB.png'
import slideC from 'assets/workCaseStudies/onPlan/slides/slideC.png'

import HeadingTile from 'molecules/headingTile'

SwiperCore.use([Pagination, A11y])

const slides = [
  {
    id: 1,
    cover: slideA,
    title: 'Expanding Market Reach',
    description:
      'Empowering clients to penetrate new market segments, capitalizing on opportunities that were once out of reach.',
  },
  {
    id: 2,
    cover: slideB,
    title: 'Elevated Margins',
    description:
      'With newfound capabilities, the sales team closed deals at higher margins, contributing to enhanced profitability.',
  },
  {
    id: 3,
    cover: slideC,
    title: 'A Platform for Tomorrow',
    description: `Our solution's scalability paved the way for future innovations, setting the stage for AI integration and further advancements.`,
  },
]
export default function Benefits() {
  const [activeTab, setActiveTab] = useState(0)
  const [instance, setInstance] = useState<any>(null)
  const swiperRef = useRef<any>(null)

  const getSwiperInstance = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      return swiperRef.current.swiper
    }
    return null
  }

  useEffect(() => {
    const ins = getSwiperInstance()
    setInstance(ins)
  }, [])

  return (
    <div className="w-container ">
      <HeadingTile title="Benefits" />
      {/* Desktop View */}
      <div className="md:flex hidden md:flex-row flex-col-reverse xl:gap-20 lg:gap-12 md:gap-4 gap-1">
        <div className="lg:w-6/12 md:w-5/12 flex flex-col items-start max-w-xl">
          {slides.map((item, index) => {
            return (
              <button
                type="button"
                onClick={() => {
                  setActiveTab(index)
                  instance.slideTo(item.id)
                }}
                key={item.id}
                className={`${
                  index <= activeTab ? ' opacity-100 h-auto' : ' opacity-50 '
                } text-left transition-all duration-500 grid pt-6`}
              >
                <h4
                  className={`${
                    index < activeTab ? 'opacity-40' : ''
                  } text-white font-medium`}
                >
                  {item.title}
                </h4>
                <h5
                  className={`${
                    index !== activeTab ? 'h-0' : '2xl:h-32 xl:h-24 h-20'
                  } text-white opacity-80 overflow-hidden font-light transtion-all duration-500 delay-75 mt-3`}
                >
                  {item.description}
                </h5>
                <div
                  className={`${
                    index === activeTab ? 'opacity-100' : 'opacity-70'
                  } bg-white w-full h-[1px] bg-opacity-30 mt-3 relative`}
                >
                  <div
                    className={`${
                      index === activeTab
                        ? 'w-[100%] transition-all duration-[4000ms]'
                        : 'w-0 bg-transparent'
                    } swiper-pagination swiper-progressbars bg-white h-[1px]  ease-linear delay-500`}
                  />
                </div>
              </button>
            )
          })}
        </div>
        <div className="lg:w-6/12 w-7/12 vertical-pagination ml-auto">
          <Swiper
            ref={swiperRef}
            className="2xl:h-[550px] md:h-[400px] h-72 w-fit mx-auto"
            spaceBetween={50}
            modules={[Pagination, A11y, Autoplay]}
            slidesPerView={1}
            centeredSlides
            slideToClickedSlide
            speed={1000}
            direction="vertical"
            onSlideChange={(e) => {
              // console.log(e.realIndex)
              setActiveTab(e.realIndex)
            }}
            grabCursor
            pagination
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
              waitForTransition: true,
            }}
            loop
            watchSlidesProgress
            breakpoints={{
              480: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              960: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              1536: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
            }}
          >
            {slides.map((item, index) => {
              return (
                <SwiperSlide
                  key={item.id}
                  className="h-full w-fit ml-auto px-8 rounded-2xl overflow-hidden"
                >
                  <img
                    className="h-full object-cover rounded-2xl"
                    src={item.cover}
                    alt={item.title}
                  />
                </SwiperSlide>
              )
            })}
            <div className="swiper-pagination" />
          </Swiper>
        </div>
      </div>

      {/* Mobile View */}
      <div className=" max-w-lg md:hidden block">
        {slides.map((item, index) => {
          return (
            <div
              key={item.id}
              className="text-left transition-all duration-500 grid mb-8"
            >
              <h4 className="text-white opacity-90 font-semibold">
                {item.title}
              </h4>
              <div className="text-white overflow-hidden mt-3">
                <h5 className="font-light pb-5"> {item.description}</h5>
                <div className="h-[250px]">
                  <img
                    className="h-full rounded-md object-cover mx-auto object-center"
                    src={item.cover}
                    alt={item.title}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
