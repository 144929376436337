import React, { Fragment, useState } from 'react'

import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { HeroBtn } from 'atoms/buttons'
import emailjs from '@emailjs/browser'

const formSchema = z.object({
  name: z
    .string()
    .min(1, 'Name is required.')
    .max(30, 'Name can not be more than 30 characters.'),
  email: z
    .string()
    .email('Invalid email address.')
    .min(1, 'Email is required.'),
  phone: z.string(),
  country: z.string(),
  message: z.string().max(200, 'Message should be less than 200 characters.'),
})

export type FormFieldSchema = z.infer<typeof formSchema>

export default function FormWrapper({ formType }: { formType: string }) {
  const [isLoading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFieldSchema>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit: SubmitHandler<FormFieldSchema> = async (data) => {
    setLoading(true)

    const { name, email, phone, country, message } = data

    const payload = {
      name,
      email,
      phone,
      optionalA: 'Country',
      optionalAvalue: country,
      optionalB: 'Message',
      optionalBvalue: message,
      formType,
    }
    emailjs
      .send(
        'innogent-service-id',
        'template_2malzgm',
        payload,
        'mG9ssGJLNqKO0OSWG',
      )
      .then(
        () => {
          setLoading(false)
          toast('Form submitted successfully!')
        },
        () => {
          setLoading(false)
          toast('Something went wrong!')
        },
      )

    reset()
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        theme="dark"
        progressClassName="bg-subfooter"
        className="text-sm !px-4"
      />
      <section
        className="  px-3 md:px-4  lg:px-6 2xl:px-8  py-6 2xl:py-10  ml-auto 2xl:w-11/12 rounded-sm md:rounded-2xl 2xl:rounded-[20px]"
        style={{
          backgroundImage:
            'linear-gradient(180deg, #DBDBDB 12.35%, #AAB1BA 100%)',
        }}
      >
        <h5 className="font-bold pb-1">Let&apos;s connect</h5>
        <span className="text-[12px] md:text-base lg:text-lg font-normal leading-tight block">
          Our team will touch base with <br className="md:hidden block" />
          you within 24 hours.
        </span>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="pt-5 lg:pt-8 flex flex-col gap-4"
        >
          <div className="grid  grid-cols-1 gap-y-4 md:grid-cols-2 gap-x-7">
            <div>
              <label
                htmlFor="name"
                className="grid gap-1 text-base md:text-lg font-semibold"
              >
                <input
                  size={1}
                  id="name"
                  placeholder="Full Name*"
                  className="border-b-1  md:pl-2  border-black border-solid py-2 border-opacity-40 focus:border-opacity-100  font-medium pr-3  text-sm md:text-base bg-transparent focus:border-blue invalid:border-red focus:outline-none placeholder:text-black placeholder:opacity-60  w-full"
                  {...register('name')}
                />
                {errors.name && (
                  <span className="text-red  text-[12px] md:text-sm font-medium block">
                    {errors.name?.message}
                  </span>
                )}
              </label>
            </div>

            <div>
              <label
                htmlFor="email"
                className="grid gap-1 text-base md:text-lg font-semibold"
              >
                <input
                  size={1}
                  className="  border-b-1 border-black border-solid md:pl-2  py-2 border-opacity-40 focus:border-opacity-100  font-medium pr-3  text-sm md:text-base bg-transparent focus:border-blue invalid:border-red focus:outline-none placeholder:text-black placeholder:opacity-60  w-full"
                  id="email"
                  type="email"
                  inputMode="email"
                  placeholder="Email ID*"
                  {...register('email')}
                />
                {errors.email && (
                  <span className="text-red  text-[12px] md:text-sm font-medium block">
                    {errors.email?.message}
                  </span>
                )}
              </label>
            </div>
          </div>

          <div className="grid  grid-cols-1 gap-y-4 md:grid-cols-2 gap-x-7">
            <div>
              <label
                htmlFor="country"
                className="grid gap-1 text-base md:text-lg font-semibold"
              >
                <input
                  size={1}
                  id="country"
                  placeholder="Country"
                  {...register('country')}
                  className="border-b-1 border-black border-solid md:pl-2  py-2 border-opacity-40 focus:border-opacity-100  font-medium pr-3  text-sm md:text-base bg-transparent focus:border-blue invalid:border-red focus:outline-none placeholder:text-black placeholder:opacity-60  w-full"
                />
                {errors.country && (
                  <span className="text-red  text-[12px] md:text-sm font-medium block">
                    {errors.country?.message}
                  </span>
                )}
              </label>
            </div>

            <div>
              <label
                htmlFor="contact"
                className="grid gap-1 text-base md:text-lg font-semibold"
              >
                <input
                  size={1}
                  id="contact"
                  className="  border-b-1 border-black border-solid md:pl-2  py-2 border-opacity-40 focus:border-opacity-100  font-medium pr-3  text-sm md:text-base bg-transparent focus:border-blue invalid:border-red focus:outline-none placeholder:text-black placeholder:opacity-60  w-full"
                  type="number"
                  inputMode="numeric"
                  placeholder="Contact Number"
                  {...register('phone')}
                />
                {errors.phone && (
                  <span className="text-red  text-[12px] md:text-sm font-medium block">
                    {errors.phone?.message}
                  </span>
                )}
              </label>
            </div>
          </div>

          <div>
            <label
              htmlFor="message"
              className="grid gap-1 text-base md:text-lg font-semibold"
            >
              <textarea
                className="border-b-1 border-black border-solid md:pl-2  py-2 border-opacity-40 focus:border-opacity-100  font-medium pr-3  text-sm md:text-base bg-transparent focus:border-blue invalid:border-red focus:outline-none placeholder:text-black placeholder:opacity-60  w-full"
                rows={4}
                cols={5}
                id="message"
                placeholder="Tell us your requirements*"
                {...register('message')}
              />
            </label>
          </div>
          <div className="grid  place-content-center md:place-content-start">
            <HeroBtn
              isDisabled={isLoading}
              type="submit"
              color="black"
              text={isLoading ? 'Loading...' : 'Submit'}
              size="small"
            />
          </div>
        </form>
      </section>
    </>
  )
}
