import React, { useEffect, useState } from 'react'
import SubFooter from 'organisms/subFooter'
import logo from 'assets/workCaseStudies/bflow/bflow.png'

import dummyIcon from 'assets/workCaseStudies/icon.svg'
import process from 'assets/workCaseStudies/bflows/benefits/process.png'
import flow from 'assets/workCaseStudies/bflows/benefits/cash-flow.png'
import reduction from 'assets/workCaseStudies/bflows/benefits/cost-reduction.png'
import reporting from 'assets/workCaseStudies/bflows/benefits/enhanced-reporting.png'
import savings from 'assets/workCaseStudies/bflows/benefits/time-savings.png'

import { useNavigate } from 'react-router-dom'
import ImpactMetrics from './impactMetrics'
import Conclusion from '../similarSection/conclusion'
import Approach from './approach'
import Banner from '../similarSection/banner'
import Overview from './overview'
import Proficiency from './proficiency'
import IndustrySpace from './industry_space'
import Benefits from '../similarSection/benefits'

import './bflows.css'
import ProblemStatement from './problemStatement'
import ProductFunctions from './productFunctions'

export default function PageBFlows() {
  const [isActive, setIsActive] = useState<any>(1)
  const navigate = useNavigate()

  const tabManagementData = [
    {
      id: 1,
      text: 'Overview',
      sectionId: 'overview',
    },
    {
      id: 2,
      text: 'Problem Statements',
      sectionId: 'problem-statements',
    },
    {
      id: 3,
      text: 'Product Functions',
      sectionId: 'product-functions',
    },
    {
      id: 4,
      text: 'Benefits',
      sectionId: 'benefits',
    },
    {
      id: 5,
      text: 'Approach',
      sectionId: 'approach',
    },
    {
      id: 6,
      text: 'Proficiency',
      sectionId: 'proficiency',
    },
    {
      id: 7,
      text: 'Impact Metrics',
      sectionId: 'impact-metrics',
    },
    {
      id: 8,
      text: 'Conclusion',
      sectionId: 'conclusion',
    },
  ]

  const handleSectionNavigation = (sectionId: string) => {
    const ele = document.querySelector(`#${sectionId}`)
    ele?.scrollIntoView({ behavior: 'smooth' })
  }

  const goToContact = () => navigate('/contact-us')

  const handleScroll = () => {
    const sec = document.querySelectorAll('.section-wrapper')

    sec.forEach((item, ind) => {
      const rect = item?.getBoundingClientRect()
      if (rect) {
        const isInView =
          rect.top >= -1 &&
          rect.left >= 0 &&
          rect.top <= window.innerHeight / 5 &&
          rect.right >= window.innerHeight / 5

        if (isInView) {
          ind += 1
          setIsActive(ind)
        }
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isActive])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="bflow-page-wrapper">
      <Banner
        title={`Invoice Management <br class="sm:hidden block" /> with`}
        subTitle="Optimizing Business Cash Flows <br /> with Financial Fluidity"
        logo={logo}
        logoShadow
        logoShadowColor="bg-[#006284]"
      />
      <IndustrySpace />

      <div className="blade-top-margin relative lg:blade-bottom-padding lg:blade-bottom-margin-sm">
        <div className="lg:flex hidden w-fit z-[999] d sticky top-[90vh] justify-center 2xl:gap-10 gap-6 mx-auto 2xl:py-3 py-2 px-6 lg:px-16 border-[1px] border-zinc-800 rounded-3xl bg-gradient-to-tl from-zinc-800 to-black">
          {tabManagementData.map((item, i) => {
            return (
              <button
                className={`text-white 2xl:text-base text-sm font-light whitespace-nowrap ${
                  isActive === item.id ? 'opacity-100' : 'opacity-50'
                }`}
                type="button"
                onClick={() => handleSectionNavigation(item.sectionId)}
                key={item.id}
              >
                {item.text}
              </button>
            )
          })}
        </div>

        <div className="section-wrapper blade-top-padding-xs" id="overview">
          <Overview />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="problem-statements"
        >
          <ProblemStatement />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="product-functions"
        >
          <ProductFunctions />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="benefits">
          <Benefits data={bflowsBenefitsData} />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="approach">
          <Approach />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="proficiency">
          <Proficiency />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="impact-metrics"
        >
          <ImpactMetrics />
        </div>
        <div className="section-wrapper blade-top-padding-sm" id="conclusion">
          <Conclusion
            title="Conclusion"
            paragraphs={[
              'Bflows is empowering businesses to transcend the limitations of traditional invoice management. By harnessing the power of Balanced Flows Technology, Bflows encompasses a unique blend of automation, collaboration, and optimization that paves the way for streamlined processes, efficient cash flow, and informed decision-making. As businesses continue to navigate the complexities of modern finance, Bflows stands as an ally, propelling them toward growth in an interconnected landscape.',
            ]}
          />
        </div>
      </div>

      <SubFooter
        title='Ready to unveil the potential <br  class="md:block hidden"/> of your product?'
        cta={[
          {
            type: 'primary',
            text: `Let's connect!`,
            onClick: goToContact,
            color: 'black',
          },
        ]}
      />
    </div>
  )
}

const bflowsBenefitsData = [
  {
    title: 'Process Efficiency',
    desc: 'Streamlining invoice management, reducing manual efforts and errors through automation.',
    coverImage: process,
    icon: dummyIcon,
  },
  {
    title: 'Cash Flow Optimization',
    desc: 'Reciprocal transactions accounting for optimized cash flow and robust financial stability.',
    coverImage: flow,
    icon: dummyIcon,
  },
  {
    title: 'Enhanced Reporting',
    desc: 'Dashboard for actionable insights into invoice statuses, aiding decision-making.',
    coverImage: reduction,
    icon: dummyIcon,
  },
  {
    title: 'Time Savings',
    desc: 'Automated processes accelerate payment and collection cycles, saving valuable time.',
    coverImage: reporting,

    icon: dummyIcon,
  },
  {
    title: 'Cost Reduction',
    desc: 'Dynamic discounts and efficient credit note management contribute to cost savings.',
    coverImage: savings,

    icon: dummyIcon,
  },
]
