import one from 'assets/about/timeline/01.jpg'
import two from 'assets/about/timeline/02.jpg'
import three from 'assets/about/timeline/03.jpg'
import four from 'assets/about/timeline/04.jpg'
import five from 'assets/about/timeline/05.jpg'
import six from 'assets/about/timeline/06.jpg'
import seven from 'assets/about/timeline/07.jpg'
import eight from 'assets/about/timeline/08.jpg'

export const timeline = [
  {
    title: 'title',
    img: one,
  },
  {
    title: 'title',
    img: two,
  },
  {
    title: 'title',
    img: three,
  },
  {
    title: 'title',
    img: four,
  },
  {
    title: 'title',
    img: five,
  },
  {
    title: 'title',
    img: six,
  },
  {
    title: 'title',
    img: seven,
  },
  {
    title: 'title',
    img: eight,
  },
]
