import React from 'react'
import trained from 'assets/landingPage/salientPoints/trained.svg'
import adept from 'assets/landingPage/salientPoints/adept.svg'
import learned from 'assets/landingPage/salientPoints/learned.svg'
import buffer from 'assets/landingPage/salientPoints/buffer.svg'

const cards = [
  {
    title: 'Trained, ready-to-deploy developers',
    cover: trained,
  },
  {
    title: 'Adept expertise in AI, Big Data, Cloud Computing, IoT, & AR/VR',
    cover: adept,
  },
  {
    title: 'Lean & agile development experts',
    cover: learned,
  },
  {
    title: '20% buffer resource backups',
    cover: buffer,
  },
]

export default function CardsGrid() {
  return (
    <section className="blade-top-padding blade-bottom-padding-sm">
      <div className="blade-bottom-padding ">
        <span className=" block text-40 font-semibold text-gradient-dark  leading-tight text-gradient-dark text-center px-5 ">
          What sets us apart?
        </span>
      </div>
      <div className="w-11/12 md:w-full mx-auto">
        <div className="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 lg:gap-x-4 md:gap-x-6 gap-y-4  2xl:gap-x-10 w-container blade-bottom-padding-sm">
          {cards.map((elem, index) => {
            const key = `${index}`
            return <Card title={elem.title} cover={elem.cover} key={key} />
          })}
        </div>
      </div>
    </section>
  )
}

function Card({ title, cover }: { title: string; cover: string }) {
  return (
    <article className="border-borderGray h-64 md:h-[16rem] lg:h-[20rem] xl:h-[18rem] 2xl:h-[20rem] border-solid border-2 rounded-xl overflow-hidden relative    landing-feature-card">
      <div className="absolute inset-0 z-10 flex flex-col items-center">
        <div className="z-10  basis-40 2xl:basis-48 grow-0 shrink-0 grid place-items-center px-9">
          <img
            src={cover}
            alt={title}
            className="h-5/6  w-auto object-contain object-center"
          />
        </div>
        <div className="text-center max-w-[300px] flex-0 py-4 grid place-content-center px-3">
          <span className="text-white  text-opacity-70 font-light text-base lg:text-lg 2xl:text-xl  leading-normal">
            {title}
          </span>
        </div>
      </div>
    </article>
  )
}
