import case_study_onplan from 'assets/home/caseStudies/case_study_onplan.png'
import case_study_bflows from 'assets/home/caseStudies/case_study_bflows.png'
import case_study_cloudeagle from 'assets/home/caseStudies/case_study_cloudeagle.png'
import case_study_evolphin from 'assets/home/caseStudies/case_study_evolphin.png'
import case_study_etl from 'assets/home/caseStudies/case_study_etl.jpg'

const ourWorkStaticData = [
  {
    title: 'Financial Planning & Analysis',
    subtitle:
      'Unlocking financial intelligence with powerful planning, budgeting, forecasting, layering visualization, integration, and collaboration capabilities.',
    tag: 'OnPlan',
    imgUrl: case_study_onplan,
    categories: ['#ux user experience', '#development'],
    link: '/work/on-plan',
    id: 1,
  },
  {
    title: 'Banking Confidence',
    subtitle:
      'Building banking confidence with reducing risks, payment workflows, payment gateway integration, financial insights and reporting, and optimized payment cycle.',
    tag: 'BFlows',
    imgUrl: case_study_bflows,
    categories: ['#ux user experience', '#development'],
    link: '/work/b-flows',
    id: 2,
  },
  {
    title: 'Spend Management',
    subtitle:
      'IT spending made simple with cost optimization, assisted buying, contract management, and procurement workflows.',
    tag: 'Cloudeagle',
    imgUrl: case_study_cloudeagle,
    categories: ['#ux user experience', '#development'],
    link: '/work/cloudeagle',
    id: 3,
  },
  {
    title: 'AI-based Image Processing',
    subtitle:
      'Unleashing visual brilliance with image-based specification, AI-enabled image-processing solutions, digital asset management, post-production, and publishing.',
    tag: 'Evolphin',
    imgUrl: case_study_evolphin,
    categories: ['#ux user experience', '#development'],
    link: '/work/evolphin',
    id: 4,
  },
  {
    title: 'Data integration & ETL',
    subtitle:
      'Streamlining data integration from a diverse set of sources to effectively Extract, Transform, and Load (ETL) data and enable better contract visualization, user data provisioning, and finance data management.',
    // tag: 'Evolphin',
    imgUrl: case_study_etl,
    categories: ['#ux user experience', '#development'],
    link: '/work/etl',
    id: 4,
  },
]
export default ourWorkStaticData
