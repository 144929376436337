import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SubFooter from 'organisms/subFooter'
import logo from 'assets/workCaseStudies/cloudeagle/cloudeagle.svg'
import dummyIcon from 'assets/workCaseStudies/icon.svg'

import './cloudeagle.css'
import Banner from '../similarSection/banner'
import IntroVideo from './introVideo'
import Overview from './overview'
import ProductFunctions from './productFunctions'
import Benefits from './benefits'
import ImpactMetrics from '../similarSection/impactMetrics'
import Conclusion from '../similarSection/conclusion'
import Proficiency from './proficiency'
import ProblemStatement from './problemStatement'
import ApproachCardsView from '../similarSection/approachCardsView'

export default function PageCloudEagle() {
  const [isActive, setIsActive] = useState<any>(1)
  const navigate = useNavigate()

  const tabManagementData = [
    {
      id: 1,
      text: 'Overview',
      sectionId: 'overview',
    },
    {
      id: 2,
      text: 'Problem Statements',
      sectionId: 'problem-statements',
    },
    {
      id: 3,
      text: 'Product Functions',
      sectionId: 'product-functions',
    },
    {
      id: 4,
      text: 'Benefits',
      sectionId: 'benefits',
    },
    {
      id: 5,
      text: 'Approach',
      sectionId: 'approach',
    },
    {
      id: 6,
      text: 'Proficiency',
      sectionId: 'proficiency',
    },
    {
      id: 7,
      text: 'Impact Metrics',
      sectionId: 'impact-metrics',
    },
    {
      id: 8,
      text: 'Conclusion',
      sectionId: 'conclusion',
    },
  ]

  const handleSectionNavigation = (sectionId: string) => {
    const ele = document.querySelector(`#${sectionId}`)
    ele?.scrollIntoView({ behavior: 'smooth' })
  }

  const goToContact = () => navigate('/contact-us')

  const handleScroll = () => {
    const sec = document.querySelectorAll('.section-wrapper')

    sec.forEach((item, ind) => {
      const rect = item?.getBoundingClientRect()
      if (rect) {
        const isInView =
          rect.top >= -1 &&
          rect.left >= 0 &&
          rect.top <= window.innerHeight / 5 &&
          rect.right >= window.innerHeight / 5

        if (isInView) {
          ind += 1
          setIsActive(ind)
        }
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isActive])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="evolphin-wrapper">
      <Banner
        title="Optimizing IT Spending with CloudEagle"
        subTitle={`Identifying and Tracking Renewals, <br class="md:block hidden" /> Usage, & Payments of apps`}
        logo={logo}
        logoShadow={false}
      />
      <IntroVideo />

      <div className="blade-top-padding blade-bottom-padding-sm relative">
        <div className="lg:flex hidden w-fit z-[999] sticky top-[90vh] justify-center 2xl:gap-10 gap-6 mx-auto 2xl:py-3 py-2 px-6 lg:px-16 border-[1px] border-zinc-800 rounded-3xl bg-gradient-to-tl from-zinc-800 to-black">
          {tabManagementData.map((item, i) => {
            return (
              <button
                className={`text-white 2xl:text-base text-sm font-light whitespace-nowrap ${
                  isActive === item.id ? 'opacity-100' : 'opacity-50'
                }`}
                type="button"
                onClick={() => handleSectionNavigation(item.sectionId)}
                key={item.id}
              >
                {item.text}
              </button>
            )
          })}
        </div>

        <div className="section-wrapper blade-top-padding-sm" id="overview">
          <Overview />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="problem-statements"
        >
          <ProblemStatement />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="product-functions"
        >
          <ProductFunctions />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="benefits">
          <Benefits />
        </div>

        <div className="section-wrapper blade-top-padding-xl" id="approach">
          {/* <TabPanel data={ApproachTabPanel} /> */}
          <ApproachCardsView data={ApproachTabPanel} />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="proficiency">
          <Proficiency />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="impact-metrics"
        >
          <ImpactMetrics data={impactMetrics} />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="conclusion">
          <Conclusion
            title="Conclusion"
            paragraphs={[
              `CloudEagle stands as a testament to Innogent’s expertise and approach to strategic product development. Its impact on businesses' cloud expenditure management transcends mere savings, it instills confidence in the teams to make informed, resourceful decisions. `,
            ]}
          />
        </div>
      </div>
      <SubFooter
        title={`Ready to optimize your <br class="md:block hidden" /> IT spending today? `}
        cta={[
          {
            type: 'primary',
            text: `Let's connect!`,
            onClick: goToContact,
            color: 'black',
          },
        ]}
      />
    </div>
  )
}

const beneFitsData = [
  {
    title: 'Savings in time and resources',
    desc: 'By automating complex tasks and offering seamless tools for image enhancement, Crop.Photo optimizes workflows, allowing teams to allocate resources more strategically and focus on core business objectives.',
    coverImage:
      'https://images.unsplash.com/photo-1704634134420-55d2e632c3a3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    icon: dummyIcon,
  },
  {
    title: 'Enhanced images boosting product presentation',
    desc: `By enhancing image quality through AI-driven processes, it doesn't just meet basic presentation criteria but elevates the visual narrative, fosters consumer trust, enriches the overall shopping experience, and bolsters the brand’s credibility in a visually saturated e-commerce environment.`,
    coverImage:
      'https://images.unsplash.com/photo-1704634134420-55d2e632c3a3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

    icon: dummyIcon,
  },
  {
    title: 'Marketplace standards being met with ease',
    desc: 'Crop.Photo ensures effortless compliance with ever-evolving marketplace regulations and standards. Its Listing Analyzer Module, adept at evaluating images against diverse marketplace rules, ensures that product visuals align seamlessly with platform requirements.',
    coverImage:
      'https://images.unsplash.com/photo-1682687220795-796d3f6f7000?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

    icon: dummyIcon,
  },
  {
    title:
      'Improvements in image quality driving better and faster conversions',
    desc: 'High-quality visuals resonate better with consumers, sparking increased interest and confidence in products in turn translating into accelerated purchase decisions and amplified conversion rates for e-commerce businesses.',
    coverImage:
      'https://images.unsplash.com/photo-1704634134420-55d2e632c3a3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

    icon: dummyIcon,
  },
]

const impactMetrics = [
  {
    id: '1',
    value: '30%',
    desc: 'savings in expenditure cost',
  },
  { id: '2', value: '500', desc: 'hours saved in a year' },
  { id: '2', value: '100%', desc: 'visibility of vendors' },
  { id: '3', value: '30', desc: 'mins industry leading onboarding time' },
]

const ApproachTabPanel = [
  {
    id: 1,
    title: 'Data Integration Framework',
    coverImage:
      'https://images.unsplash.com/photo-1704634134420-55d2e632c3a3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    icon: dummyIcon,
    list: [
      'Developed an integration framework to fetch usage data from multiple vendors.',
      'Utilized API calls to collect user data and activities, consolidating it within the system.',
    ],
  },
  {
    id: 2,
    title: 'Key Data Metrics',
    coverImage:
      'https://images.unsplash.com/photo-1704634134420-55d2e632c3a3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

    icon: dummyIcon,
    list: [
      'Extracted essential data, including the number of users per client, and usage.',
      'Login frequency to respective vendor applications.',
      'Identification of inactive users.',
      'Cost optimization suggestions.',
    ],
  },
  {
    id: 3,
    title: 'Calculated vendor spending based on user activities',
    coverImage:
      'https://images.unsplash.com/photo-1682687220795-796d3f6f7000?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

    icon: dummyIcon,
    list: [
      'Provided recommendations to clients, such as removal or de-provisioning of unused users.',
      'Downgrading subscriptions to save money.',
    ],
  },
  {
    id: 4,
    title: 'Automated Recommendations',
    coverImage:
      'https://images.unsplash.com/photo-1704634134420-55d2e632c3a3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

    icon: dummyIcon,
    list: [
      'Implemented a recommendation engine refreshing every 6 hours.',
      'Sent automated email and in-app notifications to clients.',
      'Delivered personalized suggestions for cost-saving opportunities.',
    ],
  },
  {
    id: 5,
    title: 'Financial Data Integration',
    coverImage:
      'https://images.unsplash.com/photo-1704634134420-55d2e632c3a3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

    icon: dummyIcon,
    list: [
      'Pulled contract and invoice data from finance systems.',
      'Used financial information to: Remind clients about contract renewals.',
      'Prompt users to pay outstanding invoices.',
    ],
  },
  {
    id: 6,
    title: 'Workflow Automation',
    coverImage:
      'https://images.unsplash.com/photo-1704634134420-55d2e632c3a3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

    icon: dummyIcon,
    list: [
      'Established workflows for various tasks: User provisioning and de-provisioning, Application provisioning, and de-provisioning.',
      'Procurement processes.',
      'Application access requests.',
      'Standard workflows for user-initiated tasks.',
    ],
  },
]
