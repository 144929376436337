import React from 'react'
import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'

// eslint-disable-next-line
export default function Workcard({ data }: { data: any }) {
  const navigate = useNavigate()
  const { title, subtitle, tag, imgUrl, link, id, categories } = data
  const goToSource = () => navigate(link)
  const time = '3s'
  return (
    <article className="animate-fade-in-slow bg-gradient-to-tr from-[#ffffff10] to-transparent mx-auto max-w-md w-full h-full self-stretch flex flex-col gap-1  border-[2px] rounded-lg p-3 md:p-3 lg:p-4 2xl:p-5 border-solid border-white border-opacity-20">
      <div className="h-56 ">
        <img
          className="h-full w-full object-cover object-center rounded-md text-white"
          src={imgUrl}
          alt={title}
        />
      </div>
      <div className="pt-2 md:pt-4 gap-2 flex-1 flex flex-col justify-between">
        <div className="gap-1 hidden">
          {categories.map((item: string) => {
            return (
              <small
                key={item}
                className="whitespace-nowrap text-gray line-clamp-1"
              >
                {item}
              </small>
            )
          })}
        </div>

        <div className="h-full flex flex-col justify-between">
          {' '}
          <div className="line-clamp-2 ">
            <h6 className="font-semibold leading-snug text-white w-11/12">
              {title}
            </h6>
            <h6 className="text-sm font-light leading-relaxed text-opacity-50 text-white mt-2 w-11/12  ">
              {subtitle}
            </h6>
          </div>
          <div className="grid place-content-start mt-2">
            <HeroBtn
              size="small"
              type="button"
              onClick={goToSource}
              text="Know More"
            />
          </div>
        </div>
      </div>
    </article>
  )
}
