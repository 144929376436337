import React, { useEffect } from 'react'
import { HeroBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'

export default function PageError404() {
  const navigate = useNavigate()
  const goToHome = () => {
    navigate('/')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="md:h-screen h-[80vh] grid place-content-center bg-darkGradient">
      <h1 className="text-gradient-dark px-3 leading-[90px] uppercase md:text-[90px] text-center text-4xl font-bold flex">
        Coming Soon
      </h1>
      <div className="flex justify-center md:mt-6 mt-3">
        <HeroBtn
          role="link"
          size="small"
          text="Homepage"
          onClick={goToHome}
          type="button"
        />
      </div>
    </div>
  )
}
