import HeadingTile from 'molecules/headingTile'
import React from 'react'
import grad from 'assets/workCaseStudies/gradient-logo.png'

export default function Conclusion({
  title,
  paragraphs,
  className,
}: {
  title: string
  paragraphs: string[]
  className?: string
}) {
  return (
    <div className="w-container md:w-container-sm lg:border relative border-gray border-opacity-10 flex lg:flex-row flex-col justify-center items-center 2xl:gap-20 gap-10 blade-bottom-margin-lg rounded-2xl">
      <HeadingTile
        title={title}
        paragraphs={paragraphs}
        className={`md:w-6/12 ml-auto z-20 2xl:pr-20 md:py-20 ${className}`}
      />
      <div className="md:block hidden lg:h-auto ml-10 absolute m-5 inset-0 ">
        <img
          src={grad}
          alt="innogent logo background"
          className="h-full object-contain"
        />
      </div>
    </div>
  )
}
