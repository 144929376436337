import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { gsap } from 'gsap'

interface AccordionItem {
  id: number
  problem: string
  solution: any
}

function Accordion({ items }: { items: any }) {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const toggleAccordion = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <div className="accordion grid bg-black gap-5">
      {items.map((item: AccordionItem, index: number) => {
        const { title, subtitle, paragraphI, paragraphII } = item.solution
        return (
          <div
            key={item.id}
            className="accordion-item bg-bladeGradient rounded-xl px-4"
          >
            <button
              type="button"
              className={`accordion-header flex gap-3 text-lg text-left justify-between w-full rounded-xl py-2 text-black ${
                openIndex === index ? 'open' : ''
              }`}
              onClick={() => toggleAccordion(index)}
            >
              {item.problem}
              <div>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className={`w-8 transition-all duration-500 ${
                    openIndex === index ? '-rotate-90' : ''
                  } `}
                >
                  <path fill="none" d="M0 0h24v24H0V0z" />
                  <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
              </div>
            </button>

            <CSSTransition
              in={openIndex === index}
              timeout={1000}
              unmountOnExit
              onEnter={(node: any) =>
                gsap.fromTo(
                  node,
                  { height: 0 },
                  { height: 'auto', duration: 0.5 },
                )
              }
              onExiting={(node: any) =>
                gsap.to(node, { height: 0, duration: 0.5 })
              }
            >
              <div className="overflow-hidden bg- rounded-b-xl">
                <div className="flex 2xl:gap-6 gap-4 flex-col py-4 justify-center">
                  <h4 className="font-semibold text-black">{title}</h4>
                  <h5 className="font-semibold text-black">{subtitle}</h5>
                  <h6 className="text-black text-opacity-70">{paragraphI}</h6>
                  <h6 className="text-black text-opacity-70">{paragraphII}</h6>
                </div>
              </div>
            </CSSTransition>
          </div>
        )
      })}
    </div>
  )
}

export default Accordion
