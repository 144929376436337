//  Grid Culture
import one from 'assets/culture/grid-culture/1.jpg'
import two from 'assets/culture/grid-culture/2.jpg'
import three from 'assets/culture/grid-culture/3.jpg'
import four from 'assets/culture/grid-culture/4.jpg'
import five from 'assets/culture/grid-culture/5.jpg'
import six from 'assets/culture/grid-culture/6.jpg'
import seven from 'assets/culture/grid-culture/7.jpg'

// boardrooms

import oneBoardRooms from 'assets/culture/beyondBoardrooms/1.jpg'
import twoBoardRooms from 'assets/culture/beyondBoardrooms/2.jpg'
import threeBoardRooms from 'assets/culture/beyondBoardrooms/3.jpg'
import fourBoardRooms from 'assets/culture/beyondBoardrooms/4.jpg'
import fiveBoardRooms from 'assets/culture/beyondBoardrooms/5.jpg'

export const Culture = [
  {
    imgSrc: one,
    id: 1,
    des: 'some description',
  },
  {
    imgSrc: two,
    id: 2,
    des: 'some description',
  },
  {
    imgSrc: three,
    id: 3,
    des: 'some description',
  },
  {
    imgSrc: four,
    id: 4,
    des: 'some description',
  },
  {
    imgSrc: five,
    id: 5,
    des: 'some description',
  },
  {
    imgSrc: six,
    id: 6,
    des: 'some description',
  },
  {
    imgSrc: seven,
    id: 7,
    des: 'some description',
  },
]

export const BeyondBoardroomsData = [
  {
    title: 'Removing barriers to enterprise innovation',
    subtitle:
      "We are making businesses dream big and pushing the boundaries of what's possible.",
    tag: 'Keynote',
    imgUrl: oneBoardRooms,
    link: '/',
    id: 1,
  },
  {
    title: 'Removing barriers to enterprise innovation',
    subtitle:
      "We are making businesses dream big and pushing the boundaries of what's possible.",
    tag: 'Keynote',
    imgUrl: twoBoardRooms,
    link: '/',
    id: 2,
  },
  {
    title: 'Removing barriers to enterprise innovation',
    subtitle:
      "We are making businesses dream big and pushing the boundaries of what's possible.",
    tag: 'Keynote',
    imgUrl: threeBoardRooms,
    link: '/',
    id: 3,
  },
  {
    title: 'Removing barriers to enterprise innovation',
    subtitle:
      "We are making businesses dream big and pushing the boundaries of what's possible.",
    tag: 'Keynote',
    imgUrl: fourBoardRooms,
    link: '/',
    id: 4,
  },
  {
    title: 'Removing barriers to enterprise innovation',
    subtitle:
      "We are making businesses dream big and pushing the boundaries of what's possible.",
    tag: 'Keynote',
    imgUrl: fiveBoardRooms,
    link: '/',
    id: 5,
  },
]
