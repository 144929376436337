import React, { useEffect } from 'react'
import { dataManagementData } from 'static/solutions'
import bgImage from 'assets/solutions/5.jpg'
import Banner from '../01_banner'
import Message from '../03_message'
import CuriousMinds from '../04_curiousMinds'
import ProblemStatementMobileView from '../02_problemStatementMobileView'
import GetInTouch from '../06_getInTouch'
import TempProblemStatement from '../02_temp_problemStatement'

export default function PageDataManagement() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main className="bg-black">
      <Banner
        title="Resolving the Data Dilemma with"
        subtitle="Platforms, Architecture, Integration, and Governance"
      />
      <div className="md:block hidden">
        <TempProblemStatement data={dataManagementData} bgImage={bgImage} />
      </div>
      <div className="md:hidden block">
        <ProblemStatementMobileView data={dataManagementData} />
      </div>
      <Message theMessage="Data is the currency of the future. Management redefines its value, integration connects the dots, governance shields the assets, platforms scale the ambitions, and architecture reshapes the impact." />
      <CuriousMinds title="Check our treasure trove of insights with some rewritten narratives around data management along the crossroads of governance, products, integration, architecture, and modeling. " />
      <GetInTouch
        title={`Together, let's sculpt a future `}
        subtitle="where data is the catalyst that propels your business to unparalleled heights."
      />
    </main>
  )
}
