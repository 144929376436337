import HeadingAtom from 'atoms/headingAtom'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { timeline } from 'static/ourStory'
import React, { useEffect, useRef } from 'react'
import place from 'assets/about/timeline/00.jpg'

import MobileTimeline from './mobileTimeline'

gsap.registerPlugin(ScrollTrigger)

export default function Timeline() {
  const component = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.timeline-pinned-container',
          start: 'top top',
          end: 'bottom bottom',
          pin: true,
          pinSpacing: false,
          scrub: 2,
          markers: false,

          // snap: {
          //   snapTo: 'labelsDirectional', // snap to the closest label in the timeline
          //   duration: { min: 0.2, max: 1 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
          //   delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
          //   ease: 'Linear.easeNone', // the ease of the snap animation ("power3" by default)
          // },
        },
      })

      tl.addLabel('first')
        .fromTo('.timeline-heading', { opacity: 1 }, { opacity: 0, y: -200 })
        .to(
          '.tl1',
          {
            x: '36vw',
            y: '-10vw',

            stagger: 0.3,
          },
          'first',
        )
      tl.addLabel('second')
        .to(
          '.tl1',
          {
            x: '70vw',
            y: '-30vw',

            stagger: 0.3,
          },
          'second',
        )
        .to(
          '.tl2',
          {
            x: '36vw',
            y: '-10vw',

            stagger: 0.3,
          },
          'second',
        )
      tl.addLabel('third')
        .to(
          '.tl2',
          {
            x: '70vw',
            y: '-30vw',

            stagger: 0.3,
          },
          'third',
        )
        .to(
          '.tl3',
          {
            x: '36vw',
            y: '-10vw',

            stagger: 0.3,
          },
          'third',
        )
      tl.addLabel('fourth')
        .to(
          '.tl3',
          {
            x: '70vw',
            y: '-30vw',

            stagger: 0.3,
          },
          'fourth',
        )
        .to(
          '.tl4',
          {
            x: '36vw',
            y: '-10vw',

            stagger: 0.3,
          },
          'fourth',
        )
        .to('.timeline-first-loop', { opacity: 0 })
        .to('.timeline-second-loop', { opacity: 1 })

      tl.addLabel('fifth')
        .to(
          '.tl4',
          {
            x: '70vw',
            y: '-30vw',

            stagger: 0.3,
          },
          'fifth',
        )
        .to(
          '.tl5',
          {
            x: '36vw',
            y: '-10vw',

            stagger: 0.3,
          },
          'fifth',
        )
      tl.addLabel('sixth')
        .to(
          '.tl5',
          {
            x: '70vw',
            y: '-30vw',

            stagger: 0.3,
          },
          'sixth',
        )
        .to(
          '.tl6',
          {
            x: '36vw',
            y: '-10vw',

            stagger: 0.3,
          },
          'sixth',
        )
      tl.addLabel('seventh')
        .to(
          '.tl6',
          {
            x: '70vw',
            y: '-30vw',

            stagger: 0.3,
          },
          'seventh',
        )
        .to(
          '.tl7',
          {
            x: '36vw',
            y: '-10vw',

            stagger: 0.3,
          },
          'seventh',
        )
      tl.addLabel('eight_th')
        .to(
          '.tl7',
          {
            x: '70vw',
            y: '-30vw',

            stagger: 0.3,
          },
          'eight_th',
        )
        .to(
          '.tl8',
          {
            x: '36vw',
            y: '-10vw',

            stagger: 0.3,
          },
          'eight_th',
        )
    }, component)
    return () => ctx.revert()
  }, [])

  return (
    <section id="timeline-container" className="bg-black overflow-hidden">
      {/* Desktop View */}
      <div
        ref={component}
        className="md:block hidden blade-top-padding-xl blade-bottom-padding-xl"
      >
        <div className="w-container timeline-pinned-container blade-top-padding h-[300vh]">
          <HeadingAtom
            className="timeline-heading 2xl:max-w-4xl max-w-2xl text-left ml-auto blade-bottom-padding"
            title="While We Rewrite the Narratives of Possibilities,"
            subtitle="Here’s Our Story of Transformation"
          />

          <div className="content-wrapper grid place-content-center relative min-h-[50vh] w-4/12">
            {/* Duration card */}
            <div
              key="card"
              className="tl z-20 -rotate-2 2xl:w-[28vw] w-[25vw] text-white absolute inset-0 m-auto grid place-content-center"
            >
              <img className="z-20" src={place} alt="sdflkj" />
              <div className="timeline-first-loop absolute z-30 inset-0 grid place-content-center ">
                <h6 className="text-center">September</h6>
                <h1 className="font-semibold text-big text-stroke">2016</h1>
              </div>
              <div className="timeline-second-loop opacity-0 absolute z-30 inset-0 grid place-content-center ">
                <h6 className="text-center">June</h6>
                <h1 className="font-semibold text-big text-stroke">2020</h1>
              </div>
              <div
                key="card"
                className="tl z-5 rotate-6 2xl:w-[28vw] w-[25vw] text-white absolute inset-0 m-auto grid place-content-center opacity-20"
              >
                <img src={place} alt="time" />
              </div>
              <div
                key="card"
                className="tl z-5 rotate-3 2xl:w-[28vw] w-[25vw] text-white absolute inset-0 m-auto grid place-content-center opacity-30"
              >
                <img src={place} alt="time" />
              </div>
            </div>

            {timeline.map((item, index) => {
              return (
                <div
                  key="card"
                  className={`tl tl${
                    index + 1
                  } 2xl:w-[28vw] w-[25vw] text-white absolute -rotate-2 inset-0 m-auto grid place-content-center `}
                >
                  <div>
                    <img className="bg-black" src={item.img} alt={item.title} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {/* Mobile view */}
      <MobileTimeline />
    </section>
  )
}
