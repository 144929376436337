import React from 'react'
import HeadingTile from 'molecules/headingTile'

export default function Overview() {
  return (
    <div id="overview" className="w-container">
      <HeadingTile
        title="Case Study Overview"
        paragraphs={[
          `As IT revolutionizes business operations, the challenge of efficient operations and management has become pressing. A tech startup realized the very critical need and entrusted the team at Innogent in the scaling and integration stage of development `,
          'that would not only address the challenge but also revolutionize the way organizations approach cloud expenditure.',
        ]}
      />

      <HeadingTile
        title="Company & Product Overview"
        paragraphs={[
          'By identifying and optimizing duplicate, unused, and hidden applications, CloudEagle empowers businesses to significantly reduce their SaaS spending. Its developed application’s custom reporting capabilities allow ',
          'organizations to make informed decisions and confidently right-size their contracts, leading to substantial cost savings and addressing the financial inefficiencies.',
        ]}
        className="2xl:mt-32 md:mt-16 mt-10"
      />
    </div>
  )
}
