import React from 'react'
import HeadingTile from 'molecules/headingTile'

export default function Overview() {
  return (
    <div id="overview" className="w-container">
      <HeadingTile
        title="Case Study Overview"
        paragraphs={[
          `Innogent sought to streamline data integration from a diverse set of sources, including QuickBooks Online, QuickBooks Desktop, Xero, BambooHR, Sage Intact, Zendesk, Okta, Netsuite, SaasOptics, Snowflake, Ironclad, Motion,  DialPad, New Relic, etc.`,
          ' The goal was to effectively Extract, Transform, and Load (ETL) data from these sources into the organizational system, enabling better contract visualization, user data provisioning, and finance data management.',
        ]}
      />
      {/* 
      <HeadingTile
        title="Company Overview"
        paragraphs={[
          'In a digital age where every pixel counts, Crop.Photo, the flagship product of Evolphin has been developed to specialize in AI-driven solutions for automating e-commerce image processing and enhancement.',
          'The SaaS application uses two modules; Listing Analyzer and Photo Processor, to cater to the diverse image processing and enhancement needs of e-commerce companies.',
        ]}
        className="2xl:mt-32 md:mt-16 mt-10"
      /> */}
      {/* <div className="md:mt-14 mt-6">
        <img
          className="w-full rounded-lg"
          src={overview}
          alt="team reviewing vr device"
        />
      </div> */}
    </div>
  )
}
