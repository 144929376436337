import React from 'react'
import logo from 'assets/globals/logo.svg'
import { TextAnchor, Anchor, ExternalAnchor } from 'atoms/links'
import GsapScrollTop from 'atoms/gsapScrollTop'

export default function Footer() {
  return (
    <footer className="text-white border-t-gray border-t border-opacity-10 bg-gradient-to-tr from-[#151515] from-0% via-20% to-black to-100% blade-top-padding blade-bottom-padding">
      <div className="w-container">
        <div className="flex lg:gap-20 lg:flex-row flex-col justify-between">
          <div className="flex flex-col">
            <Anchor href="/">
              <img
                src={logo}
                className="h-10 md:h-12 xlg:h-12"
                alt="Innogent logo"
                title="logo"
              />
            </Anchor>
          </div>

          <div className="flex lg:flex-row flex-col items-start md:pr-10 md:gap-20 gap-5 lg:mt-0 mt-8">
            <ul className="grid md:gap-7 gap-5  text-white list-none w-full">
              <li className="w-full block">
                <TextAnchor
                  size="base"
                  color="light"
                  href="/work"
                  text="Work"
                  className="block font-semibold uppercase text-opacity-90"
                />
              </li>
              <li className="w-full block">
                <GsapScrollTop href="/services">
                  <TextAnchor
                    size="base"
                    color="light"
                    href="/services"
                    text="Services"
                    className="block font-semibold uppercase text-opacity-90"
                  />
                </GsapScrollTop>
              </li>
              <li className="w-full block">
                <TextAnchor
                  size="base"
                  color="light"
                  href="/contact-us"
                  text="Contact Us"
                  className="block font-semibold uppercase text-opacity-90"
                />
              </li>
            </ul>

            <ul className="grid md:gap-7 gap-5  text-white list-none">
              <li className="2xl:text-base text-sm font-semibold uppercase text-opacity-90">
                Solutions
              </li>
              <li>
                <TextAnchor
                  color="light"
                  size="small"
                  className="block whitespace-nowrap"
                  href="/solutions/product-development"
                  text="Product Development / Scaling"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  size="small"
                  className="block whitespace-nowrap"
                  href="/solutions/business-process-transformation"
                  text="Business Process Transformation"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  size="small"
                  className="block whitespace-nowrap"
                  href="/solutions/engineering-sustainability"
                  text=" Engineering Sustainability"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  size="small"
                  className="block whitespace-nowrap"
                  href="/solutions/business-innovation"
                  text="Business Innovation"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  size="small"
                  className="block whitespace-nowrap"
                  href="/solutions/data-management"
                  text="Data Management"
                />
              </li>
            </ul>
            {/* <ul className="grid md:gap-7 gap-5  text-white list-none">
              <li className="uppercase font-bold">Services</li>
              <li>
                <TextAnchor
                  color="light"
                  href="/coming-soon"
                  text="Consulting"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  href="/coming-soon"
                  text="Custom Software Development"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  href="/coming-soon"
                  text="Team Augmentation"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  href="/coming-soon"
                  text="Lean Product Development"
                />
              </li>
              <li>
                <TextAnchor
                  color="light"
                  href="/coming-soon"
                  text="Engineering Excellence"
                />
              </li>
            </ul> */}
            <ul className="grid md:gap-7 gap-5  text-white list-none">
              <li className="2xl:text-base text-sm font-semibold uppercase text-opacity-90">
                Company
              </li>
              {/* <li>
                <TextAnchor color="light"
                className="block whitespace-nowrap"
                size="small"
                href="/services" text="Services" />
              </li>
              <li>
                <TextAnchor color="light"
                className="block whitespace-nowrap"
                size="small"
                href="/coming-soon" text="Work" />
              </li> */}
              <li>
                <GsapScrollTop href="/about">
                  <TextAnchor
                    color="light"
                    className="block whitespace-nowrap"
                    size="small"
                    href="/about"
                    text="About"
                  />
                </GsapScrollTop>
              </li>
              <li>
                <TextAnchor
                  color="light"
                  className="block whitespace-nowrap"
                  size="small"
                  href="/culture"
                  text="Culture"
                />
              </li>
              {/* <li>
                <TextAnchor
                  color="light"
className="block whitespace-nowrap"
size="small"
                  
                  href="/coming-soon"
                  text="Contact Us"
                />
              </li> */}
              <li>
                <TextAnchor
                  color="light"
                  className="block whitespace-nowrap"
                  size="small"
                  href="/coming-soon"
                  text="Blogs"
                />
              </li>
            </ul>
          </div>
        </div>

        <div className=" flex gap-3 md:flex-row flex-col justify-between items-center blade-top-padding">
          {/* <div className="flex gap-4 ">
            <TextAnchor
              color="light"
              text="Privacy Policy"
              href="/coming-soon"
            />
            <span className="opacity-60">|</span>
            <TextAnchor
              color="light"
              text="Terms and Conditions"
              href="/coming-soon"
            />
          </div> */}

          <h6 className="md:block hidden text-sm text-white whitespace-nowrap">
            ©2023. All Rights Reserved.
          </h6>

          <div className="flex items-center gap-4">
            <ExternalAnchor href="https://www.linkedin.com/company/innogent-in">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 448 512"
                className="h-7 hover:fill-blue transition-all"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
              </svg>
            </ExternalAnchor>
            <span className="opacity-60">|</span>
            <ExternalAnchor href="https://www.facebook.com/innogenttechnologies">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 448 512"
                className="h-7 hover:fill-blue transition-all"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
              </svg>
            </ExternalAnchor>
            {/* <ExternalAnchor href={process.env.REACT_APP_TWITTER ?? '#'}>
              Twitter
            </ExternalAnchor> */}
            {/* 
            <span className="opacity-60">|</span>
            
            <ExternalAnchor href={process.env.REACT_APP_MEDIUM ?? '#'}>
              Medium
            </ExternalAnchor> */}
          </div>

          <h6 className="md:hidden block text-sm text-white whitespace-nowrap">
            ©2023. All Rights Reserved.
          </h6>
        </div>
      </div>
    </footer>
  )
}
