import React from 'react'
import HeadingTile from 'molecules/headingTile'
import icons from 'assets/workCaseStudies/onPlan/icons.svg'
import iconsHover from 'assets/workCaseStudies/onPlan/icons-hover.svg'

const data = [
  {
    id: 1,
    title: 'Diverse Data Sources',
    subTitle:
      'Managing data from a wide array of sources, including financial platforms (QuickBooks, Xero, etc.) and user data platforms (Motion, DialPad, etc.), resulted in inefficiencies and data silos.',
    logo: icons,
    logoHover: iconsHover,
  },
  {
    id: 2,
    title: 'Data Complexity',
    subTitle:
      'The need to handle various data formats, structures, and frequent updates made data integration a complex challenge.',
    logo: icons,
    logoHover: iconsHover,
  },
  {
    id: 3,
    title: 'Timeliness',
    subTitle:
      'Ensuring real-time or near-real-time data availability was crucial for contract management and user provisioning.',
    logo: icons,
    logoHover: iconsHover,
  },
  {
    id: 4,
    title: 'Data Accuracy',
    subTitle:
      'Maintaining data accuracy during the transformation and loading process was essential for reliable financial reporting and user management.',
    logo: icons,
    logoHover: iconsHover,
  },
]

export default function ProblemStatements() {
  return (
    <div className="w-container">
      <HeadingTile title="Problem Statements" className="" />

      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 2xl:gap-6 xl:gap-4 gap-5 flex-wrap mt-2">
        {data.map((item) => {
          return (
            <div
              key={item.id}
              className="border border-white border-opacity-10 flex-1 relative group transition-all duration-700 2xl:p-5 p-4 flex flex-col justify-start items-start md:max-w-lg max-w-sm mx-auto 2xl:gap-5 gap-3 2xl:pb-20 xl:pb-8 pb-6"
            >
              <div className="absolute inset-0 h-full w-full group-hover:opacity-100 opacity-0 transition-all duration-700 bg-bladeGradient" />

              <div className="relative">
                <img
                  className="group-hover:opacity-0 2xl:h-14 h-10"
                  src={icons}
                  alt=""
                />
                <img
                  className="opacity-0 group-hover:opacity-100 2xl:h-14 h-10 absolute top-0"
                  src={iconsHover}
                  alt=""
                />
              </div>
              <h5 className="relative text-white group-hover:text-black">
                {item.title}
              </h5>
              <h6 className="2xl:text-lg text-base text-white group-hover:text-black opacity-80 font-light">
                {item.subTitle}
              </h6>
            </div>
          )
        })}
      </div>
    </div>
  )
}
