import React from 'react'
import cloud1 from 'assets/landingPage/techStack/cloud/cloud_1.png'
import cloud2 from 'assets/landingPage/techStack/cloud/cloud_2.png'
import cloud3 from 'assets/landingPage/techStack/cloud/cloud_3.png'
import cloud4 from 'assets/landingPage/techStack/cloud/cloud_4.png'

import datastorage1 from 'assets/landingPage/techStack/datastorage/datastorage_1.png'
import datastorage2 from 'assets/landingPage/techStack/datastorage/datastorage_2.png'
import datastorage3 from 'assets/landingPage/techStack/datastorage/datastorage_3.png'
import datastorage4 from 'assets/landingPage/techStack/datastorage/datastorage_4.png'

import langauges1 from 'assets/landingPage/techStack/languages/Languages_1.png'
import langauges2 from 'assets/landingPage/techStack/languages/Languages_2.png'
import langauges3 from 'assets/landingPage/techStack/languages/Languages_3.png'
import langauges4 from 'assets/landingPage/techStack/languages/Languages_4.png'
import langauges5 from 'assets/landingPage/techStack/languages/Languages_5.png'
import langauges6 from 'assets/landingPage/techStack/languages/Languages_7.png'
import langauges7 from 'assets/landingPage/techStack/languages/Languages_8.png'
import langauges8 from 'assets/landingPage/techStack/languages/Languages_6.png'

import dataEng1 from 'assets/landingPage/techStack/dataEng/Data Engineering_1.png'
import dataEng2 from 'assets/landingPage/techStack/dataEng/Data Engineering_5.png'
import dataEng3 from 'assets/landingPage/techStack/dataEng/Data Engineering_2.png'
import dataEng4 from 'assets/landingPage/techStack/dataEng/Data Engineering_6.png'
import dataEng5 from 'assets/landingPage/techStack/dataEng/Data Engineering_3.png'
import dataEng7 from 'assets/landingPage/techStack/dataEng/Data Engineering_7.png'
import dataEng6 from 'assets/landingPage/techStack/dataEng/Data Engineering_4.png'
import dataEng8 from 'assets/landingPage/techStack/dataEng/Data Engineering_8.png'

const firstIcons = [cloud1, cloud2, cloud4, cloud3]
const secondIcons = [datastorage1, datastorage2, datastorage4, datastorage3]
const thirdIcons = [
  langauges1,
  langauges2,
  langauges3,
  langauges4,
  langauges5,
  langauges6,
  langauges7,
  langauges8,
]
const fourIcons = [
  dataEng1,
  dataEng2,
  dataEng3,
  dataEng4,
  dataEng5,
  dataEng7,
  dataEng6,
  dataEng8,
]

export default function TechStack() {
  return (
    <section className="blade-top-padding">
      <section className="grid place-content-center text-center gap-3">
        <span className=" text-40 text-gradient-dark font-semibold ">
          Harness our Stack!
        </span>
      </section>

      <div className="grid max-w-7xl mx-auto md:w-11/12 px-3 pt-5 lg:pt-8 blade-bottom-padding">
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-3 lg:gap-8  pb-5 md:pb-6  lg:pb-8">
          <Card title="Languages & Frameworks" coverImages={thirdIcons} />
          <Card title="Data Storage" coverImages={fourIcons} />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-3 lg:gap-8  pb-5 md:pb-6  lg:pb-8">
          <Card title="Cloud" coverImages={secondIcons} />
          <Card title="Data Engineering" coverImages={firstIcons} />
        </div>
      </div>
    </section>
  )
}

function Card({
  title,
  coverImages,
}: {
  title: string
  coverImages: Array<string>
}) {
  return (
    <article className="rounded-[5px] px-6 py-4 ">
      <div className=" pb-3 md:pb-5 lg:pb-7">
        <h6 className="font-semibold text-white text-opacity-95 px-3 text-center">
          {title}{' '}
        </h6>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 ">
        {coverImages.map((elem, index: number) => {
          const key = `${index}`
          return (
            <div
              className="grid place-content-center place-items-center p-1"
              key={key}
            >
              <img src={elem} alt="Tech stacks used at Innogent tech" />
            </div>
          )
        })}
      </div>
    </article>
  )
}
