import HeadingTile from 'molecules/headingTile'
import React, { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { A11y, Pagination, Scrollbar } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/navigation'
import invoice from 'assets/workCaseStudies/bflows/productFunction/invoice.jpg'
import uniqueDashboard from 'assets/workCaseStudies/bflows/productFunction/uniqueDashboard.png'
import customWorkflow from 'assets/workCaseStudies/bflows/productFunction/customWorkflow.jpg'
import dynamicDiscount from 'assets/workCaseStudies/bflows/productFunction/dynamicDiscount.png'
import customTagMangement from 'assets/workCaseStudies/bflows/productFunction/customTagMangement.png'
import paymentPortal from 'assets/workCaseStudies/bflows/productFunction/paymentPortal.png'

export default function ProductFunctions() {
  const dat = [
    {
      title: 'Invoice Aggregation',
      cover: invoice,
      description:
        'Effortlessly aggregating customer invoices from various platforms, centralizing the invoicing process.',
      id: 1,
    },
    {
      title: 'Unique Dashboard',
      cover: uniqueDashboard,
      description:
        'A panoramic view of paid, overdue, and frequently paid invoices whilst empowering businesses with insights into financial trends and patterns.',
      id: 2,
    },
    {
      title: 'Custom Workflows',
      cover: customWorkflow,
      description:
        'Allowing businesses to design custom workflows, tailoring the platform to their needs, enhancing process management, and aligning with bespoke business strategies.',
      id: 3,
    },
    {
      title: 'Dynamic Discounts',
      cover: dynamicDiscount,
      description:
        'Enabling optimization of cash flow by offering attractive discounts for prompt payments.',
      id: 4,
    },
    {
      title: 'Custom Tag Management',
      cover: customTagMangement,
      description:
        'Facilitating efficient data categorization through a customizable tag management system, enhancing data organization and retrieval.',
      id: 5,
    },
    {
      title: 'Payment Portals Integration',
      cover: paymentPortal,
      description:
        'Seamless integration with various payment portals, enhancing transaction convenience and flexibility.',
      id: 6,
    },
  ]

  return (
    <section className="blade-top-padding-lg xl:pb-0 pb-8">
      <div className="w-container">
        <HeadingTile title="Product Functions" />
      </div>
      {/*  eslint-disable */}
      <Swiper
        className="2xl:pb-10 lg:pb-5 pb-0 mt-6 lg:mt-10"
        spaceBetween={50}
        modules={[Pagination, Scrollbar, A11y]}
        initialSlide={1}
        slidesPerView={1.1}
        slideToClickedSlide
        slideActiveClass="slideActiveClass"
        centeredSlides
        speed={1500}
        grabCursor
        scrollbar={{
          el: '.swiper-scrollbar',
          draggable: true,
          horizontalClass: 'bg-[#181818]',
        }}
        breakpoints={{
          480: {
            slidesPerView: 1.1,
            spaceBetween: 40,
          },
          960: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1280: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          1536: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
        }}
      >
        {dat.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <div className="max-w-5xl mx-auto w-full">
                <div className="flex md:flex-row flex-col-reverse md:gap-10">
                  <div className="flex-1 my-auto">
                    <h6 className="text-white mt-4 pr-8 leading-relaxed font-semibold">
                      {item.title}
                    </h6>
                    <h6 className="text-white text-opacity-60 mt-1 font-light pr-10 leading-relaxed">
                      {item.description}
                    </h6>
                  </div>
                  <div className="border-l-1 border-white h-auto border-opacity-20 md:block hidden" />
                  <div className="flex-1 w-full h-80">
                    <img
                      src={item.cover}
                      alt={item.description}
                      className="w-full h-full object-cover object-left rounded-2xl"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="w-container-sm mx-auto xl:mt-6 mt-0">
        <div className="swiper-scrollbar max-w-xs h-1" />
      </div>
    </section>
  )
}
