import React, { useEffect } from 'react'
import { businessProcessData } from 'static/solutions'
import bgImage from 'assets/solutions/legacy.jpg'

import Banner from '../01_banner'
import Message from '../03_message'
import CuriousMinds from '../04_curiousMinds'
import ProblemStatementMobileView from '../02_problemStatementMobileView'
import GetInTouch from '../06_getInTouch'
import TempProblemStatement from '../02_temp_problemStatement'

export default function PageBusinessProcess() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main className="bg-black">
      <Banner
        title="Engineering Strategic Process Transformations"
        subtitle="Eliminating Roadblocks & Empowering Businesses to Take a Leap to Outdo"
      />

      <div className="md:block hidden">
        <TempProblemStatement
          data={businessProcessData}
          bgImage={bgImage}
          // paddingStyles="pb-[450px]"
        />
      </div>
      <div className="md:hidden block">
        <ProblemStatementMobileView data={businessProcessData} />
      </div>
      <Message theMessage="The solutions aren’t just for today, they are engineered to adapt and scale ensuring your business remains agile and ready for the challenges of tomorrow." />
      <CuriousMinds title="Check our treasure trove of insights on Business Process Transformation with some rewritten operational narratives along the crossroads of technology and strategy." />
      <GetInTouch
        title="Ready to embrace change as an opportunity?"
        subtitle={`Reach out to us today, and let's sculpt a future that's uniquely yours.`}
      />
    </main>
  )
}
