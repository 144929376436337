import React, { useState, useRef, useEffect } from 'react'
import { Culture } from 'static/culture'
import './style.css'
import { gsap } from 'gsap'
import Viewer from './viewer'

export default function CultureGallery() {
  const [modal, setModal] = useState<boolean>(false)
  const [activeSlide, setActiveSlide] = useState<number>(0)
  // eslint-disable-next-line
  const containerRef = useRef<HTMLDivElement>(null)
  const layer1Ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (window.innerWidth > 1024) {
      const container = containerRef.current!
      const layer1 = layer1Ref.current!

      const handleMouseMove = (e: MouseEvent) => {
        const mouseX = e.clientX
        const mouseY = e.clientY

        const offsetX = mouseX / container.offsetWidth - 0.5
        const offsetY = mouseY / container.offsetHeight - 0.5
        const layer1X = offsetX * 60
        const layer1Y = offsetY * 60

        gsap.to(layer1, { x: layer1X, y: layer1Y, duration: 0.5 })
      }

      container.addEventListener('mousemove', handleMouseMove)

      return () => {
        container.removeEventListener('mousemove', handleMouseMove)
      }
    }
  }, [activeSlide])

  return (
    <section className="relative bg-black">
      <div className="bg-ellipse-blur-gradient">
        <div
          ref={containerRef}
          className="parallax-container relative lg:h-[150vh] lg:max-h-[1200px]"
        >
          <div
            ref={layer1Ref}
            className="gallery-layout lg:absolute static lg:px-0 px-4 layer-1 inset-0 h-full w-full lg:flex grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4"
          >
            <div className="lg:absolute static top-[15%] left-[50%] z-50">
              <div className="max-w-md xl:max-w-lg 2xl:max-w-3xl lg:ml-auto z-10">
                <h1 className="font-semibold leading-tight text-gradient-dark">
                  It’s{' '}
                  <span className="bg-[#82A1BF] line-through decoration-2 decoration-[#82A1BF] bg-clip-text ">
                    a culture
                  </span>{' '}
                  a lifestyle you are entering into!
                </h1>
                <h5 className=" font-medium text-gray md:mt-4 mt-2 leading-normal">
                  A Tour of The Den Awaits You!
                </h5>
              </div>
            </div>
            {Culture.map((item, index) => {
              return (
                <button
                  type="button"
                  key={`${item.des + index}`}
                  onClick={() => {
                    setActiveSlide(index)
                    setModal(true)
                  }}
                  className={`gallery-image gallery-image-${index} lg:absolute static md:h-fit lg:h-64 xl:h-72 2xl:h-[340px] lg:w-[15%] cursor-pointer transition-all duration-500`}
                >
                  <img
                    className="h-full object-cover"
                    src={item.imgSrc}
                    alt={item.des}
                  />
                </button>
              )
            })}
          </div>
        </div>
      </div>

      {modal ? (
        <div>
          <Viewer
            modal={modal}
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            setModal={setModal}
          />
        </div>
      ) : (
        ''
      )}
    </section>
  )
}
