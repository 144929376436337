import HeadingTile from 'molecules/headingTile'
import React from 'react'

export default function Proficiency() {
  return (
    <div className="w-container text-white">
      <HeadingTile title="Proficiency" />

      <h3 className="lg:w-8/12 md:w-11/12 2xl:pr-16 pr-0 leading-normal">
        In the realm of competitive e-commerce,{' '}
        <br className="md:block hidden" /> image perfection reigns supreme.
      </h3>

      <div className="lg:w-7/12 md:w-10/12 ml-auto grid md:gap-6 gap-3 lg:mt-14 mt-3 text-white">
        <h6 className="opacity-100">
          The complexities of laborious image editing were what spurred the
          team’s mission to craft an AI-driven solution that surpassed mere
          efficiency and precision.
        </h6>
        <h6 className="opacity-60">
          Meticulously curated, Crop.Photo symbolizes our team&apos;s
          comprehension of e-commerce image processing intricacies. The inbuilt
          Listing Analyzer Module, powered by AI, evaluates images against 40+
          marketplace rules, guiding users to optimize for amplified conversions
          seamlessly. Employing preemptive quality control, it averts penalties
          by proactively resolving image issues before listings reach public
          eyes.
        </h6>
        <h6 className="opacity-60">
          The Photo Processor Module seamlessly blends AI-driven automation with
          intuitive manual enhancement tools, simplifying on-model fashion
          cropping, bulk background edits, and resizing. Beyond enhancing image
          quality, it harmonizes effortlessly with stringent marketplace
          standards.
        </h6>
        <h6 className="opacity-60">
          At Innogent, our approach transcends technology; it&apos;s about
          empowering businesses with transformative tools. Crop.Photo embodies
          this commitment to delivering comprehensive solutions that streamline
          operations, effortlessly meet marketplace standards, and propel
          tangible, sustainable growth in the fiercely competitive e-commerce
          domain.
        </h6>
      </div>
    </div>
  )
}
