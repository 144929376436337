import HeadingTile from 'molecules/headingTile'
import React from 'react'

export default function Proficiency() {
  return (
    <div className="w-container-sm text-white blade-top-padding">
      <div className="blade-top-padding-sm">
        <HeadingTile title="Proficiency" />
      </div>

      <h3 className="lg:w-8/12 sm:w-11/12 2xl:pr-16 pr-0 leading-normal md:block hidden">
        In the intricate domain of financial operations,{' '}
        <br className="lg:block hidden" /> CTOs grapple with multifaceted
        challenges <br className="lg:block hidden" /> necessitating astute
        resolutions.
      </h3>
      <h4 className=" md:hidden block lg:w-8/12 sm:w-11/12 2xl:pr-16 pr-5 leading-normal">
        In the intricate domain of financial operations,{' '}
        <br className="lg:block hidden" /> CTOs grapple with multifaceted
        challenges <br className="lg:block hidden" /> necessitating astute
        resolutions.
      </h4>

      <div className="lg:w-7/12 md:w-10/12 ml-auto grid md:gap-8 gap-3 lg:mt-14 mt-3 text-white pr-5 md:pr-0 ">
        <h6 className="opacity-100 max-w-3xl font-light">
          Bflows epitomizes an amalgamation of astute technological prowess and
          strategic consultancy, adept at deciphering these intricacies. Our
          comprehensive approach is rooted in recognizing the intricate web of
          commercial relationships, harnessing our proprietary Balanced Flows
          Technology. The framework meticulously analyzes extensive data sets,
          optimizing financial agility, and refining operational efficacy.
        </h6>
        <h6 className="opacity-60 max-w-3xl font-light">
          Addressing the exigencies inherent in manual invoice management and
          the delicate equilibrium between receivables and payables, our bespoke
          consulting solutions craft meticulous workflows and seamless
          integrations.
        </h6>
        <h6 className="opacity-60 max-w-3xl font-light">
          Our proficiency in technology facilitates real-time, data-driven
          insights into evolving financial landscapes, fostering collaborative
          transactions while mitigating complexities. Amidst the nuanced fabric
          of financial intricacies, Bflows stands poised as a paragon of
          precision, offering seasoned solutions that empower enterprises to
          navigate and excel within this interwoven terrain.
        </h6>
      </div>
    </div>
  )
}
