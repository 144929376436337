import React from 'react'

export default function Banner() {
  return (
    <section className="animate-fade-in-slow blade-top-padding-xl blade-bottom-padding-xl md:mt-0 mt-16 md:mb-0 mb-10 flex justify-center items-center">
      <div className="2xl:w-container-sm w-container-xs grid 2xl:gap-5 gap-3 blade-top-padding-xl ">
        <h3 className="text-40 font-medium text-white text-center ">
          Over the years, we have decoded intricate dilemmas, and engineered
          solutions that narrate stories of resilience, innovation, and business
          transformation.
        </h3>

        <h5 className="text-white text-opacity-50 text-center 2xl:max-w-3xl max-w-xl mx-auto">
          Let’s deep dive into the case studies and explore possibilities to
          redefine your business’ potential.
        </h5>
      </div>
    </section>
  )
}
