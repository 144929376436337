import React from 'react'
import CaseStudies from 'organisms/caseStudies'

export default function SuccessStory() {
  return (
    <section className="blade-top-padding-xl blade-bottom-padding-xl">
      <div className="2xl:max-w-6xl max-w-screen-md md:px-0 px-4 mx-auto">
        <h1 className="font-semibold leading-tight text-gradient-dark">
          Leading the Charge for Industries! <br className="md:block hidden" />{' '}
          One Success Story at a Time
        </h1>
        <div className="flex justify-end md:mt-16 mt-6">
          <div className="max-w-xl grid gap-5">
            <h4 className="leading-normal font-light text-white text-opacity-60">
              Dive into a catalog of transformative products, tailored to
              empower and enrich our partners’ journey.
            </h4>
          </div>
        </div>
      </div>
      <div className="blade-top-padding-sm">
        <CaseStudies />
      </div>
    </section>
  )
}
