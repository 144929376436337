import React from 'react'
import HeadingTile from 'molecules/headingTile'

interface MetricsData {
  id: string
  value: string
  textValue?: string
  desc: string
}

export default function ImpactMetrics({
  data,
  textValueClassName,
  descClassName,
  sizeLg,
}: {
  data: MetricsData[]
  textValueClassName?: string
  descClassName?: string
  sizeLg?: boolean
}) {
  return (
    <div className="metric-swiper ">
      <div className="pb-6 md:pb-8 xl:pb-10">
        <HeadingTile title="Impact Metrics" center />
      </div>

      <div>
        <div className="2xl:max-w-screen-2xl max-w-screen-xl flex sm:flex-row flex-col flex-wrap justify-center gap-5 md:px-8 px-4 mx-auto">
          {data.map((item: any) => {
            const { id, value, desc, textValue } = item
            return (
              <div
                key={id}
                className={`metric-card flex-1 ${
                  sizeLg
                    ? 'md:blade-top-padding-sm blade-top-padding md:blade-bottom-padding-sm blade-bottom-padding'
                    : 'blade-top-padding blade-bottom-padding'
                } overflow-hidden px-3 min-w-[12rem]`}
              >
                <article className="relative grid place-content-center place-items-center gap-5 z-10">
                  <h2
                    className={`${textValueClassName} font-bold text-white value-gradient bg-clip-text text-transparent`}
                  >
                    {value}
                    {textValue ? <h3 className="inline ">{textValue}</h3> : ''}
                  </h2>
                  <h5
                    dangerouslySetInnerHTML={{ __html: desc }}
                    className={`${descClassName} text-white text-opacity-60 font-normal text-center leading-snug`}
                  />
                </article>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
