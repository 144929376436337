import HeadingTile from 'molecules/headingTile'
import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Scrollbar } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import card1 from 'assets/workCaseStudies/onPlan/functions/01.png'
import card2 from 'assets/workCaseStudies/onPlan/functions/02.png'
import card3 from 'assets/workCaseStudies/onPlan/functions/03.png'
import card4 from 'assets/workCaseStudies/onPlan/functions/04.png'
import card5 from 'assets/workCaseStudies/onPlan/functions/05.png'
import card6 from 'assets/workCaseStudies/onPlan/functions/06.png'

export default function ProductFunctions() {
  const slides = [
    {
      id: 1,
      cover: card1,
      description:
        'Utilizes Google Sheets to seamlessly retrieve data from diverse and disparate sources and integrate it into a unified spreadsheet environment.',
    },
    {
      id: 2,
      cover: card2,
      description:
        'Efficiently gathers data from multiple sources, strategically maps it within Google Sheets, and performs calculations to generate accurate forecasts.',
    },
    {
      id: 3,
      cover: card3,
      description:
        'Streamlines the creation of multiple scenarios and budgets with a single click, providing users with flexibility.',
    },
    {
      id: 4,
      cover: card4,
      description:
        'Users are empowered to effortlessly generate various charts and graphs, enhancing the visualization of their reports.',
    },
    {
      cover: card5,
      id: 5,
      description:
        'Facilitates the creation of BVA (Budget Variance Analysis) reports, providing valuable insights in making informed business decisions.',
    },
    {
      id: 6,
      cover: card6,
      description: 'Enables roll-forward modeling.',
    },
  ]
  return (
    <section>
      <div className="w-container">
        <HeadingTile
          title="Product Functions"
          paragraphs={[
            'OnPlan serves as a comprehensive toolset that transforms the way financial planning and analysis are conducted, enabling efficient decision-making and insightful projections.',
          ]}
        />
      </div>
      <Swiper
        className="2xl:pb-10 lg:pb-5 pb-3 2xl:mt-14 lg:mt-10 mt-5"
        spaceBetween={50}
        modules={[Pagination, Scrollbar]}
        initialSlide={1}
        slidesPerView={1.1}
        centeredSlides
        slideToClickedSlide
        slideActiveClass="slideActiveClass"
        speed={1500}
        grabCursor
        scrollbar={{
          el: '.swiper-scrollbar',
          draggable: true,
          horizontalClass: 'bg-[#181818]',
        }}
        breakpoints={{
          480: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 1.4,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1.8,
            spaceBetween: 30,
          },
          1536: {
            slidesPerView: 1.8,
            spaceBetween: 40,
          },
        }}
      >
        {slides.map((item) => {
          return (
            <SwiperSlide key={item.id} className="flex justify-center">
              <div className="2xl:max-w-3xl max-w-xl w-full">
                <div className="w-full">
                  <img
                    src={item.cover}
                    alt={item.description}
                    className="w-full object-contain rounded-2xl"
                  />
                </div>
                <h6 className="text-white text-opacity-60 mt-8 font-light pr-10 leading-relaxed">
                  {item.description}
                </h6>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="w-container mx-auto">
        <div className="swiper-scrollbar max-w-xs h-1" />
      </div>{' '}
    </section>
  )
}
