import React from 'react'

export default function Banner() {
  return (
    <div className="blade-top-padding-lg blade-bottom-padding">
      <div className="blade-top-padding-lg blade-top-margin-lg blade-bottom-padding-lg blade-bottom-padding-lg grid px-10  place-content-center text-center w-container  gap-3">
        <h2 className="font-semibold bg-silver bg-clip-text text-gradient-dark leading-snug  pt-3 lg:pt-6">
          Unleashing the Maverick Mindset
        </h2>
        <h6 className="text-large text-gray">
          Powering growth through radical ideas
        </h6>
      </div>
    </div>
  )
}
