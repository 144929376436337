import React, { useEffect } from 'react'
import { Culture } from 'static/culture'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'

export default function Viewer({
  activeSlide,
  setActiveSlide,
  setModal,
  modal,
}: {
  activeSlide: number
  setActiveSlide: (newValue: number) => void
  modal: boolean
  setModal: (newValue: boolean) => void
}) {
  const swiperInstance = React.useRef<SwiperCore | null>(null)

  const handleSlideChange = (swiper: SwiperCore): void => {
    setActiveSlide(swiper.activeIndex)
    // console.log(activeSlide)
  }

  const goToSlide = (slideIndex: number): void => {
    if (swiperInstance.current) {
      const swiperRef = swiperInstance.current
      swiperRef.slideTo(slideIndex)
    }
  }

  useEffect(() => {
    goToSlide(activeSlide)
  }, [activeSlide])

  useEffect(() => {
    const preventScroll = (e: Event) => {
      e.preventDefault()
    }

    window.addEventListener('wheel', preventScroll, { passive: false })
    window.addEventListener('touchmove', preventScroll, { passive: false })

    return () => {
      window.removeEventListener('wheel', preventScroll)
      window.removeEventListener('touchmove', preventScroll)
    }
  }, [])

  return (
    <div className="fixed inset-0 lg:h-screen w-full bg-dark bg-opacity-80 overflow-hidden">
      <div className="absolute left-0 right-0 bottom-8 flex justify-center">
        <button
          onClick={() => setModal(false)}
          className="text-white  group hover:bg-white top-20 md:top-20 right-3 md:right-0 md:left-0  bg-black bg-opacity-70 rounded-full p-2"
          type="submit"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1"
            className="stroke-white opacity-80 w-6 xlg:w-10 h-6 xlg:h-10 group-hover:stroke-black transition-all duration-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="viewer-slider h-full flex items-center ">
        {/*  eslint-disable */}
        <Swiper
          onSwiper={(swiper) => (swiperInstance.current = swiper)}
          onSlideChange={handleSlideChange}
          spaceBetween={20}
          slideToClickedSlide
          slidesPerView={1}
          breakpoints={{
            786: {
              slidesPerView: 'auto',
              slideNextClass: '_slideNextClass',
              slidePrevClass: '_slidePrevClass',
              slideActiveClass: '_slideActiveClass',
              centeredSlides: true,
            },
          }}
          speed={1000}
          className="lg:h-[50vh] xlg:h-[55vh] 2xl:h-[65vh] h-fit p-4 2xl:min-h-[600px] xlg:min-h-[500px] min-h-[400px] lg:mt-0 mt-20"
        >
          {/*  eslint-enable */}
          {Culture.map((item) => {
            return (
              <SwiperSlide
                key={item.id}
                className="w-fit overflow-hidden flex md:items-end items-center justify-center"
              >
                <div className="slides w-fit md:h-[400px] h-[300px] transition-all  duration-500 flex items-end justify-center ">
                  <img
                    className="h-full w-auto object-contain "
                    src={item.imgSrc}
                    alt={item.des}
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}
