import React, { ReactNode } from 'react'
import { NavLink, Link } from 'react-router-dom'

const sizeMap = {
  small: '2xl:text-sm text-sm',
  base: '2xl:text-base text-sm',
  large: ' text-base md:text-lg',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}

const colorMap = {
  dark: 'text-black',
  light: 'text-white',
  blue: 'text-blue',
}
type MasterAnchorProps = {
  size?: keyof typeof sizeMap
  text: string
  href: string
  target?: '_self' | '_blank'
  className?: string
}

export function TextAnchor({
  text,
  target,
  href,
  color = 'dark',
  size = 'base',
  className,
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <Link
      target={target}
      to={href}
      {...rest}
      className={`${sizeClass} ${colorClass} ${className} text-opacity-70 hover:text-opacity-100 focus:outline-none 
                  focus:underline decoration-from-font underline-offset-4 focus:text-opacity-100 transition-all`}
    >
      {text}
    </Link>
  )
}
export function TextAnchorBlue({
  text,
  target,
  href,
  color = 'blue',
  size = 'base',
  className,
  ...rest
}: MasterAnchorProps & { color?: 'blue' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <Link
      target={target}
      to={href}
      {...rest}
      className={`${sizeClass} ${colorClass} ${className} font-medium hover:text-white focus:outline-none 
                  focus:underline decoration-from-font underline-offset-4 group focus:text-opacity-100 transition-all duration-500 w-fit flex items-center gap-2`}
    >
      {text}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 32"
        className="group-hover:translate-x-1 transition-all duration-500 h-7 mt-[2px]"
      >
        <circle
          cx="15.696"
          cy="16.262"
          r="15.24"
          fill="#0080FF"
          strokeWidth="0.611"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.384"
          d="M10.621 16.265h10.147M16.617 12.114l4.151 4.151-4.15 4.151"
        />
      </svg>
    </Link>
  )
}

export function TextNavAnchor({
  text,
  target,
  href,
  color = 'dark',
  size = 'base',
  className,
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <NavLink
      target={target}
      to={href}
      {...rest}
      className={(navData) => {
        return `${
          navData.isActive ? 'text-lightGray' : 'text-gray'
        } ${className} ${sizeClass}  ${colorClass} hover:text-darkSlate transition-all duration-300`
      }}
    >
      {text}
    </NavLink>
  )
}

export function HeroAnchor({
  text,
  size = 'base',
  href,
  target,
  ...rest
}: MasterAnchorProps) {
  const sizeClass: string = sizeMap[size]
  return (
    <Link to={href} target={target} {...rest} className={`${sizeClass} `}>
      {text}
    </Link>
  )
}
// control the outline of anchor
export function Anchor({
  href,
  children,
  className = '',
}: {
  children: ReactNode
  className?: string
  href: string
}) {
  return (
    <Link className={className} to={href}>
      {' '}
      {children}
    </Link>
  )
}

export function ExternalAnchor({
  href,
  children,
  className,
}: {
  href: string
  className?: string

  children: ReactNode
}) {
  return (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}
