import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SubFooter from 'organisms/subFooter'

import improve_decision_making from 'assets/workCaseStudies/etl/benefits/improve_decision_making.jpg'
import enhance_user_management from 'assets/workCaseStudies/etl/benefits/enhance_user_management.jpg'
import contact_manement from 'assets/workCaseStudies/etl/benefits/contact_manement.jpg'
import reduce_data_sites from 'assets/workCaseStudies/etl/benefits/reduce_data_sites.jpg'
import cost_reduction from 'assets/workCaseStudies/etl/benefits/cost_reduction.jpg'

import './onplan.css'
import Banner from '../similarSection/banner'
import Overview from './overview'
import ProductFunctions from './productFunctions'
import Benefits from '../similarSection/benefits'
import ImpactMetrics from '../similarSection/impactMetrics'
import Conclusion from '../similarSection/conclusion'
import ProblemStatements from './problemStatements'
import Approach from './approach'

export default function PageETL() {
  const [isActive, setIsActive] = useState<any>(1)
  const navigate = useNavigate()

  const tabManagementData = [
    {
      id: 1,
      text: 'Overview',
      sectionId: 'overview',
    },
    {
      id: 2,
      text: 'Problem Statements',
      sectionId: 'problem-statements',
    },
    {
      id: 3,
      text: 'Product Functions',
      sectionId: 'product-functions',
    },
    {
      id: 4,
      text: 'Benefits',
      sectionId: 'benefits',
    },
    {
      id: 5,
      text: 'Approach',
      sectionId: 'approach',
    },

    {
      id: 6,
      text: 'Impact Metrics',
      sectionId: 'impact-metrics',
    },
    {
      id: 7,
      text: 'Conclusion',
      sectionId: 'conclusion',
    },
  ]

  const handleSectionNavigation = (sectionId: string) => {
    const ele = document.querySelector(`#${sectionId}`)
    ele?.scrollIntoView({ behavior: 'smooth' })
  }

  const goToContact = () => navigate('/contact-us')

  const handleScroll = () => {
    const sec = document.querySelectorAll('.section-wrapper')

    sec.forEach((item, ind) => {
      const rect = item?.getBoundingClientRect()
      if (rect) {
        const isInView =
          rect.top >= -1 &&
          rect.left >= 0 &&
          rect.top <= window.innerHeight / 5 &&
          rect.right >= window.innerHeight / 5

        if (isInView) {
          ind += 1
          setIsActive(ind)
        }
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isActive])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="evolphin-wrapper">
      <Banner
        title='Transforming Financial & <br class="md:block hidden"/> User Data with ETL Integration'
        subTitle="Streamlining data integration from disparate sources for an efficient organizational setup"
        logoShadow={false}
        className="xl:h-[90vh] 2xl:!pt-20 !pt-14"
        subTitleClassName="max-w-2xl"
      />

      <div className="blade-top-padding blade-bottom-padding-sm relative">
        <div className="lg:flex hidden w-fit z-[999] sticky top-[90vh] justify-center 2xl:gap-10 gap-6 mx-auto 2xl:py-3 py-2 px-6 lg:px-16 border-[1px] border-zinc-800 rounded-3xl bg-gradient-to-tl from-zinc-800 to-black">
          {tabManagementData.map((item, i) => {
            return (
              <button
                className={`text-white 2xl:text-base text-sm font-light whitespace-nowrap ${
                  isActive === item.id ? 'opacity-100' : 'opacity-50'
                }`}
                type="button"
                onClick={() => handleSectionNavigation(item.sectionId)}
                key={item.id}
              >
                {item.text}
              </button>
            )
          })}
        </div>

        <div className="section-wrapper blade-top-padding-sm" id="overview">
          <Overview />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="problem-statements"
        >
          <ProblemStatements />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="product-functions"
        >
          <ProductFunctions />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="benefits">
          <Benefits data={beneFitsData} />
        </div>

        <div className="section-wrapper blade-top-padding-sm" id="approach">
          <Approach />
        </div>

        <div
          className="section-wrapper blade-top-padding-xl"
          id="impact-metrics"
        >
          <ImpactMetrics
            data={impactMetrics}
            sizeLg
            textValueClassName="2xl:text-2xl text-xl text-center"
            descClassName="2xl:text-lg text-base"
          />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="conclusion">
          <Conclusion
            title="Conclusion"
            paragraphs={[
              'Innogent’s custom ETL framework is a testament to the team’s commitment to innovation and efficiency. Operating seamlessly in a distributed environment, supporting various trigger types, including on-demand and scheduled executions, our comprehensive ETL integration solution successfully addressed the challenges of diverse data sources, data complexity, timeliness, and data accuracy. The organization now enjoys improved decision-making, streamlined data management, and significant cost savings. The case study highlights the importance of robust ETL processes in managing data from multiple sources, ultimately leading to a more efficient and data-driven organizational setup.',
            ]}
          />
        </div>
      </div>
      <SubFooter
        title={`Ready to pave the way for a <br class="md:block hidden" />  data-driven organizational <br class="md:block hidden" />  setup through ETL? `}
        cta={[
          {
            type: 'secondary',
            text: `Let's connect!`,
            onClick: goToContact,
            color: 'transparent',
          },
        ]}
      />
    </div>
  )
}

const beneFitsData = [
  {
    title: 'Improved Decision-Making',
    desc: 'Real-time financial data access led to better financial decision-making.',
    coverImage: improve_decision_making,
  },
  {
    title: 'Enhanced User Management',
    desc: `Provisioning and de-provisioning user data became more efficient and accurate.`,
    coverImage: enhance_user_management,
  },
  {
    title: 'Streamlined Contract Management',
    desc: 'Contracts from Iron Clad were easily visualized and managed.',
    coverImage: contact_manement,
  },
  {
    title: 'Reduced Data Silos',

    desc: 'A single data warehouse improved data collaboration and reduced silos.',
    coverImage: reduce_data_sites,
  },
  {
    title: 'Cost Reduction',
    desc: `Leveraging a 'Pay-As-You-Go' model with cloud functions led to significant cost savings by charging only for active data processing, optimizing resource usage, and eliminating idle resource costs.`,
    coverImage: cost_reduction,
  },
]

const impactMetrics = [
  {
    id: '1',
    value: 'Data Latency',
    desc: 'Reduced data update time from hours to minutes.',
  },
  {
    id: '2',
    value: 'Data Accuracy ',
    desc: 'Achieved a 99% data accuracy rate.',
  },
  {
    id: '3',
    value: 'Operational Efficiency',
    desc: 'Decreased data-related operation time by 30%.',
  },
  {
    id: '3',
    value: 'Cost Savings',
    desc: 'Reduced data management costs by 15%.',
  },
  {
    id: '3',
    value: 'Time to Value (TTV)',
    desc: 'Significantly decreased customer Time to Value, enabling them to derive value from our services in days instead of weeks.',
  },
]
