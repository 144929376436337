import React, { useEffect } from 'react'
import { businessInnovationData } from 'static/solutions'
import bgImage from 'assets/solutions/innovation.jpg'

import Banner from '../01_banner'
import Message from '../03_message'
import CuriousMinds from '../04_curiousMinds'
import ProblemStatementMobileView from '../02_problemStatementMobileView'
import GetInTouch from '../06_getInTouch'
import TempProblemStatement from '../02_temp_problemStatement'

export default function PageBusinessInnovation() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main className="bg-black">
      <Banner
        title="Fusing Human-Ingenuity & Tech to"
        subtitle="Enhance your Business’ Readiness to Embrace Innovation"
      />
      <div className="md:block hidden">
        <TempProblemStatement data={businessInnovationData} bgImage={bgImage} />
      </div>
      <div className="md:hidden block">
        <ProblemStatementMobileView data={businessInnovationData} />
      </div>
      <Message theMessage="In a world where standing still is akin to moving backward, the question isn't whether you'll innovate – it's how brilliantly you'll shape the future." />
      <CuriousMinds subtitle="Check our treasure trove of insights with some rewritten narratives around business innovation along the crossroads of technologies and consulting. " />
      <GetInTouch
        title="Innovation Awaits to Transform Your Business’ Potential!"
        subtitle="Reach out to us today, and let's sculpt a future that's uniquely yours."
      />
    </main>
  )
}
