import HeadingTile from 'molecules/headingTile'
import React from 'react'

export default function Proficiency() {
  return (
    <div className="w-container text-white">
      <HeadingTile title="Proficiency" />

      <h3 className="lg:w-8/12 md:w-11/12 2xl:pr-16 pr-0 leading-normal">
        Cloud technology has ushered in
        <br className="md:block hidden" /> unprecedented growth opportunities.
      </h3>

      <div className="lg:w-7/12 md:w-10/12 ml-auto grid md:gap-6 gap-3 lg:mt-14 mt-3 text-white">
        <h6 className="opacity-100">
          Yet, with this surge, a hidden adversary emerges - the intricate web
          of IT expenditure. The conundrum of redundant, unused, and elusive
          applications silently inflates costs, eluding scrutiny within a SaaS
          environment.
        </h6>
        <h6 className="opacity-60">
          CloudEagle doesn&apos;t just promise savings; it delivers measurable
          impact. Think significant cost reductions, precise resource
          allocation, and a newfound confidence in decision-making. The
          application serves a testament to our expertise and commitment to
          revolutionize the product development journey with a consultative
          approach.
        </h6>
      </div>
    </div>
  )
}
