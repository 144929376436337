import React, { useEffect, useRef, useState } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { gsap } from 'gsap'
import { featuresData } from 'static/services'
import SplitType from 'split-type'
import './index.css'

gsap.registerPlugin(ScrollTrigger)

export default function SlideAnimation() {
  const [viewIndex, setViewIndex] = useState(0)
  const wrapper = useRef<any>()
  const imgWrapper = useRef<any>()
  const cardWrapper = useRef<any>()
  const nodeRef = useRef<any>()

  useEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth > 768) {
      const selector = gsap.utils.selector(cardWrapper)
      const ctx = gsap.context(() => {
        const articles = selector('article')
        articles.forEach((elem, index) => {
          const allChildNodes = elem.querySelectorAll('.split-type')

          gsap.fromTo(
            elem,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              duration: 0.5,
              onStart: () => {
                setViewIndex(index)
                allChildNodes.forEach((item) => {
                  const text = new SplitType(item as HTMLElement, {
                    types: 'lines',
                  })
                  console.log(text)
                  gsap.fromTo(text.lines, { x: 40 }, { x: 0, stagger: 0.02 })
                })
              },
              scrollTrigger: {
                trigger: elem,
                start: 'top 50%',
                end: 'bottom 50%',
                markers: false,
                toggleActions: 'play reverse play reverse',
              },
            },
          )
        })
      }, wrapper)
      return () => ctx.revert()
    }
  }, [])

  const handleClick = (i: number, id: number) => {
    const element = document.getElementById(`${id}`)
    console.log(element)
    if (element) {
      gsap.to(window, { duration: 1, scrollTo: element })
    }
    setViewIndex(id)
  }

  return (
    <div className="bg-darkSlate blade-bottom-padding" ref={wrapper}>
      <div>
        <div className="md:grid gap-10 hidden grid-cols-2" ref={imgWrapper}>
          <div className="blade-bottom-padding-lg">
            <div className="2xl:h-[45rem] h-[35rem] blade-top-padding-lg grid place-content-center sticky top-0 md:ml-[15%]">
              <SwitchTransition>
                <CSSTransition
                  key={viewIndex}
                  nodeRef={nodeRef}
                  timeout={500}
                  onEnter={(contentEl: any) =>
                    gsap.fromTo(contentEl, { opacity: 0.5 }, { opacity: 1 })
                  }
                  classNames="imgslide"
                  unmountOnExit
                >
                  <div
                    className="list-none dropDown inline w-container 2xl:w-full w-10/12 pl-10"
                    ref={nodeRef}
                  >
                    <img
                      className="w-full"
                      src={featuresData[viewIndex].imgSrc}
                      alt={featuresData[viewIndex].title}
                    />
                  </div>
                </CSSTransition>
              </SwitchTransition>

              <div className="absolute top-0 bottom-0 xl:left-10 left-0 h-full flex flex-col gap-[10px] justify-center items-center">
                {featuresData.map((item, index) => {
                  const { id } = item
                  return (
                    <button
                      key="pagination"
                      type="button"
                      aria-label="paginations"
                      // onClick={() => handleClick(index, id)}
                      className={`rounded-full w-[10px] bg-white transition-all duration-500 ${
                        index === viewIndex
                          ? 'bg-darkBlue h-20'
                          : 'bg-white h-[10px]'
                      }`}
                    />
                  )
                })}
              </div>
            </div>
          </div>

          <div className="cards-wrapper blade-top-padding-xl" ref={cardWrapper}>
            {featuresData.map((item: any, i: number) => {
              const ind = `${i}-feature-cards`
              const { title, subtitle, paragraph, subParagraph, imgSrc } = item
              return (
                <article
                  key={ind}
                  id={item.id}
                  className="last-of-type:2xl:pb-[15rem] last-of-type:xl:pb-[12rem] last-of-type:pb-[8rem] first-of-type:xl:blade-top-padding pb-10"
                >
                  <FeatureCard
                    title={title}
                    desc={subtitle}
                    paragraph={paragraph}
                    subParagraph={subParagraph}
                    imgSrc={imgSrc}
                  />
                </article>
              )
            })}
          </div>
        </div>

        <div className="grid md:hidden blade-top-padding-xl ">
          <div className="cards-wrapper grid gap-10 w-container">
            {featuresData.map((item: any, i: number) => {
              const ind = `${i}-feature-cards`
              const { title, subtitle, paragraph, subParagraph, imgSrc } = item
              return (
                <div key={ind}>
                  <FeatureCard
                    title={item.title}
                    desc={item.subtitle}
                    paragraph={paragraph}
                    subParagraph={subParagraph}
                    imgSrc={imgSrc}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function FeatureCard({
  title,
  desc,
  imgSrc,
  paragraph,
  subParagraph,
}: {
  title: string
  desc: string
  imgSrc?: string
  paragraph?: string
  subParagraph?: string
}) {
  return (
    <div className="flex flex-col md:gap-4 md:pr-28 md:mt-0 2xl:max-w-3xl max-w-2xl ">
      <h2 className="split-type font-semibold text-black">{title}</h2>
      <h6 className="split-type text-black md:mt-0 mt-2 leading-relaxed max-w-2xl font-normal">
        {desc}
      </h6>
      <div className="md:hidden flex justify-start lg:max-w-none max-w-[200px]">
        <img className="w-full" src={imgSrc} alt="" />
      </div>
      <div className="flex-1 max-w-xl">
        {paragraph ? (
          <h5 className="split-type font-semibold">{paragraph}</h5>
        ) : (
          ''
        )}
        <h6 className="split-type mt-2">{subParagraph}</h6>
      </div>
    </div>
  )
}
