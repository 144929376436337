import React from 'react'
import HeadingTile from 'molecules/headingTile'
import icons from 'assets/workCaseStudies/onPlan/icons.svg'
import iconsHover from 'assets/workCaseStudies/onPlan/icons-hover.svg'

const data = [
  {
    id: 1,
    title: 'Custom ETL Pipelines',
    subTitle:
      'The team tailored pipelines for each data source, ensuring efficient extraction, transformation, and loading (ETL). Unlike traditional ETL tools, the approach allowed us to create custom transformations as needed, providing greater control and accuracy.',
  },
  {
    id: 2,
    title: 'Data Warehouse (BigQuery)',
    subTitle:
      'Recognizing the importance of a centralized data repository, Google BigQuery served as our data warehouse, offering scalability, robust security features, and real-time data access.',
  },
  {
    id: 3,
    title: 'ETL Automation',
    subTitle:
      'Timeliness and accuracy were paramount. We automated the scheduling of ETL jobs, ensuring that data updates occurred precisely when needed. This not only improved efficiency but also reduced the risk of data inconsistencies.',
  },
  {
    id: 4,
    title: 'Data Quality Checks',
    subTitle:
      'Data accuracy was non-negotiable. To maintain data integrity, we implemented rigorous data quality checks at each stage of the ETL process. These checks became an integral part of our data pipeline, guaranteeing the reliability of our insights.',
  },
]

export default function ProductFunctions() {
  return (
    <div className="w-container">
      <HeadingTile
        title="Product Functions"
        paragraphs={[
          'Innogent’s ETL solution was born out of the necessity to meet stringent deadlines while accommodating the unique requirements of data integration which demanded enhanced flexibility. The team created an inhouse framework that leveraged the capabilities of',
          'Google Cloud services, specifically Google Cloud Functions, Google Pub/Sub, and BigQuery. The result was a self-sufficient and highly adaptable solution that could seamlessly handle data from disparate sources.',
        ]}
      />

      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 2xl:gap-6 xl:gap-4 gap-5 flex-wrap mt-10">
        {data.map((item) => {
          return (
            <div
              key={item.id}
              className="border border-white border-opacity-10 flex-1 relative group transition-all rounded-lg overflow-hidden duration-700 2xl:p-5 p-4 flex flex-col justify-start items-start md:max-w-lg max-w-sm mx-auto 2xl:gap-5 gap-3 2xl:pb-20 xl:pb-8 pb-6"
            >
              <div className="absolute inset-0 h-full w-full transition-all duration-700 bg-gradient-to-br from-zinc-800 to-transparent " />

              <h5 className="relative text-white">{item.title}</h5>
              <h6 className="2xl:text-lg xl:text-sm text-base text-white opacity-80 font-light">
                {item.subTitle}
              </h6>
            </div>
          )
        })}
      </div>
    </div>
  )
}
