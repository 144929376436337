import React from 'react'
import overview from 'assets/workCaseStudies/onPlan/dashboard.jpg'
import HeadingTile from 'molecules/headingTile'

export default function Overview() {
  return (
    <div id="overview" className="w-container">
      <HeadingTile
        title="Case Study Overview"
        paragraphs={[
          `At the forefront of strategic product development and innovation, our team presents OnPlan—a Financial Planning & Analysis SaaS application meticulously crafted to meet industry demands.`,
          `Leveraging a deep-rooted understanding of industry insights, OnPlan stands as a testament to Innogent’s commitment to redefining the financial planning space, further epitomizing the team's expertise in delivering tangible value to businesses worldwide.`,
        ]}
      />

      <HeadingTile
        title="Company Overview"
        paragraphs={[
          'OnPlan hosts a flexible financial planning, forecasting, and analytics SaaS application that combines insightful visualizations and collaboration on top of spreadsheets to fuel better decision-making within businesses.',
          'The company thrives on making financial modeling intuitive, and interactive through tailored models, visualization, integrated workflows, native scenarios, and variance functionality.',
        ]}
        className="2xl:mt-32 md:mt-16 mt-10"
      />
      <div className="md:mt-14 mt-6">
        <img
          className="w-full rounded-lg"
          src={overview}
          alt="team reviewing vr device"
        />
      </div>
    </div>
  )
}
