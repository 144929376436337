import React from 'react'

export default function Banner() {
  return (
    <section className='bg-black min-h-[480px] md:min-h-[550px] md:bg-right-bottom bg-elementPosition bg-cover bg-no-repeat lg:h-screen bg-[url("assets/globals/bg-element-right.png")]'>
      <div className="w-container-xs min-h-[480px] md:min-h-[550px]  h-full  ">
        <div className="animate-fade-in-slow flex flex-col min-h-[480px] md:min-h-[550px] md:pb-10 md:pt-0 pt-10 gap-3 justify-center md:px-4  h-full">
          <h1 className=" font-semibold text-gradient-dark text-transparent bg-clip-text leading-none">
            Fearlessly Taking <br /> Strides in Sneakers
          </h1>
          <h5 className="font-light leading-normal text-gray">
            Where Backbenchers Find Home <br className="md:block hidden" /> in{' '}
            <span className="text-white font-medium">
              Purpose, Passion, and Fun!
            </span>
          </h5>
        </div>
      </div>
    </section>
  )
}
