import React, { useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'

export default function TempProblemStatement({
  data,
  bgImage,
}: {
  data: any
  bgImage: string
}) {
  const [viewIndex, setViewIndex] = useState(0)
  const [panelIndex, setPanelIndex] = useState(0)

  const wrapperRef = useRef<any>(null)
  const textRef = useRef<any>(null)
  const contentRef = useRef<any>(null)

  useEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth > 768) {
      const selector = gsap.utils.selector(wrapperRef)
      const ctx = gsap.context(() => {
        const tabs = selector('.tab')
        tabs.forEach((elem, index) => {
          gsap.fromTo(
            elem,
            { opacity: 0.2 },
            {
              opacity: 1,
              duration: 1,
              onStart: () => {
                setViewIndex(index)
              },
              scrollTrigger: {
                trigger: elem,
                start: 'top 35%',
                end: 'bottom 40%',
                markers: false,
                toggleActions: 'play reverse play reverse',
              },
            },
          )
        })
      }, wrapperRef)
      return () => ctx.revert()
    }
  }, [viewIndex])

  return (
    <>
      <section
        ref={wrapperRef}
        className="text-white bg-dark-gradient blade-top-padding-xl blade-bottom-padding-lg"
      >
        <div className="w-container flex gap-10">
          <div className="w-5/12 h-fit xlg:pr-28 pr-10 sticky 2xl:top-[20%] top-[15%] pb-[20vh]">
            <h6 className="text-white">{data.paragraph}</h6>
            <h3 className="text-gradient-dark mt-14 text-40 font-semibold ">
              {data.title}
            </h3>
            <h4 className="text-gradient-dark mt-5 2xl:pr-20 pr-10">
              {data.subtitle}
            </h4>
            <div className="paginations absolute right-0 top-2 flex flex-col gap-2 ">
              {data.list.map((item: any, ind: number) => {
                return (
                  <span
                    key={item.id}
                    className={`${
                      viewIndex === ind ? '!h-20 !bg-white' : ''
                    } rounded-full transition-all duration-300 h-3 w-3 bg-darkGray`}
                  />
                )
              })}
            </div>
          </div>
          <div
            ref={textRef}
            className="texts-btn flex-1 relative tabs grid pl-20 pb-[30vh]"
          >
            {data.list.map((item: any, ind: number) => {
              return (
                <a
                  href={`#${item.id}`}
                  onClick={() => {
                    setViewIndex(ind)
                    setPanelIndex(ind)
                  }}
                  type="button"
                  key={item.id}
                  className={`tab tab-${ind} pb-24 text-left `}
                >
                  <h2 className="text-gradient-dark text-40 font-semibold">
                    {`${ind + 1}`}. {item.problem}
                  </h2>
                  <button type="button" className="mt-5 !hover:opacity-100">
                    <svg
                      width="70"
                      height="43"
                      viewBox="0 0 70 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.539062"
                        y="0.705566"
                        width="68"
                        height="41"
                        rx="20.5"
                        fill="#181818"
                        fillOpacity="0.44"
                      />
                      <path
                        d="M46.6968 20.3779C47.1539 20.835 47.1539 21.5761 46.6968 22.0332L39.2481 29.4819C38.791 29.939 38.0499 29.939 37.5928 29.4819C37.1357 29.0248 37.1357 28.2837 37.5928 27.8266L44.2139 21.2056L37.5928 14.5845C37.1357 14.1274 37.1357 13.3863 37.5928 12.9292C38.0499 12.4721 38.791 12.4721 39.2481 12.9292L46.6968 20.3779ZM18.6944 22.376C18.048 22.376 17.5239 21.852 17.5239 21.2056C17.5239 20.5591 18.048 20.0351 18.6944 20.0351V22.376ZM45.8691 22.376H18.6944V20.0351H45.8691V22.376Z"
                        fill="white"
                      />
                      <rect
                        x="0.539062"
                        y="0.705566"
                        width="68"
                        height="41"
                        rx="20.5"
                        stroke="#252525"
                      />
                    </svg>
                  </button>
                </a>
              )
            })}
          </div>
        </div>
      </section>

      <section
        ref={contentRef}
        style={{ backgroundImage: `url(${bgImage})` }}
        className="panels-wrapper bg-bladeGradient bg-fixed  bg-no-repeat xl:bg-left-top bg-center bg-cover blade-bottom-padding-xl overflow-hidden relative"
      >
        {data.list.map((item: any, ind: number) => {
          const {
            id,
            solution: {
              title,
              subtitle,
              paragraphI,
              paragraphII,
              paragraphIII,
            },
          } = item
          return (
            <div
              id={`${id}`}
              key={id}
              className="blade-top-padding-xl relative flex justify-center items-center"
            >
              <section
                className={`panel-section-${id} panel-section flex justify-between w-container`}
              >
                <div className="flex-1 items-center justify-end opacity-0">
                  <div className="max-w-lg w-full d" />
                </div>
                <div className="flex-1 2xl:max-w-3xl max-w-2xl flex 2xl:gap-6 gap-4 flex-col">
                  <div className="border-b-1 border-gray border-dashed flex gap-2 pb-6">
                    <div className="flex ">
                      <h5 className="pr-5 grid justify-start">
                        {`${ind + 1}`}.
                      </h5>
                      <h5>{item.problem}</h5>
                    </div>
                  </div>
                  <h3 className="font-semibold">{title}</h3>
                  <h5 className=" font-semibold">{subtitle}</h5>
                  <h6 className=" ">{paragraphI}</h6>
                  <h6 className="">{paragraphII}</h6>
                  <h6 className="">{paragraphIII}</h6>
                </div>
              </section>
              <div className="bg-lightGray h-16 absolute hidden items-center justify-center gap-2 bottom-0 inset-x-0">
                <div className="flex ">
                  <h5 className="w-24 h-full grid place-content-center ">
                    {`${ind + 1}`}.
                  </h5>
                  <h5 className="font-semibold whitespace-nowrap ">
                    {item.problem}
                  </h5>
                </div>
              </div>
            </div>
          )
        })}
      </section>
    </>
  )
}
