import React from 'react'

export default function Message({ theMessage }: { theMessage: string }) {
  return (
    <div className="w-container blade-top-padding-xl blade-bottom-padding-xl grid place-content-center min-h-[400px] xlg:min-h-[500px]">
      <h3 className="text-48 font-semibold text-gradient-dark text-center">
        <i>“{theMessage}”</i>
      </h3>
    </div>
  )
}
