import React, { useEffect } from 'react'
import Banner from './01_banner'
import CardGrid from './02_cardGrid'

export default function Blogs() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main className="bg-black">
      <Banner />
      <CardGrid />
    </main>
  )
}
