import React from 'react'
import overview from 'assets/workCaseStudies/evolphin/dashboard.jpg'
import HeadingTile from 'molecules/headingTile'

export default function Overview() {
  return (
    <div id="overview" className="w-container">
      <HeadingTile
        title="Case Study Overview"
        paragraphs={[
          `Evolphin's Crop.Photo signifies a pinnacle in AI-driven e-commerce solutions, embodying a well-thought-out response to the complexities inherent in image processing within the space. Developed by Innogent, the application stands as a testament to the team’s endeavor to harness AI capabilities to not just save time and resources but also significantly enhance image quality in the e-commerce ecosystem. Crop.Photo facilitates adherence to stringent marketplace standards, elevating product presentation and catalyzing enhanced conversions.`,
          'At Innogent, we believe in offering transformative tools that empower businesses to thrive in digitally competitive environments. Crop.Photo encapsulates this notion by providing comprehensive, impactful solutions that streamline operations and drive sustainable business growth.',
        ]}
      />

      <HeadingTile
        title="Company Overview"
        paragraphs={[
          'In a digital age where every pixel counts, Crop.Photo, the flagship product of Evolphin has been developed to specialize in AI-driven solutions for automating e-commerce image processing and enhancement.',
          'The SaaS application uses two modules; Listing Analyzer and Photo Processor, to cater to the diverse image processing and enhancement needs of e-commerce companies.',
        ]}
        className="2xl:mt-32 md:mt-16 mt-10"
      />
      <div className="md:mt-14 mt-6">
        <img
          className="w-full rounded-lg"
          src={overview}
          alt="team reviewing vr device"
        />
      </div>
    </div>
  )
}
