import React, { useEffect } from 'react'

import Banner from './01_banner'
import CultureGallery from './02_cultureGallery'
import LimitlessPossibilities from './03_limitlessPossibilities'
import SubFooter from './05_subfooter'
import BeyondBoardrooms from './04_beyondBoardrooms'

export default function PageCulture() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="bg-black">
      <Banner />
      <CultureGallery />
      <LimitlessPossibilities />
      <BeyondBoardrooms />
      <SubFooter />
    </div>
  )
}
