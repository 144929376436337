import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SubFooter from 'organisms/subFooter'
import logo from 'assets/workCaseStudies/evolphin/crop-photo.svg'
import HeadingTile from 'molecules/headingTile'
import dummyIcon from 'assets/workCaseStudies/icon.svg'
import timeSavings from 'assets/workCaseStudies/evolphin/benefits/timeSavings.jpg'
import imageBoosting from 'assets/workCaseStudies/evolphin/benefits/imageBoosting.jpg'
import marketplaceStandards from 'assets/workCaseStudies/evolphin/benefits/marketplaceStandards.jpg'
import fasterCoversions from 'assets/workCaseStudies/evolphin/benefits/fasterCoversions.jpg'

import './onplan.css'
import Banner from '../similarSection/banner'
import IntroVideo from './introVideo'
import Overview from './overview'
import ProductFunctions from './productFunctions'
import Benefits from '../similarSection/benefits'
import Approach from '../similarSection/approach'
import ImpactMetrics from '../similarSection/impactMetrics'
import Conclusion from '../similarSection/conclusion'
import Proficiency from './proficiency'

export default function PageEvolphin() {
  const [isActive, setIsActive] = useState<any>(1)
  const navigate = useNavigate()

  const tabManagementData = [
    {
      id: 1,
      text: 'Overview',
      sectionId: 'overview',
    },
    {
      id: 2,
      text: 'Problem Statements',
      sectionId: 'problem-statements',
    },
    {
      id: 3,
      text: 'Product Functions',
      sectionId: 'product-functions',
    },
    {
      id: 4,
      text: 'Benefits',
      sectionId: 'benefits',
    },
    {
      id: 5,
      text: 'Approach',
      sectionId: 'approach',
    },
    {
      id: 6,
      text: 'Proficiency',
      sectionId: 'proficiency',
    },
    {
      id: 7,
      text: 'Impact Metrics',
      sectionId: 'impact-metrics',
    },
    {
      id: 8,
      text: 'Conclusion',
      sectionId: 'conclusion',
    },
  ]

  const handleSectionNavigation = (sectionId: string) => {
    const ele = document.querySelector(`#${sectionId}`)
    ele?.scrollIntoView({ behavior: 'smooth' })
  }

  const goToContact = () => navigate('/contact-us')

  const handleScroll = () => {
    const sec = document.querySelectorAll('.section-wrapper')

    sec.forEach((item, ind) => {
      const rect = item?.getBoundingClientRect()
      if (rect) {
        const isInView =
          rect.top >= -1 &&
          rect.left >= 0 &&
          rect.top <= window.innerHeight / 5 &&
          rect.right >= window.innerHeight / 5

        if (isInView) {
          ind += 1
          setIsActive(ind)
        }
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isActive])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const introVideo = {
    vdo: '',
  }
  return (
    <div className="evolphin-wrapper">
      <Banner
        title={`E-Commerce Image Processing <br class=""/> & Enhancement with`}
        subTitle={`Enabling Evolphin's Quantum Leap`}
        logo={logo}
        logoShadow={false}
      />
      <IntroVideo />

      <div className="blade-top-padding blade-bottom-padding-sm relative">
        <div className="lg:flex hidden w-fit z-[999] sticky top-[90vh] justify-center 2xl:gap-10 gap-6 mx-auto 2xl:py-3 py-2 px-6 lg:px-16 border-[1px] border-zinc-800 rounded-3xl bg-gradient-to-tl from-zinc-800 to-black">
          {tabManagementData.map((item, i) => {
            return (
              <button
                className={`text-white 2xl:text-base text-sm font-light whitespace-nowrap ${
                  isActive === item.id ? 'opacity-100' : 'opacity-50'
                }`}
                type="button"
                onClick={() => handleSectionNavigation(item.sectionId)}
                key={item.id}
              >
                {item.text}
              </button>
            )
          })}
        </div>

        <div className="section-wrapper blade-top-padding-sm" id="overview">
          <Overview />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="problem-statements"
        >
          <HeadingTile
            title="Problem Statement"
            paragraphs={[
              'E-commerce enterprises grapple with the arduous task of manual image editing, a process that consumes significant time and resources. Traditional methods often entail meticulous, time-intensive adjustments and enhancements to align images with marketplace standards.',
              'This cumbersome process not only burdens teams but also diverts valuable resources away from more strategic business endeavors.',
            ]}
            className="w-container"
          />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="product-functions"
        >
          <ProductFunctions />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="benefits">
          <Benefits data={beneFitsData} />
        </div>

        <div className="section-wrapper blade-top-padding-sm" id="approach">
          <Approach />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="proficiency">
          <Proficiency />
        </div>
        <div
          className="section-wrapper blade-top-padding-xl"
          id="impact-metrics"
        >
          <ImpactMetrics data={impactMetrics} />
        </div>
        <div className="section-wrapper blade-top-padding-xl" id="conclusion">
          <Conclusion
            title="Conclusion"
            paragraphs={[
              'This case study stands as a testament to the power of AI in redefining traditional e-commerce image processing and enhancement mechanisms for concrete and measurable business gains.',
            ]}
          />
        </div>
      </div>
      <SubFooter
        title={`Ready to redefine the <br class="md:block hidden" /> industry of your choosing <br class="md:block hidden" /> through AI?`}
        cta={[
          {
            type: 'primary',
            text: `Let's connect!`,
            onClick: goToContact,
            color: 'black',
          },
        ]}
      />
    </div>
  )
}

const beneFitsData = [
  {
    title: 'Savings in time and resources',
    desc: 'By automating complex tasks and offering seamless tools for image enhancement, Crop.Photo optimizes workflows, allowing teams to allocate resources more strategically and focus on core business objectives.',
    coverImage: timeSavings,

    icon: dummyIcon,
  },
  {
    title: 'Enhanced images boosting product presentation',
    desc: `By enhancing image quality through AI-driven processes, it doesn't just meet basic presentation criteria but elevates the visual narrative, fosters consumer trust, enriches the overall shopping experience, and bolsters the brand’s credibility in a visually saturated e-commerce environment.`,
    coverImage: imageBoosting,

    icon: dummyIcon,
  },
  {
    title: 'Marketplace standards being met with ease',
    desc: 'Crop.Photo ensures effortless compliance with ever-evolving marketplace regulations and standards. Its Listing Analyzer Module, adept at evaluating images against diverse marketplace rules, ensures that product visuals align seamlessly with platform requirements.',
    coverImage: marketplaceStandards,

    icon: dummyIcon,
  },
  {
    title:
      'Improvements in image quality driving better and faster conversions',
    desc: 'High-quality visuals resonate better with consumers, sparking increased interest and confidence in products in turn translating into accelerated purchase decisions and amplified conversion rates for e-commerce businesses.',
    coverImage: fasterCoversions,

    icon: dummyIcon,
  },
]

const impactMetrics = [
  {
    id: '1',
    value: '100',
    desc: 'Daily Retouched Images',
  },
  { id: '2', value: '21 ', textValue: 'Seconds', desc: 'Cropping time' },
  { id: '3', value: '70%', desc: 'Productivity' },
]
