import React, { useEffect } from 'react'
import onPlan from 'assets/workCaseStudies/onPlan/onplan.mp4'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function IntroVideo() {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#intro-video',
          start: 'top 70%',
          end: '110% 50%',
          markers: false,
          scrub: true,
        },
      })
      .fromTo(
        '#intro-video',
        { scale: 0.85, transformOrigin: 'top' },
        {
          scale: 1.05,
        },
      )
      .addPause(0.5)
      .fromTo(
        '#intro-video',
        { filter: 'blur(5px)' },
        {
          filter: 'blur(0px)',
          duration: 1,
        },
      )
      .to('#intro-video', {
        onStart: () => {
          const introVideo = document.querySelector(
            '#intro-video video',
          ) as HTMLVideoElement
          introVideo.play()
        },
        onReverseComplete: () => {
          const introVideo = document.querySelector(
            '#intro-video video',
          ) as HTMLVideoElement
          introVideo.pause()
        },
      })
      .addPause(0.5)
      .to('#intro-video', {
        onComplete: () => {
          const introVideo = document.querySelector(
            '#intro-video video',
          ) as HTMLVideoElement
          introVideo.pause()
        },
      })
      .to('#intro-video', { filter: 'blur(5px)' })
  }, [])
  return (
    <section className="overflow-hidden p-2">
      <section className="animate-fade-in-slow w-11/12 lg:w-7/12 mx-auto">
        <div
          id="intro-video"
          className="md:block hidden mx-auto overflow-hidden transition-all md:rounded-3xl rounded-md blade-bottom-margin-sm"
        >
          <video className="w-full h-full object-cover" muted loop playsInline>
            <source src={onPlan} type="video/mp4" />
            <track kind="captions" />
          </video>
        </div>
        <div className="md:hidden block mx-auto overflow-hidden transition-all md:rounded-3xl rounded-md blade-bottom-margin-sm">
          <video
            className="w-full h-full object-cover"
            muted
            loop
            playsInline
            controls
          >
            <source src={onPlan} type="video/mp4" />
            <track kind="captions" />
          </video>
        </div>

        <div className="flex justify-between gap-4 md:gap-10 mx-auto text-white md:px-10 ">
          <div className="flex flex-col min-w-[70px] gap-2">
            <h6 className="font-light">Client</h6>
            <h6 className="font-light opacity-60">Onplan</h6>
          </div>
          <div className="flex flex-col min-w-[70px] gap-2">
            <h6 className="font-light">Industry</h6>
            <h6 className="font-light opacity-60">
              Financial Planning and Analysis SaaS Application
            </h6>
          </div>
          <div className="flex flex-col min-w-[70px] gap-2">
            <h6 className="font-light">Key features</h6>
            <h6 className="font-light opacity-60">
              The financial forecasting platform
            </h6>
          </div>
        </div>
      </section>
    </section>
  )
}
