import React from 'react'
import data from 'static/work'
import './stylesOrganism.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { TextAnchorBlue } from 'atoms/links'

function Slide({ item }: { item: any }) {
  return (
    <div className="flex sm:flex-row flex-col-reverse h-full justify-between md:p-4 p-3 border-2 border-gray border-opacity-30 md:rounded-xl rounded-2xl">
      <div className="flex-1 xlg:p-5 md:p-3 pt-4 min-h-full">
        <div className="flex justify-end h-full 2xl:gap-3 gap-2 flex-col 2xl:pr-16 xl:pr-5">
          <h6 className="text-opacity-60 text-white font-light">{item.tag}</h6>
          <h4 className="text-large text-white leading-snug">{item.title}</h4>
          <h6 className="text-opacity-60 text-white leading-relaxed ">
            {item.subtitle}
          </h6>
          <TextAnchorBlue
            color="blue"
            href={item.link}
            target="_blank"
            text="Know More"
            size="large"
          />
        </div>
      </div>
      <div className="flex-1 xl:min-h-[300px] 2xl:min-h-[420px] rounded-xl overflow-hidden">
        <img
          className="sm:h-full h-56 w-full object-cover object-center md:rounded-xl rounded-xl"
          src={item.imgUrl}
          alt=""
        />
      </div>
    </div>
  )
}
export default function CaseStudies() {
  return (
    <section>
      <div className="concept-reality case-studies-organism blade-bottom-padding-xl relative ">
        <div className="cursor hidden justify-center items-center z-[9999999] w-24 h-12 absolute border border-black bg-black bg-opacity-50 top-0 left-0 rounded-full">
          <div>
            <svg
              stroke="white"
              fill="white"
              strokeWidth="0"
              viewBox="0 0 16 16"
              className="h-10 rotate-180"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </div>
          <div>
            <svg
              stroke="white"
              fill="white"
              strokeWidth="0"
              viewBox="0 0 16 16"
              className="h-10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </div>
        </div>
        <Swiper
          className=" 2xl:pb-20 xl:pb-14 pb-10"
          spaceBetween={50}
          modules={[Pagination]}
          initialSlide={1}
          slidesPerView={1.1}
          centeredSlides
          slideToClickedSlide
          slideActiveClass="slideActiveClass"
          slideNextClass="slideNextClass"
          slidePrevClass="slidePrevClass"
          speed={1500}
          grabCursor
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            480: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            960: {
              slidesPerView: 1.4,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 1.8,
              spaceBetween: 30,
            },
            1536: {
              slidesPerView: 1.8,
              spaceBetween: 40,
            },
          }}
        >
          {data.map((item: any) => {
            return (
              <SwiperSlide key={item.id}>
                <Slide item={item} />
              </SwiperSlide>
            )
          })}
          <span className="swiper-pagination" />
        </Swiper>
      </div>
    </section>
  )
}
