import React, { ReactNode, Ref, RefObject, useEffect, useRef } from 'react'
import { solutions } from 'static/solutions'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import HeadingAtom from 'atoms/headingAtom'
import '../style.css'
import MobileView from './mobileView'

gsap.registerPlugin(ScrollTrigger)

export default function Possibilities() {
  const component = useRef<HTMLDivElement>(null)
  const slideRef01 = useRef<HTMLDivElement>(null)
  const slideRef02 = useRef<HTMLDivElement>(null)
  const slideRef03 = useRef<HTMLDivElement>(null)
  const slideRef04 = useRef<HTMLDivElement>(null)
  const slideRef05 = useRef<HTMLDivElement>(null)

  const refs = [
    {
      ref: slideRef01,
      id: 1,
    },
    {
      ref: slideRef02,
      id: 2,
    },
    {
      ref: slideRef03,
      id: 3,
    },
    {
      ref: slideRef04,
      id: 4,
    },
    {
      ref: slideRef05,
      id: 5,
    },
  ]

  const handleNavigation = (ref: RefObject<any>) => () => {
    console.log('asdasdad')
  }

  useEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.pinned',
          start: 'top top',
          end: 'bottom 100%',
          pin: true,
          pinSpacing: false,
          scrub: 1.5,
          // snap: {
          //   snapTo: 'labelsDirectional', // snap to the closest label in the timeline
          //   duration: { min: 0.2, max: 1 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
          //   delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
          //   ease: 'Linear.easeNone', // the ease of the snap animation ("power3" by default)
          // },
          markers: false,
        },
      })

      tl.fromTo(
        '.text',
        { scale: 1 },
        { scale: 0.7, transformOrigin: 'top left' },
        'text',
      )
      tl.fromTo('.slide-wrapper', { y: 0 }, { y: -50 }, 'text')

      // First slide
      tl.addLabel('slide1')
      tl.to('.pg-1', { height: 10, background: 'white' }, 'slide1')
      tl.to('.pg-2', { height: 70, background: '#0080FF' }, 'slide1')

      tl.to(
        '.slides .slide-1',
        {
          duration: 1.5,
        },
        'slide1',
      )
        .to(
          '.subtext-1',
          { x: 50, stagger: 0.02, opacity: 0, duration: 0.2 },
          'slide1',
        )
        .to(
          '.slides .slide-1 img',
          {
            opacity: 0,
            y: -50,
          },
          'slide1',
        )
        .to(
          '.slides .slide-1',
          {
            opacity: 0,
          },
          'slide1',
        )
        .fromTo(
          '.subtext-2',
          { x: 60, opacity: 0, delay: 0.5 },
          { x: 0, stagger: 0.05, opacity: 1 },
          'slide1',
        )
        .fromTo(
          '.slides .slide-2 img',
          { y: 40 },
          {
            opacity: 1,
            y: 0,
          },
          'slide1',
        )
        .fromTo(
          '.slides .slide-2',
          {},
          {
            opacity: 1,
          },
          'slide1',
        )

      // Second slide
      tl.addLabel('slide2')
      tl.to('.pg-2', { height: 10, background: 'white' }, 'slide2')
        .to('.pg-3', { height: 70, background: '#0080FF' }, 'slide2')
        .to(
          '.slides .slide-2',
          {
            opacity: 0,

            y: -0,
          },
          'slide2',
        )
        .to(
          '.subtext-2',
          { x: 50, stagger: 0.02, opacity: 0, duration: 0.2 },
          'slide2',
        )
        .fromTo(
          '.slides .slide-2 img',
          { y: 0 },
          {
            opacity: 0,
            y: -50,
          },
          'slide2',
        )
        .to(
          '.slides .slide-3',
          {
            opacity: 1,
          },
          'slide2',
        )
        .fromTo(
          '.slides .slide-3 img',
          { y: 40 },
          {
            opacity: 1,
            y: 0,
          },
          'slide2',
        )
        .fromTo(
          '.subtext-3',
          { x: 60, opacity: 0, delay: 0.5 },
          { x: 0, stagger: 0.05, opacity: 1 },
          'slide2',
        )
      // Third slide
      tl.addLabel('slide3')
      tl.to('.pg-3', { height: 10, background: 'white' }, 'slide3')
        .to('.pg-4', { height: 70, background: '#0080FF' }, 'slide3')
        .to(
          '.slides .slide-3',
          {
            opacity: 0,
          },
          'slide3',
        )
        .to(
          '.subtext-3',
          { x: 50, stagger: 0.02, opacity: 0, duration: 0.2 },
          'slide3',
        )
        .fromTo(
          '.slides .slide-3 img',
          { y: 0 },
          {
            opacity: 0,
            y: -40,
          },
          'slide3',
        )
        .to(
          '.slides .slide-4',
          {
            opacity: 1,
          },
          'slide3',
        )
        .fromTo(
          '.slides .slide-4 img',
          { y: 40 },
          {
            opacity: 1,
            y: 0,
          },
          'slide3',
        )
        .fromTo(
          '.subtext-4',
          { x: 60, opacity: 0, delay: 0.5 },
          { x: 0, stagger: 0.05, opacity: 1 },
          'slide3',
        )
      // Fourth slide
      tl.addLabel('slide4')
      tl.to('.pg-4', { height: 10, background: 'white' }, 'slide4')
        .to('.pg-5', { height: 70, background: '#0080FF' }, 'slide4')
        .to(
          '.slides .slide-4',
          {
            opacity: 0,

            y: -0,
          },
          'slide4',
        )
        .to(
          '.subtext-4',
          { x: 50, stagger: 0.02, opacity: 0, duration: 0.2 },
          'slide4',
        )
        .fromTo(
          '.slides .slide-4 img',
          { y: 0 },
          {
            opacity: 0,
            y: -40,
          },
          'slide4',
        )
        .fromTo(
          '.slides .slide-5',
          { y: 0 },
          {
            opacity: 1,

            y: 0,
          },
          'slide4',
        )
        .fromTo(
          '.slides .slide-5 img',
          { y: 40 },
          {
            opacity: 1,
            y: 0,
          },
          'slide4',
        )
        .fromTo(
          '.subtext-5',
          { x: 80, opacity: 0 },
          { x: 0, stagger: 0.05, opacity: 1 },
          'slide4',
        )
      tl.addLabel('slide5').to('slides .slide-5', { y: 0 }, 'slide5')
    }, component)
    return () => ctx.revert()
  }, [])

  return (
    <section id="possibilities">
      {/* DESKTOP View */}
      <div
        ref={component}
        className="bg-bladeGradient bg-left-top sm:block hidden"
      >
        <section className="bg-container overflow-hidden bg-left-top">
          <div className="blade-top-padding-lg background-gradient-img pinned h-[440vh] z-50 blade-bottom-padding-xl">
            <div className="h-full w-container-sm xlg:pt-8">
              <div className="text">
                <HeadingAtom
                  title="From Problems to Possibilities"
                  subtitle="Solutions in Action"
                  themeDark={false}
                  className="max-w-xl text-left"
                />
              </div>

              <section className="slide-wrapper flex gap-6 h-full relative 2xl:mt-16 xlg:mt-14 mt-10">
                <div className="paginations flex flex-col h-full gap-2 pt-2">
                  {refs.map((item) => {
                    return (
                      <button
                        type="button"
                        key={item.id}
                        onClick={() => handleNavigation(item.ref)}
                        className={`pg-${item.id} ${
                          item.id === 1 ? 'h-[70px] bg-blue' : 'h-3 bg-white'
                        } w-[10px] rounded-full scale-90`}
                        aria-label="paignation"
                      />
                    )
                  })}
                </div>

                <div id="slides" className="slides w-full relative h-[45vh] ">
                  {solutions.map((item, index) => {
                    return (
                      <div
                        id={`${item.id}`}
                        ref={refs[index].ref}
                        key={item.id}
                        className={`slide slide-${item.id} ${
                          item.id === 1 ? 'opacity-1' : 'opacity-0'
                        } min-h-[50vh] rounded-md flex justify-between items-start`}
                      >
                        <div className="flex-1 2xl:max-w-xl max-w-lg grid gap-4">
                          <h3
                            className={`subtext-${item.id} text-black font-semibold leading-tight`}
                          >
                            {item.title}
                          </h3>
                          <h6 className={`subtext-${item.id}`}>
                            {item.subtitle}
                          </h6>

                          <ul className=" text-xl list-disc list-inside">
                            {item.ul.map((ele) => {
                              const key = ele.li
                              return (
                                <li
                                  className={`subtext-${item.id} text-base lg:text-sm xlg:text-base 2xl:text-xl`}
                                  key={key}
                                >
                                  {ele.li}
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                        <div className="flex-1">
                          <img
                            className="2xl:h-96 xl:h-72 lg:h-60 h-40 w-auto mx-auto "
                            src={item.img}
                            alt={`${item.img + 1}`}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      <MobileView data={solutions} />
    </section>
  )
}
