import React, { DispatchWithoutAction, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logomark from 'assets/globals/logo-mark.svg'
import { TextAnchor, TextNavAnchor } from 'atoms/links'
import { CSSTransition } from 'react-transition-group'
import gsap from 'gsap'

export default function MobileNavModal({
  toggle,
}: {
  toggle: DispatchWithoutAction
}) {
  const navigate = useNavigate()
  const navigateTo = (target: string) => {
    navigate(target)
    toggle()
  }
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const dropdownRef = useRef<any>()

  const toggleDropdown = () => {
    setIsOpenMenu(!isOpenMenu)
  }

  return (
    /* eslint-disable */
    <nav className="bg-black h-full">
      <section className="w-container min-h-full text-dark overflow-auto flex flex-col flex-nowrap z-[999]">
        <div className="flex-0 flex border-white border-opacity-10 border-b-2 flex-nowrap gap-5 items-center justify-between py-2">
          <img src={logomark} alt="Buyerr logo without text" className="h-10" />

          <button
            type="button"
            onClick={toggle}
            className="rounded-full stroke-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              className="stroke-blue opacity-80 w-7 h-7"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col flex-1 pt-4">
          <ul className="list-none grid gap-3">
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="light"
                text="Home"
                href="/"
                className="block py-2"
              />
            </li>
            <li>
              <button
                onClick={toggleDropdown}
                type="button"
                className="dropdown-toggle font-light text-white md:text-md text-sm border-none leading-none flex justify-between w-full items-center opacity-80"
              >
                Solutions
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className={`w-6 transition-all duration-500 
                              ${isOpenMenu ? '-rotate-90' : ''} `}
                >
                  <path fill="none" d="M0 0h24v24H0V0z" />
                  <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
              </button>

              <CSSTransition
                in={isOpenMenu}
                timeout={400}
                unmountOnExit
                onEnter={(node: any) =>
                  gsap.fromTo(
                    node,
                    { height: 0 },
                    { height: 'auto', duration: 0.5 },
                  )
                }
                onExiting={(node: any) =>
                  gsap.to(node, { height: 0, duration: 0.5 })
                }
              >
                <div className="dropdow overflow-hidden" ref={dropdownRef}>
                  <button
                    type="button"
                    onClick={() => setIsOpenMenu(false)}
                    className="flex flex-col gap-3 mt-2 bg-black px-4 pt-4 pb-5 w-full"
                  >
                    <li onClick={toggle}>
                      <TextNavAnchor
                        color="light"
                        href="/solutions/product-development"
                        text="Product Development / Scaling"
                        className="whitespace-nowrap"
                        size="small"
                      />
                    </li>

                    <li onClick={toggle}>
                      <TextNavAnchor
                        color="light"
                        href="/solutions/business-process-transformation"
                        text="Business Process Transformation"
                        className="whitespace-nowrap"
                        size="small"
                      />
                    </li>

                    <li onClick={toggle}>
                      <TextNavAnchor
                        color="light"
                        href="/solutions/engineering-sustainability"
                        text="Engineering Sustainability"
                        className="whitespace-nowrap"
                        size="small"
                      />
                    </li>

                    <li onClick={toggle}>
                      <TextNavAnchor
                        color="light"
                        href="/solutions/business-innovation"
                        text="Business Innovation"
                        className="whitespace-nowrap"
                        size="small"
                      />
                    </li>

                    <li onClick={toggle}>
                      <TextNavAnchor
                        color="light"
                        href="/solutions/data-management"
                        text="Data Management"
                        className="whitespace-nowrap"
                        size="small"
                      />
                    </li>
                  </button>
                </div>
              </CSSTransition>
            </li>
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="light"
                text="Services"
                href="/services"
                className="block py-2"
              />
            </li>
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="light"
                text="Work"
                href="/work"
                className="block  py-2"
              />
            </li>
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="light"
                text="About"
                href="/about"
                className="block  py-2"
              />
            </li>
            <li onClick={toggle}>
              <TextAnchor
                size="base"
                color="light"
                text="Culture"
                href="/culture"
                className="block  py-2"
              />
            </li>
            {/* <li 
            onClick={toggle}
            >
              <TextAnchor
                size="base"
                color="light"
                text="Blogs"
                href="/coming-soon"
                className="block  py-2"
              />
            </li> */}
          </ul>
        </div>
        <div className="mt-auto pt-10 pb-1">
          <button
            role="link"
            type="button"
            onClick={() => navigateTo('/contact-us')}
            className=" bg-dark text-white text-lg font-normal mb-3  pt-2 pb-3 px-3 rounded-full grid  place-content-center  w-full hover:bg-opacity-90 "
          >
            Contact us
          </button>
        </div>
      </section>
    </nav>
  )
}
