import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

gsap.registerPlugin(ScrollToPlugin)

export default function GsapScrollTop({
  children,
  href,
}: {
  children: React.ReactNode
  href: string
}) {
  const navigate = useNavigate()
  const location = useLocation()

  const handleScrollTop = () => {
    if (location.pathname !== href) {
      navigate(href)
      gsap.to(window, {
        scrollTo: { y: 0 },
      })
    }
  }
  return (
    <button type="button" onClick={handleScrollTop}>
      {children}
    </button>
  )
}
