import React from 'react'
import HeadingTile from 'molecules/headingTile'

export default function Approach() {
  return (
    <div className="w-container">
      <div className="blade-top-padding-sm">
        <HeadingTile
          title="Approach"
          subtitle="Thinking Beyond Mere Conventions"
          center
        />
        <div className="flex justify-center flex-wrap 2xl:gap-32 xl:gap-16 gap-10 blade-top-padding-sm xl:max-w-screen-xl mx-auto">
          {dat.map((item) => {
            return (
              <div
                key={item.id}
                className="sm:w-5/12 w-10/12 2xl:even:mt-20 sm:even:mt-10 lg:min-w-[20rem] min-w-[18rem]"
              >
                <Cards
                  title={item.title}
                  paragraph={item.paragraph}
                  id={item.id}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

function Cards({
  title,
  paragraph,
  image,
  id,
}: {
  title: string
  paragraph: string
  image?: string
  id: number
}) {
  return (
    <div className="cards flex md:flex-row flex-col-reverse border relative border-white border-opacity-10 gap-y-6 max-w-screen-md mx-auto xl:p-10 lg:p-7 p-4 rounded-2xl lg:gap-10 gap-5 bg-gradient-to-br from-[#222121] via-black to-black">
      <div className="flex justify-between relative ">
        <div>
          <h5 className="text-white">
            {id}. {title}
          </h5>
          <span
            className=" text-sm md:text-base lg:text-lg text-white opacity-60 lg:mt-4 mt-2 pr-3 font-light leading-snug block"
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        </div>
      </div>
      {/* <div className="flex-1 relative -ml-1">
        <img
          className="w-full h-full object-cover object-left"
          src={dummy}
          alt=""
        />
      </div> */}
    </div>
  )
}

const dat = [
  {
    title: 'Acknowledging the Need',
    paragraph:
      'Recognized the evolving landscape of invoice management and analyzed the current infrastructure in place. This involved diving deep into the operational fabric of businesses. By analyzing the existing infrastructure, we unearthed critical bottlenecks and opportunities for enhancement, aligning technology with tangible business needs.',
    id: 1,
  },
  {
    title: 'Balanced Flows Technology',
    paragraph:
      "Employed the proprietary framework, Balanced Flows Technology, to facilitate this evolution. The integration was a strategic choice grounded in a profound understanding of financial ecosystems. The framework is a tailored solution crafted to harmonize with businesses' operational intricacies, offering a nuanced approach to financial optimization.",
    id: 2,
  },
  {
    title: 'Informed by Data-Driven Insights',
    paragraph:
      'Leveraged extensive data analysis, focusing on interconnected commercial relationships. This comprehension allowed us to sculpt a solution that wasn’t just about managing invoices but optimizing the entire financial cycle, nurturing mutually beneficial transactions.',
    id: 3,
  },
  {
    title: 'Promoting Collaborative Transaction',
    paragraph:
      'Fostered reciprocal transactions and introduced a non-clearing approach to invoice regulation. It aims to promote synergy among transactions and catalyze a fundamental reevaluation of traditional approaches to financial management. <br /> <br /> The result isn’t just a fundamental shift in how companies approach and manage their financial operations, it’s also about reimagining financial processes through a lens that marries technological prowess with a profound understanding of business intricacies.',
    id: 4,
  },
]
