import React from 'react'
import dashboardMock from 'assets/workCaseStudies/bflows/bflows-banner-image.png'

export default function IndustrySpace() {
  return (
    <div className="pt-5  blade-bottom-padding w-container">
      <div className="grid px-4 place-content-center place-items-center">
        <img
          src={dashboardMock}
          className="h-full"
          alt="mockup for daashboard of bflow product"
        />
      </div>
      <div className="flex flex-col justify-center md:flex-row gap-4 md:gap-5 lg:gap-6 pt-12 md:pt-8 lg:pt-12">
        <article className="flex-0 md:basis-64 grid gap-2">
          <h6 className="text-white font-semibold">Client</h6>
          <h6 className="text-white text-opacity-60 font-light"> Bflows</h6>
        </article>
        <article className="flex-0 md:basis-96 grid gap-2">
          <h6 className="text-white font-semibold">Industry</h6>
          <h6 className="text-white text-opacity-60 font-light">
            Invoice Management
          </h6>
        </article>
        <article className="flex-0 md:basis-60 grid gap-2">
          <h6 className="text-white font-semibold">What Innogent did</h6>
          <h6 className="text-white text-opacity-60 font-light">
            Full-stack Development
          </h6>
        </article>
      </div>
    </div>
  )
}
