import React from 'react'
import costSavings from 'assets/workCaseStudies/cloudeagle/benefits/cost-savings.png'
import dummy from 'assets/workCaseStudies/cloudeagle/benefits/dummy.png'

export default function Benefits() {
  const data = [
    {
      title: '30% Cost Savings ',
      description:
        'By eliminating redundant and unused applications, businesses using CloudEagle can achieve  a 30% cost savings in their SaaS expenditure.',
      img: dummy,
      id: 1,
    },
    {
      title: 'Confident Decision-making',
      description:
        'The customizable reports provided by CloudEagle equip businesses with the information they need to negotiate contracts confidently and also right-size SaaS usage.',
      img: dummy,
      id: 2,
    },
    {
      title: 'Improved Resource Allocation',
      description:
        'With a clearer understanding of application usage, organizations can optimize their resource allocation, ensuring optimized resource allocation.',
      img: dummy,
      id: 3,
    },
  ]
  return (
    <div>
      <div className="blade-bottom-padding  w-container mx-auto">
        <h4 className="text-gradient-dark font-semibold w-fit">Benefits</h4>
      </div>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:gap-5 lg:gap-6 2xl:gap-8  w-container">
        {data.map((item) => {
          return (
            <div
              key={item.id}
              className="cards-wrapper relative p-2 h-auto group max-w-lg mx-auto"
            >
              {/* <div className="w-11/12 h-fit lg:-mt-6 -mt-4 group-hover:-translate-y-1 transition-all duration-700 mx-auto absolute z-50 inset-0 px-3 2xl:px-6 pb-6 ">
                <img
                  className="h-full w-full relative object-contain z-10"
                  src={item.img}
                  alt={item.title}
                />
              </div> */}
              <div className="benefits-cards-gradient h-full text-center border-gradient px-3 2xl:px-6 pb-6">
                {/* <div className="w-11/12 lg:-mt-6 -mt-4 mx-auto opacity-0 relative">
                  <img
                    className="h-full w-full object-cover"
                    src={item.img}
                    alt={item.title}
                  />
                </div> */}
                <div className="text-wrapper relative transition-transform duration-700 py-10">
                  <h5 className="text-white mt-4">{item.title}</h5>
                  <h6 className="font-light  text-opacity-60 mt-3 text-white">
                    {item.description}
                  </h6>
                </div>
              </div>
              {/* <div className="bg-blur-gradient z-10 bg-darkGray h-[25%] w-[25%] mx-auto absolute top-[25%] inset-0 rounded-full" /> */}
            </div>
          )
        })}
      </div>
    </div>
  )
}
