import React, { useEffect, useState } from 'react'

interface Banner {
  className?: string
  title: string
  subTitle: string
  subTitleClassName?: string

  logo?: string
  logoShadow?: boolean
  logoShadowColor?: string
}

export default function Banner({
  title,
  subTitle,
  subTitleClassName,
  logo,
  logoShadow = true,
  logoShadowColor = 'bg-blue',
  className,
}: Banner) {
  const [state, setState] = useState(false)

  useEffect(() => {
    setState(true)
  }, [state])

  return (
    <section className={`${className} md:pt-8`}>
      <section className="relative blade-top-padding-xl md:mt-0 blade-top-margin md:blade-bottom-padding-sm">
        <div className="animate-fade-in-slow flex relative z-10 flex-col justify-center items-center 2xl:gap-10 lg:gap-8 gap-5 blade-top-padding-lg blade-bottom-padding-lg">
          <h2
            className="text-gradient-dark font-semibold text-center "
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {logo ? (
            <div className="flex overflow-hidden justify-center items-center gap-8 w-full mx-auto">
              <div
                className={`${
                  state ? 'w-full' : 'w-0'
                } h-[1px] transition-all duration-[3000ms] ease-linear delay-700 bg-opacity-10 bg-white`}
              />
              <img
                className="w-auto max-w-[8rem] md:max-w-[12rem] 2xl:max-w-[18rem] mt-2"
                src={logo}
                alt="onplan"
              />
              <div
                className={`${
                  state ? 'w-full' : 'w-0'
                } h-[1px] transition-all duration-[3000ms] ease-linear delay-700 bg-opacity-10 bg-white`}
              />
            </div>
          ) : (
            ''
          )}
          <h5
            className={`${subTitleClassName}  
            ${
              subTitleClassName ? '' : 'max-w-lg'
            } text-white text-opacity-80 font-normal text-center px-3`}
            dangerouslySetInnerHTML={{ __html: subTitle }}
          />
        </div>
        {logoShadow ? (
          <div
            className={`absolute rounded-full inset-0 w-44 lg:w-[20rem] 2xl:w-[32rem] h-44 lg:h-[20rem] 2xl:h-[32rem] m-auto blur-[60px] lg:blur-[120px] 2xl:blur-[150px] ${logoShadowColor} bg-opacity-20 z-0`}
          />
        ) : (
          ''
        )}{' '}
      </section>
    </section>
  )
}
