import React from 'react'
import HeadingTile from 'molecules/headingTile'
import icons from 'assets/workCaseStudies/onPlan/icons.svg'
import iconsHover from 'assets/workCaseStudies/onPlan/icons-hover.svg'

const data = [
  {
    id: 1,
    title: 'Data Ingestion Complexity',
    subTitle:
      'The need to connect diverse source systems and expedite data pipelines for rapid model creation.',
    logo: icons,
    logoHover: iconsHover,
  },
  {
    id: 2,
    title: 'Scenario Planning and Budget Analysis',
    subTitle:
      'The need to evaluate "what-if" scenarios, compare budget variations, and capture snapshots of financial conditions at specific points in time.',
    logo: icons,
    logoHover: iconsHover,
  },
  {
    id: 3,
    title: 'Scalable Data Storage Layer',
    subTitle:
      'Conventional solutions struggled to support voluminous data storage, read data requests, hindering seamless analytics and reporting.',
    logo: icons,
    logoHover: iconsHover,
  },
  {
    id: 4,
    title: 'Bottlenecked Onboarding',
    subTitle:
      'Tedious customer onboarding, a roadblock to market expansion and value delivery.',
    logo: icons,
    logoHover: iconsHover,
  },
  {
    id: 5,
    title: 'Visualization',
    subTitle:
      'Converting complex data into visual formats for facilitating better comprehension and interpretation of insights.',
    logo: icons,
    logoHover: iconsHover,
  },
]

export default function ProblemStatements() {
  return (
    <div className="w-container">
      <HeadingTile title="Problem Statements" className="" />

      <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 2xl:gap-14 xl:gap-8 gap-5 flex-wrap mt-2">
        {data.map((item) => {
          return (
            <div
              key={item.id}
              className="border border-white border-opacity-10 flex-1 relative group transition-all duration-700 2xl:p-10 p-8 flex flex-col justify-start items-start md:max-w-lg max-w-sm mx-auto gap-5 2xl:pb-20 xl:pb-14 pb-10"
            >
              <div className="absolute inset-0 h-full w-full group-hover:opacity-100 opacity-0 transition-all duration-700 bg-bladeGradient" />

              <div className="relative">
                <img
                  className="group-hover:opacity-0 h-14"
                  src={icons}
                  alt=""
                />
                <img
                  className="opacity-0 group-hover:opacity-100 h-14 absolute top-0"
                  src={iconsHover}
                  alt=""
                />
              </div>
              <h4 className="relative text-white group-hover:text-black">
                {item.title}
              </h4>
              <h6 className="text-white group-hover:text-black opacity-80 font-light">
                {item.subTitle}
              </h6>
            </div>
          )
        })}
      </div>
    </div>
  )
}
