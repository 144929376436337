import React from 'react'
import HeadingTile from 'molecules/headingTile'
import approach1 from 'assets/workCaseStudies/onPlan/features/Approach-01.png'
import approach2 from 'assets/workCaseStudies/onPlan/features/Approach-02.png'
import approach3 from 'assets/workCaseStudies/onPlan/features/Approach-03.png'
import approach4 from 'assets/workCaseStudies/onPlan/features/Approach-04.png'

export default function Approach() {
  return (
    <div className="w-container">
      <div>
        <HeadingTile
          title="Approach"
          subtitle="Thinking Beyond Mere Conventions"
          center
        />
        <div className="flex justify-center flex-wrap 2xl:gap-24 xl:gap-16 gap-10 blade-top-padding-sm xl:max-w-screen-xl mx-auto">
          {dat.map((item) => {
            return (
              <div
                key={item.id}
                className="sm:w-5/12 w-10/12 2xl:even:mt-20 sm:even:mt-10 lg:min-w-[20rem] min-w-[18rem]"
              >
                <Cards
                  title={item.title}
                  paragraph={item.paragraph}
                  id={item.id}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

function Cards({
  title,
  paragraph,
  image,
  id,
}: {
  title: string
  paragraph: string
  image?: string
  id: number
}) {
  return (
    <div className="cards flex md:flex-row flex-col-reverse border relative border-white border-opacity-10 gap-y-6 max-w-screen-md mx-auto xl:p-10 lg:p-7 p-4 rounded-2xl lg:gap-10 gap-5 bg-gradient-to-br from-[#222121] via-black to-black">
      <div className="flex justify-between relative ">
        <div>
          <h5 className="text-white">
            {id}. {title}
          </h5>
          <span
            className=" text-sm md:text-base lg:text-lg text-white opacity-60 lg:mt-4 mt-2 pr-3 font-light leading-snug block"
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        </div>
      </div>
    </div>
  )
}

const dat = [
  {
    id: 1,
    cover: approach1,
    title: 'Strategic use of cloud-based technologies',
    paragraph:
      'Leveraged the latest cloud-based technologies such as EventBridge and AWS Lambda to create a decoupled and scalable system which allowed us to develop modular features that could scale with ease as the project evolved.',
  },
  {
    id: 2,
    cover: approach2,
    title: 'Cost-effective design and efficient resource utilization',
    paragraph: `We designed the system to optimize the utilization of managed services which not only ensured efficient resource allocation but also helped manage project expenses effectively.`,
  },
  {
    id: 3,
    cover: approach3,
    title: 'Fail-fast approach for rapid feature deployment',
    paragraph:
      'Adopted a "fail-fast" approach which involved implementing features rapidly, even in their initial stages, and then iteratively improving them over time based on feedback and evolving client needs.',
  },
  {
    id: 4,
    cover: approach4,
    title: 'Peer review and collaborative decision-making',
    paragraph:
      'Before implementation, we conducted thorough peer reviews of the proposed approach. This collaborative decision-making process ensured that the chosen solutions were well-vetted and aligned with project goals.',
  },
  {
    id: 5,
    cover: approach4,
    title: 'Client-centric delivery',
    paragraph: `Our primary focus was on delivering features that precisely met the client's requirements for which we maintained open communication channels with the client to quickly address any changes or additional needs that arose during the project.`,
  },
  {
    id: 6,
    cover: approach4,
    title: 'Code quality and review standards',
    paragraph:
      'We upheld stringent code review and code coverage  standards throughout the development process. This commitment to code quality helped maintain a robust and reliable system.',
  },
]
