import React, { useState, useEffect, useRef, ReactNode } from 'react'
import Icon from 'assets/workCaseStudies/icon.svg'
import IconBlue from 'assets/workCaseStudies/icon-blue.svg'

interface DataProps {
  title: string
  desc: string
  coverImage: string
  icon: string
}

export default function Benefits({ data }: { data: any }) {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleIndexChange = (update: number) => {
    setActiveIndex(update)
  }

  function moveNext() {
    setActiveIndex((prev) => Math.min(prev + 1, data.length))
  }

  function movePrev() {
    setActiveIndex((prev) => Math.max(prev - 1, 0))
  }

  useEffect(() => {
    console.log(data.length)
  }, [])

  return (
    <div>
      <div className="blade-bottom-padding w-container mx-auto">
        <h4 className="text-gradient-dark font-semibold w-fit">Benefits</h4>
      </div>
      <div className="lg:grid gap-5 grid-cols-bflow-benefits blade-bottom-padding-lg">
        <div className="flex flex-col justify-center 2xl:gap-2 gap-1 ml-auto mr-auto lg:mr-0 lg:ml-10 xl:ml-28 2xl:ml-auto 2xl:max-w-xl xl:max-w-lg  px-3 md:pr-6  2xl:pt-8  2xl:pr-1">
          {data.map((elem: any, index: number) => {
            const key = `${index}`
            return (
              <SlideCard
                index={index}
                isActive={activeIndex === index}
                handleClick={handleIndexChange}
                data={elem}
                key={key}
              />
            )
          })}
        </div>
        <div className="2xl:gap-8 gap-5 justify-center items-center lg:flex hidden w-fit mx-auto px-2">
          <NavBtn disabled={activeIndex === 0} handleClick={() => movePrev()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 15"
              className="h-5 group-hover:opacity-100 rotate-180 opacity-70 disabled:hover:opacity-70 transition-opacity duration-150 ease-in-out group-focus:opacity-100 "
              fill="none"
            >
              <path
                d="M23.445 6.7864C23.8057 7.14707 23.8057 7.73183 23.445 8.0925L17.5676 13.97C17.2069 14.3306 16.6222 14.3306 16.2615 13.97C15.9008 13.6093 15.9008 13.0245 16.2615 12.6639L21.4859 7.43945L16.2615 2.21506C15.9008 1.85439 15.9008 1.26963 16.2615 0.908956C16.6222 0.548287 17.2069 0.548287 17.5676 0.908956L23.445 6.7864ZM1.3496 8.363C0.839533 8.363 0.426044 7.94952 0.426044 7.43945C0.426044 6.92939 0.839533 6.5159 1.3496 6.5159V8.363ZM22.792 8.363H1.3496V6.5159H22.792V8.363Z"
                fill="white"
              />
            </svg>
          </NavBtn>
          <NavBtn
            disabled={activeIndex === data.length - 1}
            handleClick={() => moveNext()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 15"
              className="h-5 group-hover:opacity-100 opacity-70  transition-opacity duration-150 ease-in-out group-focus:opacity-100 "
              fill="none"
            >
              <path
                d="M23.445 6.7864C23.8057 7.14707 23.8057 7.73183 23.445 8.0925L17.5676 13.97C17.2069 14.3306 16.6222 14.3306 16.2615 13.97C15.9008 13.6093 15.9008 13.0245 16.2615 12.6639L21.4859 7.43945L16.2615 2.21506C15.9008 1.85439 15.9008 1.26963 16.2615 0.908956C16.6222 0.548287 17.2069 0.548287 17.5676 0.908956L23.445 6.7864ZM1.3496 8.363C0.839533 8.363 0.426044 7.94952 0.426044 7.43945C0.426044 6.92939 0.839533 6.5159 1.3496 6.5159V8.363ZM22.792 8.363H1.3496V6.5159H22.792V8.363Z"
                fill="white"
              />
            </svg>
          </NavBtn>
        </div>

        <div className="after:rounded-full 2xl:h-[42rem] h-full after:contents-[''] after:absolute after:-left-4 after:right-auto after:bottom-0 after:top-0 after:m-auto after:h-auto after:aspect-square after:w-3/6  relative after:bg-[#151515] after:blur-[60px] lg:block hidden">
          <div className="absolute inset-0 z-10 " />
          <div className="relative h-full w-full z-20 text-white overflow-hidden">
            {data.map((elem: any, index: number) => {
              const key = `${index}`
              return (
                <img
                  key={key}
                  className={`rounded-l-3xl absolute inset-0 xl:left-10 h-full w-full object-cover object-center transition-all duration-700 ease-in-out ${
                    index === activeIndex
                      ? 'opacity-100 scale-100'
                      : ' scale-95 opacity-0'
                  }`}
                  src={elem.coverImage}
                  alt={elem.title}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
function NavBtn({
  children,
  handleClick,
  disabled,
}: {
  disabled: boolean
  handleClick: () => void
  children: ReactNode
}) {
  return (
    <button
      disabled={disabled}
      className="rounded-full focus-visible:border-white focus-visible:border-opacity-40 disabled:hover:border-transparent hover:border-white hover:border-opacity-40 transtion-all duration-150 ease-in-out text-white disabled:cursor-not-allowed outline-none focus-visible:outline-none aspect-square h-14 grid place-content-center place-items-center w-auto p-2 group benefit-nav-btns"
      type="button"
      onClick={() => handleClick()}
    >
      {children}
    </button>
  )
}

type ISlideCard = {
  isActive: boolean
  index: number
  handleClick: (update: number) => void
  data: {
    coverImage: string
    icon: string
    title: string
    desc: string
  }
}

function SlideCard({ data, isActive, handleClick, index }: ISlideCard) {
  const accordionRef = useRef<any>()
  useEffect(() => {
    const elem = accordionRef.current
    if (elem) {
      if (isActive) {
        elem.style.height = `${elem.scrollHeight}px`
        elem.style.opacity = 1
      } else {
        elem.style.height = '0px'
        elem.style.opacity = 0
      }
    }
  }, [isActive])

  const { icon, title, desc, coverImage } = data
  return (
    <article
      className={`pt-2 pb-3 px-1 md:px-3 border-1 border-solid rounded-md md:rounded-lg xl:rounded-[10px] ${
        isActive ? 'shadow-tab-active bg-[#060606] border-[#414141]' : ''
      }`}
    >
      <button
        type="button"
        onClick={() => handleClick(index)}
        className="flex py-2 md:py-2 gap-1 md:gap-3 w-full"
      >
        <div className="grid place-content-center place-items-center 2xl:basis-16 basis-10 flex-0">
          {isActive ? (
            <img
              src={IconBlue}
              alt={title}
              className="h-8 md:h-6 lg:h-8 2xl:h-10"
            />
          ) : (
            <img
              src={Icon}
              alt={title}
              className="h-8 md:h-6 lg:h-8 2xl:h-10"
            />
          )}
        </div>
        <div className="flex-1 text-left ">
          <span className=" text-lg lg:text-xl 2xl:text-2xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal text-white text-left">
            {title}
          </span>
        </div>
      </button>

      <div
        className="overflow-hidden transition-all duration-300 ease-in-out md:pl-[64px] xl:pl-[54px] 2xl:pl-[76px] "
        ref={accordionRef}
      >
        <div className="py-px md:pr-3 xl:pr-5 pt-2 md:pt-0 pb-1 md:pb-0 pr-8 pl-3 md:pl-0 lg:py-1  overflow-hidden ">
          <span className=" block text-sm 2xl:text-lg text-white max-w-xl">
            {desc}
          </span>
        </div>

        <div className="h-44 mt-5 md:hidden block">
          <img
            src={coverImage}
            className="h-full w-full object-center object-cover"
            alt={title}
          />
        </div>
      </div>
    </article>
  )
}
